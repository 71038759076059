import * as React from 'react';
import classNames from 'classnames';
import { FlexBox, Icon, Typography } from '@vp/swan';
import { useNavContext } from '../NavContext';
import { SubcategoryList } from './SubcategoryList';
import { BookendsBadge } from '../../../BookendsBadge';
import { NavItemWithChildrenType } from '../../../../types';
import { TrackingContext } from 'hooks/useTracking';
import { TOP_NAV, TOP_NAV_LEVEL } from '../../../../../constants/analytics';
import { HOLIDAY_RED_COLOR_CODE } from '../../../../../constants/styleClasses';
import { isThemeHoliday } from '../../../../utils/utilities';

export const TopLevelWithChildren = (
  props: NavItemWithChildrenType & React.ComponentProps<'li'>
) => {
  const { state, navigation, hoverNavItem, isMxNav } = useNavContext();

  const { trackHeaderLinkClicked } = React.useContext(TrackingContext);

  let isFocused = false;
  if (state.focusedCategoryIdx?.toString()) {
    isFocused = state.focusedCategoryIdx === props.index;
  }

  const navigationDetailPath = props.analyticsId ? [props.analyticsId] : [];
  return (
    <li
      className={classNames(
        'site-header-nav-item',
        'site-header-nav-toplevel',
        { 'site-header-nav-flyout-focused': isFocused },
        { 'site-header-nav-visual-layout': props.isVisual },
        props.className
      )}
      data-item-title={props.text}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          hoverNavItem(props.index, navigation, true);
        }
      }}
    >
      <Typography
        fontWeight="bold"
        fontSize={'standard'}
        className={classNames(
          'site-header-nav-link',
          'accessibility-keyboard-clickable',
          {
            'mx-top-level-link': isMxNav,
          },
          props.theme
        )}
        style={isThemeHoliday(props.theme) ? { color: HOLIDAY_RED_COLOR_CODE } : {}}
        tabIndex={0}
        onClick={() => {
          hoverNavItem(props.index, navigation, true);
          isMxNav
            ? trackHeaderLinkClicked(
                navigationDetailPath.join('|'),
                TOP_NAV,
                TOP_NAV_LEVEL
              )
            : trackHeaderLinkClicked(navigationDetailPath.join('|'), TOP_NAV);
        }}
        onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
          if (
            event.key === 'Enter' ||
            event.key === 'Space' ||
            event.key === ' '
          ) {
            isMxNav
              ? trackHeaderLinkClicked(
                  navigationDetailPath.join('|'),
                  TOP_NAV,
                  TOP_NAV_LEVEL
                )
              : trackHeaderLinkClicked(navigationDetailPath.join('|'), TOP_NAV);
          }
        }}
        role="button"
        id={props.id + '-mob'}
        aria-expanded={isFocused}
        aria-controls={props.id + '-accordion'}
      >
        <FlexBox>
          {props.text}
          {props.badge && <BookendsBadge {...props.badge} layout="visual" />}
        </FlexBox>
        {isMxNav && <Icon iconType="chevronRight" />}
      </Typography>
      <SubcategoryList
        parentId={props.id}
        children={props.children}
        text={props.text}
        seeAllText={isMxNav ? props.seeAllCategory : props.seeAllMinimal}
        seeAllUrl={props.url}
        analyticsId={props.analyticsId}
        categoryId={props.index}
        isVisualNav={props.isVisual}
        parentNavigationDetailPath={navigationDetailPath}
      />
    </li>
  );
};
