export const setCookie = (
  cookieKey: string,
  cookieValue: string,
  expirationDays?: number
) => {
  let expiryDate = '';
  if (expirationDays) {
    const date = new Date();
    const expirationMilliseconds = (expirationDays || 30) * 24 * 60 * 60 * 1000;
    date.setTime(date.getTime() + expirationMilliseconds);
    expiryDate = `; expires=" ${date.toUTCString()}`;
  }
  document.cookie = `${cookieKey}=${cookieValue || ''}${expiryDate}; path=/`;
};

export const getCookie = (cookieKey: string) => {
  const cookieName = `${cookieKey}=`;
  const cookieArray = document.cookie.split(';');
  for (let cookie of cookieArray) {
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1, cookie.length);
    }
    if (cookie.indexOf(cookieName) === 0) {
      return cookie.substring(cookieName.length, cookie.length);
    }
  }
};

export const checkForCurrentCountry = () => {
  const cCountry = getCookie('cf-ipcountry');
  return cCountry || null;
};

export const checkForSelectedCountry = () => {
  const sfCountry = getCookie('cf-ipdfcountry');
  return sfCountry || null;
};

export const setSelectedCountry = (val: string) => {
  setCookie('cf-ipdfcountry', val, 365);
};

export const redirectToDefaultCountry = (dfCountry?: string) => {
  window.location.href = dfCountry || 'https://www.vistaprint.com/';
};