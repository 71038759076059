import React from 'react';
import { useStyles, useSwanStyleKeys } from '@vp/ubik-context';

import { SlimFooter as BaseSlimFooter } from '../common/components/SlimFooter';
import { stylesheet } from '../common/components/SlimFooter/SlimFooter.scss';
import { stylesheet as masksStylesheet } from '../common/components/SlimFooter/SlimFooterMasks.scss';

import { swanStyleKeys } from '../common/utils/swanStyleKeys';

export const SlimFooter = (props: any) => {
  useSwanStyleKeys(swanStyleKeys);
  useStyles(stylesheet, masksStylesheet);

  return <BaseSlimFooter {...props} />;
};
