import * as React from 'react';
import classNames from 'classnames';
import { BoundedContent, Box, Typography, Span, Ul, Li } from '@vp/swan';
import { useBookendsData } from '../../hooks/useBookendsData';
import { TrackingProvider } from '../../hooks/useTracking';
import { PaymentIcons } from '../PaymentIcons';
import TrackableLink from '../TrackableLink';
import { ErrorBoundary } from '../ErrorBoundary';

import {
  BookendsResponse,
  BookendsServiceCallParameters,
  GenericSharedProps,
  SimpleLink,
  SlimFooterProps,
} from '../../types';

type Props =
  | (BookendsResponse<SlimFooterProps> & GenericSharedProps)
  | (BookendsServiceCallParameters & GenericSharedProps);

const SlimFooterContents = (props: Props) => {
  const data = useBookendsData<SlimFooterProps>(props, {
    version: 'v3',
    headerOrFooter: 'footer',
    variant: 'slim',
  });

  if (!data) return <footer></footer>;
  const {
    paymentTypes,
    companyMessage,
    copyrightMessage,
    lowerNavigationBar,
    footerLayoutName,
  } = data;

  return (
    (<footer className={classNames(footerLayoutName, 'slim-footer', 'swan-dark-mode')}>
      <Box as="aside" className="site-footer-bar typography-2017" paddingY={8}>
        <BoundedContent className="full-width-container">
          <PaymentIcons paymentTypes={paymentTypes} />
          <div className="site-footer-lower-navigation-bar">
            <Ul
              role="list"
              // role="list" is to account for Safari suppressing lists with the bullets removed
              // (see https://www.scottohara.me/blog/2019/01/12/lists-and-safari.html)
              className="swan-list-skin-minimal site-footer-additional-links-slim"
            >
              {lowerNavigationBar.map(
                (lowerNavItem: SimpleLink, index: number) => (
                  <Li
                    key={lowerNavItem.url}
                    fontSize="xsmall"
                    className="site-footer-additional-link"
                    marginRight={"4"}
                  >
                    <TrackableLink
                      navigationDetailPath={
                        lowerNavItem.analyticsId
                          ? [lowerNavItem.analyticsId]
                          : []
                      }
                      dataPosition={index + 1}
                      href={lowerNavItem.url}
                    >
                      {lowerNavItem.text}
                    </TrackableLink>
                  </Li>
                )
              )}
              <Span
                fontSize="xsmall"
                className="site-footer-additional-link company-message"
              >
                {companyMessage}
              </Span>
            </Ul>
          </div>
          <Typography
            fontSize="xsmall"
            className="site-footer-copyright-notice-slim"
          >
            {copyrightMessage}
          </Typography>
        </BoundedContent>
      </Box>
    </footer>)
  );
};

export const SlimFooter = (props: Props) => {
  return (
    <TrackingProvider
      trackingConfiguration={props.trackingConfiguration}
      bookendName="Footer"
    >
      <ErrorBoundary
        suppressError={props.suppressError}
        component="slim-footer"
      >
        <SlimFooterContents {...props} />
      </ErrorBoundary>
    </TrackingProvider>
  );
};
