import { fireImpression, getVariation, whenAvailable } from '@vp/ab-reader';
import { MyAccountContent } from '@vp/my-account-pages-dropdown';
import { FlexBox } from '@vp/swan';
import TrackableLink from 'components/TrackableLink';
import React, { useContext } from 'react';
import { AccountResources } from '../../../../../types';
import { getAuthHeaderAndShopperId } from '../../../../../utils/authUtils';
import { MobileNavHeader } from '../MobileNavHeader';
import { MyAccountMobileContext } from './MyAccountMobileContext';
import { useNavContext } from '../../NavContext';

type MyAccountLinksProps = {
  accountResources: AccountResources | undefined;
  auth: any;
};

export default function MyAccountLinks(props: MyAccountLinksProps) {
  const { isOpen, setIsOpen } = useContext(MyAccountMobileContext);
  const { accountResources } = props;
  const { isMxNav } = useNavContext();

  const [token, setToken] = React.useState<string>('');
  const [shopperId, setShopperId] = React.useState<string>('');

  React.useEffect(() => {
    if (props.auth && props.auth.getAuthorizationHeader) {
      const { authHeader, shopperId } = getAuthHeaderAndShopperId(props.auth);
      setToken(authHeader);
      setShopperId(shopperId);
    }
  }, []);

  if (!accountResources) return <></>;

  return (
    <FlexBox flexDirection="column" px={isMxNav ? '7' : 0}>
      <MobileNavHeader
        isOpen={isOpen}
        text={accountResources.translation.messages.account}
        onClose={() => setIsOpen(false)}
      />
      <MyAccountContent
        getVariationAsync={(pageExperimentKey: string) => {
          return new Promise((resolve) => {
            whenAvailable(() => {
              const variation = getVariation(pageExperimentKey);
              resolve(variation);
            }, 1000);
          });
        }}
        fireTestImpression={(experimentKey: string, variationKey: string) => {
          fireImpression(experimentKey, variationKey);
        }}
        trackableLink={(
          subSection: string,
          className: string,
          href: string,
          navigationDetailPath: string[],
          children: React.ReactNode
        ) => {
          return (
            <TrackableLink
              subSection={subSection}
              className={className}
              href={href}
              navigationDetailPath={navigationDetailPath}
            >
              {children}
            </TrackableLink>
          );
        }}
        authHeader={token}
        shopperId={shopperId}
        mode={'column'}
        // will add a prop showBia when the tests are configured
      />
    </FlexBox>
  );
}
