import React from 'react';
import { Visible } from '@vp/swan';
import { MobileSearchCloseButtonProps } from '../../types';

export const MobileSearchCloseButton = ({
  clickHandler,
  label,
}: MobileSearchCloseButtonProps) => {
  return (
    <Visible xs>
      <button
        className="swan-button-skin-unstyled close-search"
        onClick={clickHandler}
      >
        <span className="swan-visually-hidden">{label}</span>
        <span aria-hidden="true" data-dialog-show="navigation-dialog">
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <line
              x1="14.25"
              y1="7.94995"
              x2="3.75"
              y2="7.94995"
              stroke="black"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7 4L3 8C3 8 5.47619 10.4762 7 12"
              stroke="black"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      </button>
    </Visible>
  );
};
