import * as React from 'react';
import classNames from 'classnames';
import {
  BoundedContent,
  Box,
  StandardTile,
  StandardTileContents,
  StandardTileDescription,
  StandardTileImage,
  StandardTileName,
  StandardTileOverlay,
  Typography,
  Span,
} from '@vp/swan';
import { isEmptyNavItem } from '../isEmptyNavItem';
import { useNavContext } from '../NavContext';
import { useHoverIntent } from '../../../../hooks/useHoverIntent';
import flattenNavLinks from '../flattenNavLinks';
import { useIsSsrContext } from '../../../../contexts/IsSsrContext';
import { Image } from '../../../Image';
import TrackableLink from '../../../TrackableLink';
import { BookendsBadge } from '../../../BookendsBadge';
import {
  LinkList,
  MenuTileProps,
  NavItem,
  NavItemWithChildrenType,
  SubCategoryEntryProps,
  TileMenuNavFlyout,
  ThirdLevelNavItemListProps,
} from '../../../../types';
import { SeeAllBar } from './SeeAllBar';

const hasRenderableChildren = (subCategory: NavItem) => {
  return (
    !isEmptyNavItem(subCategory) &&
    (subCategory as NavItemWithChildrenType).children?.filter(
      (subflyoutLink) =>
        subflyoutLink.url && subflyoutLink.image && subflyoutLink.image.url
    ).length > 0
  );
};

export const TileMenuNavigationFlyout = (
  props: TileMenuNavFlyout &
    LinkList<NavItem> &
    React.ComponentProps<'section'> & {
      parentNavigationDetailPath: string[];
    }
) => {
  const {
    focusedSubcategoryIdx,
    focusedThirdLevelIdx,
    focusAfterRender,
    isMxNav,
  } = useNavContext();
  const { isSsr } = useIsSsrContext();
  const seeAllRef = React.useRef<HTMLAnchorElement>(null);

  React.useEffect(() => {
    if (
      props.isInFocusedCategory &&
      focusAfterRender &&
      focusedThirdLevelIdx !== undefined
    ) {
      seeAllRef.current?.focus();
    }
  }, [props.isInFocusedCategory, focusAfterRender, focusedThirdLevelIdx]);

  const renderableChildren = props.children.filter((subCategoryLink) =>
    hasRenderableChildren(subCategoryLink)
  );

  const displayedSubCategory = //Either the currently focused category when flyout is open, or the first one with children
    renderableChildren.find(
      (subCategoryLink: NavItem, subcategoryIndex: number) => {
        return (
          props.isInFocusedCategory &&
          subcategoryIndex === focusedSubcategoryIdx &&
          hasRenderableChildren(subCategoryLink)
        );
      }
    ) ||
    renderableChildren.find((subCategoryLink: NavItem) => {
      return hasRenderableChildren(subCategoryLink);
    });

  if (!props.children) {
    return null;
  }

  if (isSsr) {
    return (
      <div className="site-header-nav-flyout">
        {flattenNavLinks(props.children)}
      </div>
    );
  } else {
    return (
      <section
        className={classNames(
          'site-header-nav-flyout site-header-nav-flyout-tile-menu',
          props.className
        )}
        id={props.id}
        aria-labelledby={props.labelledBy}
      >
        <BoundedContent
          marginTop={'7'}
          className={classNames(
            'full-width-container',
            'site-header-nav-flyout-content',
            'site-header-nav-flyout-tile-menu-content-wrapper',
            'site-header-nav-tile-menu-secondlevel-container-js'
          )}
        >
          <Box
            component="ul"
            className={classNames(
              'site-header-nav-tile-menu-menu-items',
              'site-header-nav-tile-menu-menu-items-second-level'
            )}
          >
            {renderableChildren.map(
              (subCategoryLink: NavItem, subcategoryIndex: number) => {
                return (
                  <SubCategoryEntry
                    index={subcategoryIndex}
                    subCategoryLink={subCategoryLink as NavItemWithChildrenType}
                    isFocused={subCategoryLink === displayedSubCategory}
                    parentNavigationDetailPath={
                      props.parentNavigationDetailPath
                    }
                    key={subCategoryLink.id}
                  />
                );
              }
            )}
          </Box>
          {displayedSubCategory && (
            <>
              <Box className="site-header-tile-menu-nav-tile-display">
                <ThirdLevelNavItemList
                  subCategory={displayedSubCategory as NavItemWithChildrenType}
                  categoryRenderIndex="na"
                  isFocused={props.isInFocusedCategory}
                  parentNavigationDetailPath={[
                    ...props.parentNavigationDetailPath,
                    ...(displayedSubCategory.analyticsId
                      ? [displayedSubCategory.analyticsId]
                      : []),
                  ]}
                />
              </Box>
              {!isMxNav && (
                <SeeAllBar
                  seeAllText={
                    (displayedSubCategory as NavItemWithChildrenType)
                      .seeAllCategory
                  }
                  seeAllUrl={displayedSubCategory.url}
                  parentNavigationDetailPath={props.parentNavigationDetailPath}
                  analyticsId={displayedSubCategory.analyticsId}
                  level={3}
                  ref={seeAllRef}
                />
              )}
            </>
          )}
        </BoundedContent>
      </section>
    );
  }
};

const SubCategoryEntry = ({
  index,
  isFocused,
  subCategoryLink,
  parentNavigationDetailPath,
}: SubCategoryEntryProps) => {
  const { hoverSubNavItem, isMxNav } = useNavContext();

  const subCategoryRef = useHoverIntent<HTMLLIElement>({
    hoverIn: () => hoverSubNavItem(index),
  });

  return (
    <li
      key={subCategoryLink.id}
      id={subCategoryLink.id}
      ref={subCategoryRef}
      className={classNames(
        { 'site-header-nav-flyout-focused': isFocused },
        'site-header-nav-tile-menu-second-level-element'
      )}
      data-item-title={subCategoryLink.text}
      tabIndex={0}
    >
      <button
        aria-expanded={isFocused}
        aria-controls={`${subCategoryLink.id}-category-listing ${subCategoryLink.id}-category-listing-focused`}
        className={classNames(
          `swan-button-skin-unstyled`,
          `site-header-nav-tile-menu-category-name`,
          'site-header-nav-secondlevel-js',
          'site-header-nav-tile-menu-secondlevel-js',
          subCategoryLink.theme
        )}
        onFocus={() => {
          hoverSubNavItem(index);
        }}
      >
        <Span
          id={`${subCategoryLink.id}-category-name`}
          className={classNames(
            'site-header-nav-tile-menu-category-heading',
            subCategoryLink.theme
          )}
          fontWeight="bold"
          fontSize="small"
        >
          {subCategoryLink.text}
          {subCategoryLink?.badge?.text && (
            <>
              <BookendsBadge {...subCategoryLink.badge} layout="visual" />
            </>
          )}
        </Span>
      </button>
      <Box
        className={classNames(
          'site-header-nav-flyout-tile-menu-subcategory',
          'site-header-nav-tile-menu-menu-items',
          'site-header-nav-tile-menu-menu-items-third-level'
        )}
      >
        <ThirdLevelNavItemList
          subCategory={subCategoryLink}
          categoryRenderIndex={index}
          parentNavigationDetailPath={[
            ...parentNavigationDetailPath,
            ...(subCategoryLink.analyticsId
              ? [subCategoryLink.analyticsId]
              : []),
          ]}
          isFocused={false}
        />
        {!isMxNav && (
          <SeeAllBar
            seeAllText={subCategoryLink.seeAllCategory}
            seeAllUrl={subCategoryLink.url}
            parentNavigationDetailPath={parentNavigationDetailPath}
            analyticsId={subCategoryLink.analyticsId}
            level={-1}
            additionalClasses={['site-header-nav-tile-menu-thirdlevel-js']}
          />
        )}
      </Box>
    </li>
  );
};

const ThirdLevelNavItemList = ({
  subCategory,
  categoryRenderIndex,
  isFocused,
  parentNavigationDetailPath,
}: ThirdLevelNavItemListProps) => {
  const { focusedThirdLevelIdx, focusAfterRender } = useNavContext();
  const refs = React.useRef<React.RefObject<HTMLAnchorElement>[]>([]);

  React.useEffect(() => {
    if (isFocused && focusAfterRender && focusedThirdLevelIdx !== undefined) {
      refs.current?.[focusedThirdLevelIdx]?.current?.focus();
    }
  }, [isFocused, focusedThirdLevelIdx, focusAfterRender]);

  return (
    <ul
      className={classNames('site-header-nav-tile-menu-menu-items', {
        'site-header-nav-tile-menu-menu-items-third-level-list': isFocused,
      })}
      id={`${subCategory.id}-category-listing${isFocused ? '-focused' : ''}`}
      aria-labelledby={`${subCategory.id}-category-name`}
    >
      {subCategory.children
        ?.filter(
          (subflyoutLink: any) =>
            subflyoutLink.url && subflyoutLink.image && subflyoutLink.image.url
        )
        .map((subflyoutLink: any, itemIndex: number) => {
          return (
            itemIndex < 5 && (
              <Box
                className="site-header-nav-flyout-tile-menu-tile"
                marginLeft={'7'}
                marginTop={'7'}
                paddingBottom={'7'}
                component="li"
                key={`${subflyoutLink.id}-${categoryRenderIndex}`}
              >
                <MenuTile
                  subflyoutLink={subflyoutLink}
                  parentNavigationDetailPath={parentNavigationDetailPath}
                  isFocused={isFocused}
                  tileIndex={itemIndex}
                  addRefToList={(
                    index: number,
                    ref: React.RefObject<HTMLAnchorElement>
                  ) => {
                    refs.current[index] = ref;
                  }}
                />
              </Box>
            )
          );
        })}
    </ul>
  );
};

const MenuTile = ({
  subflyoutLink,
  isFocused,
  parentNavigationDetailPath,
  tileIndex,
  addRefToList,
}: MenuTileProps) => {
  const ref = React.useRef<HTMLAnchorElement>(null);
  addRefToList(tileIndex, ref);

  return (
    <TrackableLink
      subSection="TopNav"
      navigationDetailPath={
        subflyoutLink.analyticsId
          ? parentNavigationDetailPath.concat([subflyoutLink.analyticsId])
          : []
      }
      linkType="Visual Tile"
      ref={ref}
      href={subflyoutLink.url}
      tabIndex={0}
      dataPosition={tileIndex + 1}
      className={classNames('site-header-nav-tile-menu-thirdlevel-js', {
        'site-header-nav-thirdlevel-js': isFocused,
      })}
    >
      <StandardTile>
        {subflyoutLink?.badge?.text && (
          <StandardTileOverlay>
            <BookendsBadge {...subflyoutLink.badge} layout="visual" />
          </StandardTileOverlay>
        )}
        <StandardTileImage>
          <Image image={subflyoutLink.image} alt={subflyoutLink.text} lazy />
        </StandardTileImage>
        <StandardTileContents>
          <StandardTileName>
            <Typography
              fontWeight="bold"
              className={classNames(subflyoutLink.theme)}
            >
              {subflyoutLink.text}
            </Typography>
          </StandardTileName>
          <StandardTileDescription>
            {subflyoutLink.description}
          </StandardTileDescription>
        </StandardTileContents>
      </StandardTile>
    </TrackableLink>
  );
};
