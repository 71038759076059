import * as React from 'react';

/**
 * A helper for React.createContext which allows creation of a context with no defaultValue on creation
 * but which does not require consumers to check for undefined
 * https://github.com/typescript-cheatsheets/react-typescript-cheatsheet/blob/master/README.md#context
 */
export function createCtx<A>() {
  const ctx = React.createContext<A | undefined>(undefined);
  function useCtx() {
    const c = React.useContext(ctx);
    if (!c) throw new Error('useCtx must be inside a Provider with a value');
    return c;
  }
  return [useCtx, ctx.Provider] as const; // make TypeScript infer a tuple, not an array of union types
}
