import React from 'react';
import { Box, Divider, H2, Icon } from '@vp/swan';
import classNames from 'classnames';
import { BackButtonIcon } from './BackButtonIcon';
import { useNavContext } from '../NavContext';

type MobileNavHeaderProps = {
  isOpen: boolean;
  text: string;
  onClose: () => void;
  backButtonLabel?: string;
};

export const MobileNavHeader = (props: MobileNavHeaderProps) => {
  const { isOpen, text, onClose } = props;
  const { backButtonRef, isMxNav } = useNavContext();

  return (<>
    <div
      className={classNames(
        'swan-modal-dialog-header',
        'site-header-nav-reduced-header',
        { 'swan-visually-hidden': !isOpen }
      )}
      onClick={() => onClose()}
      onKeyDown={(evt: React.KeyboardEvent<HTMLDivElement>) => {
        if (evt.key === ' ') {
          onClose();
        }
      }}
    >
      <button
        ref={backButtonRef}
        className={classNames(
          'snowflake-mobile-nav-back-button',
          'graphic-button',
          'graphic-button-skin-black',
          'site-header-nav-reduced-go-back'
        )}
        tabIndex={isOpen ? 0 : -1}
      >
        <span className="swan-visually-hidden">{props.backButtonLabel}</span>

        {isMxNav ? (
          <Box p={"2"} pl={0}>
            <Icon iconType="chevronLeft" size={'24p'} />
          </Box>
        ) : (
          <BackButtonIcon />
        )}
      </button>
      <H2
        fontSize={"standard"}
        fontWeight={isMxNav ? 'bold' : 'normal'}
        className={isMxNav ? classNames('site-header-nav-reduced-header-title swan-base-font-weight-bold'): classNames('site-header-nav-reduced-header-title')}
        marginTop={isMxNav ? 0 : 7}
      >
        {isOpen ? text : ''}
      </H2>
    </div>
    {isMxNav && isOpen && <Divider m={0} />}
  </>);
};
