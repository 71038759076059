import React from 'react';
import { useStyles, useSwanStyleKeys } from '@vp/ubik-context';

import { FullFooter as BaseFullFooter } from '../common/components/FullFooter';
import { stylesheet } from '../common/components/FullFooter/FullFooter.scss';
import { stylesheet as masksStylesheet } from '../common/components/FullFooter/FullFooterMasks.scss';
import { stylesheet as countrySelectorStylesheet } from '../common/components/FullFooter/CountrySelector/CountrySelector.scss';

import { swanStyleKeys } from '../common/utils/swanStyleKeys';

export const FullFooter = (props: any) => {
  useSwanStyleKeys(swanStyleKeys);
  useStyles(stylesheet, masksStylesheet, countrySelectorStylesheet);

  return <BaseFullFooter {...props} />;
};
