import React from 'react';
import { Box, FlexBox } from '@vp/swan';
import { Image } from '../Image';
import TrackableLink from '../TrackableLink';
import { SimpleLink } from '../../types';

export const SocialIconSection = ({
  socialIcons,
}: {
  socialIcons: SimpleLink[];
}) => {
  return (
    <FlexBox
      as="ul"
      role="list"
      display="flex"
      alignItems="flex-start"
      flexWrap="wrap"
    >
      {/* 
      role="list" is to account for Safari suppressing lists with the bullets removed 
      (see https://www.scottohara.me/blog/2019/01/12/lists-and-safari.html)
      */}
      {socialIcons.map((socialIcon: SimpleLink, index: number) => (
        <Box
          as={'li'}
          key={socialIcon.url}
          marginRight={'4'}
          className="site-footer-social-logo"
        >
          <TrackableLink
            href={socialIcon.url}
            navigationDetailPath={
              socialIcon.analyticsId ? [socialIcon.analyticsId] : []
            }
            dataPosition={index + 1}
            target="_blank"
            rel="noopener noreferrer external"
          >
            {socialIcon.image ? (
              <Image
                image={socialIcon.image}
                alt={socialIcon.text}
                lazy
                className="footer-social-icons"
              />
            ) : (
              <>{socialIcon.text}</>
            )}
          </TrackableLink>
        </Box>
      ))}
    </FlexBox>
  );
};
