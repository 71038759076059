import { PageAvailabilityServiceData } from '../../../types';
import { doRequest } from '../../../utils/http';

/**
 * Calls the availability service and parses the data to an object.
 * @param tenant The availability service tenant.
 * @param siteLocale The Locale
 * @param pageType The PageType
 */
export const getPublicPageAvailability = async (
  tenant: string,
  pageType: string
): Promise<Array<PageAvailabilityServiceData>> => {
  if (!tenant) {
    return Promise.reject(`tenant was not defined`);
  }
  if (!pageType) {
    return Promise.reject(`pageType parameter was not defined`);
  }

  return doRequest(
    `https://page-availability-service.prod.merch.vpsvc.com/api/v2/${tenant}/${pageType}/public`
  ).then((res) => res);
};
