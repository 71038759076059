import * as React from 'react';
import { isEmptyNavItem } from './isEmptyNavItem';
import {
  NavItem,
  NavItemLeafType,
  NavItemWithChildrenType,
} from '../../../types';

export default function flattenNavLinks(
  navItems: NavItem[],
  items: JSX.Element[] = [],
  visitedLinks: Set<string> = new Set<string>()
) {
  for (const navItem of navItems) {
    if (isEmptyNavItem(navItem)) {
      const leafNode = navItem as NavItemLeafType;
      if (!visitedLinks.has(leafNode.url)) {
        visitedLinks.add(leafNode.url);
        items.push(
          <a key={leafNode.url} href={leafNode.url}>
            {navItem.text}
          </a>
        );
      }
    } else {
      flattenNavLinks(
        (navItem as NavItemWithChildrenType).children,
        items,
        visitedLinks
      );
    }
  }
  return items;
}
