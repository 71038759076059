import React from 'react';
import { DeepLinkContextProvider } from './Hooks/useDeepLinkCountryLinks';
import { BoundedContent, FlexBox, Box, Li } from '@vp/swan';
import { AbsoluteGuaranteeSection } from './AbsoluteGuaranteeSection';
import { CountrySelectorButton } from './CountrySelector/CountrySelectorButton';
import { LegalShipping } from './LegalShipping';
import { CountrySelector } from './CountrySelector';
import TrackableLink from '../TrackableLink';
import { CountrySelectorProps, Tenants } from '../../types';

export const MasksLayout = (props: any) => {
  const { data, deepLink } = props;
  const {
    companyMessage,
    copyrightMessage,
    legalShippingMessage,
    lowerNavigationBar,
    absoluteGuarantee,
    localeSelectButtonA11yText,
    footerNavigation,
  } = data;

  const [isCountrySelectorOpen, setCountrySelectorOpen] =
    React.useState<boolean>(false);

  const countrySelector = data as CountrySelectorProps;
  countrySelector.isCountrySelectorOpen = isCountrySelectorOpen;
  countrySelector.setIsCountrySelectorOpen = setCountrySelectorOpen;
  const tenantData = data as Tenants;

  return (
    (<DeepLinkContextProvider deepLink={deepLink} tenants={tenantData}>
      <footer className="masks site-footer">
        <div className="full-width-container">
          <aside className="site-footer-bar typography-2017">
            <BoundedContent>
              <FlexBox className="masks-footer-upper-section">
                <div className="site-footer-upper-section">
                  <FlexBox flexDirection="column">
                    <AbsoluteGuaranteeSection
                      {...absoluteGuarantee}
                      fullWidth={true}
                    />
                    <Box marginTop={"7"} marginBottom={"7"}>
                      <CountrySelectorButton
                        countrySelector={countrySelector}
                        localeSelectButtonA11yText={localeSelectButtonA11yText}
                        fullWidth={true}
                        setCountrySelectorOpen={setCountrySelectorOpen}
                      />
                    </Box>
                  </FlexBox>
                </div>
                <div className="site-footer-lower-section">
                  <div className="site-footer-navigation">
                    {footerNavigation &&
                      footerNavigation.length > 0 &&
                      footerNavigation.map((footerNavItem: any) => (
                        <div key={footerNavItem.listHeader} className="col-12">
                          <h3 className="site-footer-navigation-header swan-text-1 link-list-heading">
                            {footerNavItem.listHeader}
                          </h3>
                          <FlexBox
                            as="ul"
                            className="site-footer-navigation-menu swan-link-list swan-text-1 swan-list-skin-minimal"
                            display="flex"
                            alignItems="flex-start"
                            role="list"
                            // role="list" is to account for Safari suppressing lists with the bullets removed
                            // (see https://www.scottohara.me/blog/2019/01/12/lists-and-safari.html)
                          >
                            {footerNavItem.links.map(
                              (linkItem: any, index: number) => (
                                <Li
                                  key={linkItem.url}
                                  className="site-footer-navigation-item"
                                >
                                  <TrackableLink
                                    navigationDetailPath={
                                      linkItem.analyticsId
                                        ? [linkItem.analyticsId]
                                        : []
                                    }
                                    href={linkItem.url}
                                    dataPosition={index + 1}
                                  >
                                    {linkItem.text}
                                  </TrackableLink>
                                </Li>
                              )
                            )}
                          </FlexBox>
                        </div>
                      ))}
                  </div>
                </div>
              </FlexBox>
              <FlexBox
                paddingTop={"7"}
                justifyContent="space-between"
                className="site-footer-lower-navigation-bar swan-text-1"
              >
                <FlexBox
                  as="ul"
                  role="list"
                  // role="list" is to account for Safari suppressing lists with the bullets removed
                  // (see https://www.scottohara.me/blog/2019/01/12/lists-and-safari.html)
                  className="site-footer-additional-links swan-list-skin-minimal"
                  display="flex"
                  alignItems="flex-start"
                  marginTop={0}
                >
                  {lowerNavigationBar.map((lowerNavItem: any) => (
                    <React.Fragment key={lowerNavItem.url}>
                      <Li className="site-footer-additional-link">
                        <TrackableLink
                          navigationDetailPath={
                            lowerNavItem.analyticsId
                              ? [lowerNavItem.analyticsId]
                              : []
                          }
                          href={lowerNavItem.url}
                        >
                          {lowerNavItem.text}
                        </TrackableLink>
                      </Li>
                    </React.Fragment>
                  ))}
                </FlexBox>
                <div className="site-footer-copyright-notice">
                  {companyMessage} {copyrightMessage}
                </div>
              </FlexBox>
              <LegalShipping legalShippingMessage={legalShippingMessage} />
            </BoundedContent>
          </aside>
        </div>
      </footer>
      <CountrySelector {...countrySelector} />
    </DeepLinkContextProvider>)
  );
};
