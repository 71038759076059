export const currentCountry = {
  alternateLanguages: [
    {
      url: 'https://www.vistaprint.com/',
      text: 'EN',
      analyticsId: '',
      geoIpBarText:
        'You are viewing the U.S. website.\n For a better experience, we suggest browsing the site for your region.',
      locale: 'en-us',
      continueText: 'Continue',
    },
    {
      url: 'https://www.vistaprint.com/es/',
      text: 'ES',
      analyticsId: '',
      geoIpBarText:
        'Está viendo el sitio web de VistaPrint para U.S. \nPara una mejor experiencia sugerimos que explore el sitio de su región.',
      locale: 'es-us',
      continueText: 'Continuar',
    },
  ],
  text: 'United States',
  url: 'https://www.vistaprint.com/',
  imageUrl: {
    image: {
      url: 'https://cms.cloudinary.vpsvc.com/image/upload/c_scale,dpr_auto,f_auto,q_auto,w_46/country_flags/usflag.svg',
    },
  },
  locale: 'en-us',
  analyticsId: '',
  cloudflareCountryCode: 'US',
};

export const supportedCountries = [
  {
    alternateLanguages: [],
    text: 'Australia',
    url: 'https://www.vistaprint.com.au',
    imageUrl: {
      image: {
        url: 'https://cms.cloudinary.vpsvc.com/image/upload/c_scale,dpr_auto,f_auto,q_auto,w_46/country_flags/auflag.svg',
      },
    },
    locale: 'en-au',
    analyticsId: '',
    cloudflareCountryCode: 'AU',
  },
  {
    alternateLanguages: [
      {
        url: 'https://www.vistaprint.be/',
        text: 'NL',
        analyticsId: '',
        locale: 'nl-be',
      },
      {
        url: 'https://www.vistaprint.be/fr/',
        text: 'FR',
        analyticsId: '',
        geoIpBarText:
          'Vous consultez le site web de VistaPrint pour le pays suivant  U.S. \nPour optimiser votre expérience, nous vous conseillons de parcourir le site de votre région.',
        locale: 'fr-be',
      },
    ],
    text: 'België',
    url: 'https://www.vistaprint.be/',
    locale: 'nl-be',
    imageUrl: {
      image: {
        url: 'https://cms.cloudinary.vpsvc.com/image/upload/c_scale,dpr_auto,f_auto,q_auto,w_46/country_flags/beflag.svg',
      },
    },
    geoIpBarText:
      'Vous consultez le site web de VistaPrint pour le pays suivant  U.S. \nPour optimiser votre expérience, nous vous conseillons de parcourir le site de votre région.',
    analyticsId: '',
    cloudflareCountryCode: 'BE',
  },
  {
    alternateLanguages: [],
    text: 'Brazil',
    url: 'https://www.printi.com.br/',
    locale: 'en-br',
    imageUrl: {
      image: {
        url: 'https://cms.cloudinary.vpsvc.com/image/upload/c_scale,dpr_auto,f_auto,q_auto,w_46/country_flags/brflag.svg',
      },
    },
    analyticsId: '',
    cloudflareCountryCode: 'BR',
  },
  {
    alternateLanguages: [
      {
        url: 'https://www.vistaprint.ca/',
        text: 'EN',
        analyticsId: '',
        locale: 'en-ca',
      },
      {
        url: 'https://www.vistaprint.ca/fr/',
        text: 'FR',
        analyticsId: '',
        locale: 'fr-ca',
        GeoIpBarText:
          'Vous consultez le site web de VistaPrint pour le pays suivant U.S \nPour optimiser votre expérience, nous vous conseillons de parcourir le site de votre région.',
        continueText: 'Continuer',
      },
    ],
    text: 'Canada',
    url: 'https://www.vistaprint.ca/',
    locale: 'en-ca',
    imageUrl: {
      image: {
        url: 'https://cms.cloudinary.vpsvc.com/image/upload/c_scale,dpr_auto,f_auto,q_auto,w_46/country_flags/caflag.svg',
      },
    },
    analyticsId: '',
    cloudflareCountryCode: 'CA',
  },
  {
    alternateLanguages: [],
    text: 'Danmark',
    url: 'https://www.vistaprint.dk/',
    imageUrl: {
      image: {
        url: 'https://cms.cloudinary.vpsvc.com/image/upload/c_scale,dpr_auto,f_auto,q_auto,w_46/country_flags/dkflag.svg',
      },
    },
    analyticsId: '',
    locale: 'da-dk',
    cloudflareCountryCode: 'DK',
    geoIpBarText:
      'Du ser VistaPrints hjemmeside for U.S. \nVi foreslår, at du browser hjemmesiden for din region, for at få en bedre oplevelse.',
    continueText: 'Fortsæt',
  },
  {
    alternateLanguages: [],
    text: 'Deutschland',
    url: 'https://www.vistaprint.de/',
    imageUrl: {
      image: {
        url: 'https://cms.cloudinary.vpsvc.com/image/upload/c_scale,dpr_auto,f_auto,q_auto,w_46/country_flags/deflag.svg',
      },
    },
    analyticsId: '',
    locale: 'de-de',
    cloudflareCountryCode: 'DE',
    geoIpBarText:
      'Sie befinden sich gerade auf der VistaPrint-Website für folgendes Land U.S. \nSehen Sie sich bitte die Website für Ihre Region an, um ein gezielteres Einkaufserlebnis sicherzustellen.',
    continueText: 'Weiter',
  },
  {
    alternateLanguages: [],
    text: 'España',
    url: 'https://www.vistaprint.es/',
    imageUrl: {
      image: {
        url: 'https://cms.cloudinary.vpsvc.com/image/upload/c_scale,dpr_auto,f_auto,q_auto,w_46/country_flags/esflag.svg',
      },
    },
    analyticsId: '',
    locale: 'es-es',
    cloudflareCountryCode: 'ES',
    geoIpBarText:
      'Estás viendo la página web de VistaPrint para U.S. \nPara disfrutar de una mejor experiencia, te recomendamos que visites la página de tu país.',
    continueText: 'Continuar',
  },
  {
    alternateLanguages: [],
    text: 'France',
    url: 'https://www.vistaprint.fr/',
    imageUrl: {
      image: {
        url: 'https://cms.cloudinary.vpsvc.com/image/upload/c_scale,dpr_auto,f_auto,q_auto,w_46/country_flags/frflag.svg',
      },
    },
    analyticsId: '',
    locale: 'fr-fr',
    cloudflareCountryCode: 'FR',
    geoIpBarText:
      'Vous consultez actuellement le site web VistaPrint du pays suivant  U.S. \nPour une expérience optimale, nous vous conseillons de parcourir le site de votre pays.',
    continueText: 'Continuer',
  },
  {
    alternateLanguages: [],
    text: 'India',
    url: 'https://www.vistaprint.in',
    imageUrl: {
      image: {
        url: 'https://cms.cloudinary.vpsvc.com/image/upload/c_scale,dpr_auto,f_auto,q_auto,w_46/country_flags/inflag.svg',
      },
    },
    analyticsId: '',
    locale: 'en-in',
    cloudflareCountryCode: 'IN',
  },
  {
    alternateLanguages: [],
    text: 'Ireland',
    url: 'https://www.vistaprint.ie',
    imageUrl: {
      image: {
        url: 'https://cms.cloudinary.vpsvc.com/image/upload/c_scale,dpr_auto,f_auto,q_auto,w_46/country_flags/ieflag.svg',
      },
    },
    analyticsId: '',
    locale: 'en-ie',
    cloudflareCountryCode: 'IE',
  },
  {
    alternateLanguages: [],
    text: 'Italia',
    url: 'https://www.vistaprint.it',
    imageUrl: {
      image: {
        url: 'https://cms.cloudinary.vpsvc.com/image/upload/f_auto,q_auto,w_46/country_flags/itFlag.svg',
      },
    },
    analyticsId: '',
    locale: 'it-it',
    cloudflareCountryCode: 'IT',
    geoIpBarText:
      'Stai visualizzando il sito web di VistaPrint per U.S. \nPer un’esperienza ottimale, ti suggeriamo di visitare il sito relativo alla tua area geografica.',
    continueText: 'Continua',
  },
  {
    alternateLanguages: [],
    text: 'Nederland',
    url: 'https://www.vistaprint.nl/',
    imageUrl: {
      image: {
        url: 'https://cms.cloudinary.vpsvc.com/image/upload/c_scale,dpr_auto,f_auto,q_auto,w_46/country_flags/nlflag.svg',
      },
    },
    analyticsId: '',
    locale: 'nl-nl',
    cloudflareCountryCode: 'NL',
    geoIpBarText:
      'Je bekijkt de VistaPrint-website voor U.S. \nVoor een betere beleving raden we je aan om de site voor je regio te bekijken.',
    continueText: 'Doorgaan',
  },
  {
    alternateLanguages: [],
    text: 'New Zealand',
    url: 'https://www.vistaprint.co.nz/',
    imageUrl: {
      image: {
        url: 'https://cms.cloudinary.vpsvc.com/image/upload/c_scale,dpr_auto,f_auto,q_auto,w_46/country_flags/nzflag.svg',
      },
    },
    analyticsId: '',
    locale: 'en-nz',
    cloudflareCountryCode: 'NZ',
  },
  {
    alternateLanguages: [],
    text: 'Norge',
    url: 'https://www.vistaprint.no/',
    imageUrl: {
      image: {
        url: 'https://cms.cloudinary.vpsvc.com/image/upload/c_scale,dpr_auto,f_auto,q_auto,w_46/country_flags/noflag.svg',
      },
    },
    analyticsId: '',
    locale: 'nb-no',
    cloudflareCountryCode: 'NO',
    geoIpBarText:
      'Du er inne på VistaPrint sine nettsider i U.S. \nVi anbefaler at du bruker de norske nettsidene for en best mulig opplevelse.',
    continueText: 'Fortsett',
  },
  {
    alternateLanguages: [],
    text: 'Österreich',
    url: 'https://www.vistaprint.at',
    imageUrl: {
      image: {
        url: 'https://cms.cloudinary.vpsvc.com/image/upload/c_scale,dpr_auto,f_auto,q_auto,w_46/country_flags/atflag.svg',
      },
    },
    analyticsId: '',
    locale: 'de-at',
    cloudflareCountryCode: 'AT',
    geoIpBarText:
      'Sie befinden sich gerade auf der VistaPrint-Website für folgendes Land U.S. \nSehen Sie sich bitte die Website für Ihre Region an, um ein gezielteres Einkaufserlebnis sicherzustellen.',
  },
  {
    alternateLanguages: [],
    text: 'Portugal',
    url: 'https://www.vistaprint.pt',
    imageUrl: {
      image: {
        url: 'https://cms.cloudinary.vpsvc.com/image/upload/c_scale,dpr_auto,f_auto,q_auto,w_46/country_flags/ptflag.svg',
      },
    },
    analyticsId: '',
    locale: 'pt-pt',
    cloudflareCountryCode: 'PT',
    geoIpBarText:
      'Está a ver o web site da VistaPrint para U.S. \nPara uma experiência melhor, sugerimos que aceda ao site da sua região.',
    continueText: 'Continuar',
  },
  {
    alternateLanguages: [
      {
        url: 'https://www.vistaprint.ch',
        text: 'DE',
        analyticsId: '',
        locale: 'de-ch',
      },
      {
        url: 'https://www.vistaprint.ch/fr/',
        text: 'FR',
        analyticsId: '',
        locale: 'fr-ch',
      },
      {
        url: 'https://www.vistaprint.ch/it/',
        text: 'IT',
        analyticsId: '',
        locale: 'it-ch',
      },
    ],
    text: 'Schweiz',
    url: 'https://www.vistaprint.ch/',
    imageUrl: {
      image: {
        url: 'https://cms.cloudinary.vpsvc.com/image/upload/c_scale,dpr_auto,f_auto,q_auto,w_46/country_flags/chflag.svg',
      },
    },
    locale: 'de-ch',
    analyticsId: '',
    cloudflareCountryCode: 'CH',
  },
  {
    alternateLanguages: [],
    text: 'Singapore',
    url: 'https://www.vistaprint.sg/',
    imageUrl: {
      image: {
        url: 'https://cms.cloudinary.vpsvc.com/image/upload/c_scale,dpr_auto,f_auto,q_auto,w_46/country_flags/sgflag.svg',
      },
    },
    analyticsId: '',
    locale: 'en-sg',
    cloudflareCountryCode: 'SG',
  },
  {
    alternateLanguages: [],
    text: 'Suomi',
    url: 'https://www.vistaprint.fi/',
    imageUrl: {
      image: {
        url: 'https://cms.cloudinary.vpsvc.com/image/upload/c_scale,dpr_auto,f_auto,q_auto,w_46/country_flags/fiflag.svg',
      },
    },
    analyticsId: '',
    locale: 'fi-fi',
    cloudflareCountryCode: 'FI',
    geoIpBarText:
      'Katsot tällä hetkellä seuraavaa VistaPrint-sivustoa U.S. \nParemman kokemuksen saamiseksi suosittelemme, että selaat sivustoa oman alueesi mukaan.',
    continueText: 'Jatka',
  },
  {
    alternateLanguages: [],
    text: 'Sverige',
    url: 'https://www.vistaprint.se/',
    imageUrl: {
      image: {
        url: 'https://cms.cloudinary.vpsvc.com/image/upload/c_scale,dpr_auto,f_auto,q_auto,w_46/country_flags/seflag.svg',
      },
    },
    analyticsId: '',
    locale: 'sv-se',
    cloudflareCountryCode: 'SE',
    geoIpBarText:
      'Du tittar på VistaPrints webbplats för U.S. \nFör en bättre upplevelse föreslår vi att du besöker webbplatsen för din region.',
    continueText: 'Fortsätt',
  },
  {
    alternateLanguages: [],
    text: 'United Kingdom',
    url: 'https://www.vistaprint.co.uk/',
    imageUrl: {
      image: {
        url: 'https://cms.cloudinary.vpsvc.com/image/upload/c_scale,dpr_auto,f_auto,q_auto,w_46/country_flags/gbflag.svg',
      },
    },
    locale: 'en-gb',
    analyticsId: '',
    cloudflareCountryCode: 'GB',
    geoIpBarText:
      'You are viewing the VistaPrint website for U.S. \nFor a better experience, we suggest browsing the website for your region.',
  },
  {
    alternateLanguages: [],
    text: '日本',
    url: 'https://www.vistaprint.jp/',
    imageUrl: {
      image: {
        url: 'https://cms.cloudinary.vpsvc.com/image/upload/c_scale,dpr_auto,f_auto,q_auto,w_46/country_flags/jpflag.svg',
      },
    },
    locale: 'jp-jp',
    analyticsId: '',
    cloudflareCountryCode: 'JP',
  },
];
