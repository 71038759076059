// -- browser storage -- //
import { SITE_HEADER_NAV_SKIN_HOLIDAY } from "../../constants/styleClasses";

export const setLocalStorageWithExpiry = (key: string, value: any, ttlInDays: number) => {

  const ttlInMilliseconds = ttlInDays * 24 * 60 * 60 * 1000;
	const now = new Date()
	const item = {
		value: value,
		expiry: now.getTime() + ttlInMilliseconds,
	}
	localStorage.setItem(key, JSON.stringify(item))
} 

export const getLocalStorageWithExpiry = (key: string) =>  {
	const itemStr = localStorage.getItem(key)
	
	if (!itemStr) {
		return null
	}
	const item = JSON.parse(itemStr)
	const now = new Date()
	
	if (now.getTime() > item.expiry) {
		localStorage.removeItem(key)
		return null
	}
	return item.value
}

// -- general utilities -- //
export const getLanguageFromLocale = (locale: string) => {
  return locale?.split("-")[0];
}

export const getCountryFromLocale = (locale: string) => {
  return locale?.split("-")[1];
}

export const isThemeHoliday = (theme: string) => {
	return theme === `${SITE_HEADER_NAV_SKIN_HOLIDAY}`;
}