import TrackableLink from '../TrackableLink';
import React from 'react';
import { Image } from '../Image';

export const MasksLayout = (props: any) => {
  const { inlineSecondaryLogo, host } = props.data;

  return (
    <header className="site-header site-header-slim masks">
      <div className="site-header-main site-header-main-slim full-width-container full-width-container-capped">
        <div className="site-header-logo-and-tagline">
          <div className="site-header-logo">
            <TrackableLink href={host} navigationDetailPath={['homePage']}>
              <Image
                className={'logo-fullsize'}
                image={inlineSecondaryLogo.image}
              />
              <Image
                className={'logo-narrow'}
                image={inlineSecondaryLogo.image}
              />
            </TrackableLink>
          </div>
        </div>
      </div>
    </header>
  );
};
