import React, { FC, ComponentProps } from 'react';
import { Typography } from '@vp/swan';

export const LegalShipping: FC<ComponentProps<'div'> & {
  legalShippingMessage: string;
}> = ({ legalShippingMessage }) => {
  return (
    (<Typography fontSize="xsmall" className="site-footer-lower-message-section">
      {legalShippingMessage}
    </Typography>)
  );
};
