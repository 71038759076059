import React, { useContext, useState, useEffect } from 'react';
import {
  Accordion,
  BoundedContent,
  Box,
  Collapsible,
  CollapsibleContent,
  CollapsibleSummary,
  CollapsibleSummaryButton,
  Column,
  FlexBox,
  GridContainer,
  H3,
  Icon,
  Row,
  Typography,
  useScreenClass,
  Li,
  Ul,
} from '@vp/swan';
import { Image } from '../Image';
import { DeepLinkContextProvider } from './Hooks/useDeepLinkCountryLinks';
import { AbsoluteGuaranteeSection } from './AbsoluteGuaranteeSection';
import { PaymentIcons } from '../PaymentIcons';
import { SocialIconSection } from './SocialIconSection';
import { CountrySelectorButton } from './CountrySelector/CountrySelectorButton';
import { LegalShipping } from './LegalShipping';
import { CountrySelector } from './CountrySelector';
import TrackableLink from '../TrackableLink';
import { TrackingContext } from '../../hooks/useTracking';
import {
  CountrySelectorProps,
  DeepLinkLocaleSelectorProps,
  FullFooterProps,
  LinkList,
  SimpleLink,
  Tenants,
} from '../../types';
import { XS, SMALL, MEDIUM, LARGE, XL } from '../../utils/screenClassHelper';

type Props = {
  data: FullFooterProps & CountrySelectorProps & Tenants;
  deepLink?: DeepLinkLocaleSelectorProps;
};

export const DefaultLayout = ({ data, deepLink }: Props) => {
  const { trackNavigation } = useContext(TrackingContext);
  const [isCountrySelectorOpen, setCountrySelectorOpen] =
    useState<boolean>(false);
  const screenClass = useScreenClass();
  const {
    socialIcons,
    companyMessage,
    copyrightMessage,
    legalShippingMessage,
    lowerNavigationBar,
    absoluteGuarantee,
    localeSelectButtonA11yText,
    footerNavigation,
    referralMessageHtml,
    referralMessageAnalyticsId,
    paymentTypes,
    modalDialogCloseLabel,
    awardCallout,
  } = data;

  useEffect(() => {
    // load script here if present in awardCallout.script property
    if (awardCallout?.script) {
      const script = document.createElement('script');
      script.src = awardCallout.script;
      document.body.appendChild(script);
    }
  }, [awardCallout?.script]);

  // data is a compound object, so we're making sure to extract the objects we care about.
  const countrySelector = data as CountrySelectorProps;
  countrySelector.isCountrySelectorOpen = isCountrySelectorOpen;
  countrySelector.setIsCountrySelectorOpen = setCountrySelectorOpen;
  const tenantData = data as Tenants;

  return (
    <DeepLinkContextProvider deepLink={deepLink} tenants={tenantData}>
      <footer className="full-footer swan-dark-mode">
        <div>
          <Box component="aside" className="site-footer-bar typography-2017">
            <BoundedContent
              paddingTop={9}
              paddingBottom={9}
              className="site-footer-top"
            >
              <GridContainer>
                <Row>
                  <Column span={12}>
                    <Box
                      className="site-footer-guarantee-logo"
                      marginBottom={'7'}
                    >
                      {absoluteGuarantee?.icon?.image && (
                        <Image
                          ariaHidden
                          image={absoluteGuarantee.icon.image}
                          lazy
                        />
                      )}
                    </Box>
                  </Column>
                </Row>
                <Row>
                  <Column span={3} className="site-footer-left-section">
                    {absoluteGuarantee && (
                      <AbsoluteGuaranteeSection {...absoluteGuarantee} />
                    )}
                    <div
                      className="site-footer-referral-message"
                      dangerouslySetInnerHTML={{
                        __html: referralMessageHtml,
                      }}
                      onClick={(
                        evt: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => {
                        //only if root click is the a tag
                        if ((evt.target as HTMLElement).nodeName === 'A') {
                          const href = (evt.target as HTMLAnchorElement).href;
                          trackNavigation(
                            referralMessageAnalyticsId,
                            href ? new URL(href).pathname : ''
                          );
                        }
                      }}
                      onKeyDown={(evt: React.KeyboardEvent<HTMLDivElement>) => {
                        //only if root click is the a tag
                        if (
                          (evt.target as HTMLElement).nodeName === 'A' &&
                          evt.key === 'Enter'
                        ) {
                          const href = (evt.target as HTMLAnchorElement).href;
                          trackNavigation(
                            referralMessageAnalyticsId,
                            href ? new URL(href).pathname : ''
                          );
                        }
                      }}
                    />
                  </Column>
                  <Column
                    span={8}
                    push={1}
                    className="site-footer-right-section"
                  >
                    <Box className="site-footer-navigation">
                      <GridContainer>
                        <Row>
                          {footerNavigation.map((footerNavItem: LinkList) => (
                            <Column
                              key={footerNavItem.listHeader}
                              span={3}
                              spanXs={12}
                              spanSm={12}
                            >
                              {[LARGE, MEDIUM, XL].includes(screenClass) && (
                                <>
                                  <H3
                                    fontSize={'small'}
                                    className="site-footer-navigation-header link-list-heading"
                                  >
                                    {footerNavItem.listHeader}
                                  </H3>
                                  <Box
                                    as="ul"
                                    role="list"
                                    // role="list" is to account for Safari suppressing lists with the bullets removed
                                    // (see https://www.scottohara.me/blog/2019/01/12/lists-and-safari.html)
                                    aria-label={footerNavItem.listHeader}
                                    className="site-footer-navigation-menu swan-link-list"
                                  >
                                    {footerNavItem?.links?.map(
                                      (linkItem: SimpleLink, index: number) => (
                                        <Li
                                          component="li"
                                          fontSize={'small'}
                                          key={`${linkItem.url}-${index}`}
                                          className="site-footer-navigation-item"
                                        >
                                          <TrackableLink
                                            navigationDetailPath={
                                              linkItem.analyticsId
                                                ? [linkItem.analyticsId]
                                                : []
                                            }
                                            href={linkItem.url}
                                            dataPosition={index + 1}
                                          >
                                            {linkItem.text}
                                          </TrackableLink>
                                        </Li>
                                      )
                                    )}
                                  </Box>
                                </>
                              )}
                              {[XS, SMALL].includes(screenClass) && (
                                <Accordion>
                                  <Collapsible
                                    collapsibleId={footerNavItem.listHeader}
                                  >
                                    <CollapsibleSummary>
                                      <H3 fontSize={'small'}>
                                        <CollapsibleSummaryButton className="site-footer-navigation-header link-list-heading">
                                          {footerNavItem.listHeader}
                                          <Icon
                                            iconType="caretDown"
                                            size="20p"
                                            skin="white"
                                            style={{ float: 'right' }}
                                          />
                                        </CollapsibleSummaryButton>
                                      </H3>
                                    </CollapsibleSummary>
                                    <CollapsibleContent>
                                      <Ul className="site-footer-navigation-menu swan-link-list">
                                        {footerNavItem?.links?.map(
                                          (
                                            linkItem: SimpleLink,
                                            index: number
                                          ) => (
                                            <Li
                                              fontSize={'small'}
                                              key={`${linkItem.url}-${index}`}
                                              className="site-footer-navigation-item"
                                            >
                                              <TrackableLink
                                                navigationDetailPath={
                                                  linkItem.analyticsId
                                                    ? [linkItem.analyticsId]
                                                    : []
                                                }
                                                href={linkItem.url}
                                                dataPosition={index + 1}
                                              >
                                                {linkItem.text}
                                              </TrackableLink>
                                            </Li>
                                          )
                                        )}
                                      </Ul>
                                    </CollapsibleContent>
                                  </Collapsible>
                                </Accordion>
                              )}
                            </Column>
                          ))}
                          {awardCallout && (
                            <Column
                              span={5}
                              spanXs={12}
                              spanSm={12}
                              className="site-footer-award-zone"
                            >
                              {awardCallout.img ? (
                                <Image
                                  image={awardCallout.img}
                                  alt={awardCallout.altText}
                                  className="site-footer-award-image"
                                />
                              ) : null}
                              <Typography
                                fontSize="xsmall"
                                textColor="standard"
                                darkMode
                                className="site-footer-award-message"
                                onClick={(evt: React.MouseEvent) => {
                                  //only if root click is the a tag
                                  if (
                                    (evt.target as HTMLElement).nodeName === 'A'
                                  ) {
                                    const href = (
                                      evt.target as HTMLAnchorElement
                                    ).href;
                                    trackNavigation(
                                      awardCallout.analyticsId,
                                      href ? new URL(href).pathname : ''
                                    );
                                  }
                                }}
                                onKeyDown={(evt: React.KeyboardEvent) => {
                                  //only if root click is the a tag
                                  if (
                                    (evt.target as HTMLElement).nodeName ===
                                      'A' &&
                                    evt.key === 'Enter'
                                  ) {
                                    const href = (
                                      evt.target as HTMLAnchorElement
                                    ).href;
                                    trackNavigation(
                                      awardCallout.analyticsId,
                                      href ? new URL(href).pathname : ''
                                    );
                                  }
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: awardCallout.html,
                                }}
                              />
                            </Column>
                          )}
                        </Row>
                        {[XS, SMALL].includes(screenClass) && (
                          <Row>
                            <Column
                              span={12}
                              className="site-footer-right-section-social"
                            >
                              <SocialIconSection socialIcons={socialIcons} />
                              <CountrySelectorButton
                                countrySelector={countrySelector}
                                localeSelectButtonA11yText={
                                  localeSelectButtonA11yText
                                }
                                setCountrySelectorOpen={setCountrySelectorOpen}
                              />
                            </Column>
                          </Row>
                        )}
                      </GridContainer>
                    </Box>
                  </Column>
                </Row>
              </GridContainer>
            </BoundedContent>
            <Box
              className="site-footer-bottom"
              paddingBottom={7}
              paddingTop={8}
            >
              <BoundedContent>
                <Box className="site-footer-lower-navigation-bar">
                  <GridContainer>
                    <Row>
                      <Column
                        span={5}
                        spanSm={12}
                        className="site-footer-lower-left"
                      >
                        <Box className="site-footer-additional-links-container">
                          <FlexBox
                            as="ul"
                            role="list"
                            // role="list" is to account for Safari suppressing lists with the bullets removed
                            // (see https://www.scottohara.me/blog/2019/01/12/lists-and-safari.html)
                            className="site-footer-additional-links"
                            justifyContent="flex-start"
                            flexWrap="wrap"
                          >
                            {lowerNavigationBar.map(
                              (lowerNavItem: SimpleLink, index: number) => (
                                <Li
                                  fontSize="xsmall"
                                  marginBottom={'2'}
                                  marginRight={'4'}
                                  marginTop={0}
                                  className="site-footer-additional-link"
                                  key={`${lowerNavItem.url}-${index}`}
                                >
                                  <TrackableLink
                                    navigationDetailPath={
                                      lowerNavItem.analyticsId
                                        ? [lowerNavItem.analyticsId]
                                        : []
                                    }
                                    href={lowerNavItem.url}
                                    dataPosition={index + 1}
                                  >
                                    {lowerNavItem.text}
                                  </TrackableLink>
                                </Li>
                              )
                            )}
                          </FlexBox>
                        </Box>
                        <Typography
                          fontSize="xsmall"
                          className="site-footer-copyright-notice"
                        >
                          {companyMessage} {copyrightMessage}
                        </Typography>
                        <LegalShipping
                          legalShippingMessage={legalShippingMessage}
                        />
                      </Column>
                      <Column span={4} spanSm={12}>
                        <PaymentIcons paymentTypes={paymentTypes} />
                      </Column>
                      {[LARGE, MEDIUM, XL].includes(screenClass) && (
                        <Column
                          span={3}
                          spanSm={8}
                          pushSm={4}
                          className="site-footer-lower-right"
                        >
                          <SocialIconSection socialIcons={socialIcons} />
                          <CountrySelectorButton
                            countrySelector={countrySelector}
                            localeSelectButtonA11yText={
                              localeSelectButtonA11yText
                            }
                            setCountrySelectorOpen={setCountrySelectorOpen}
                          />
                        </Column>
                      )}
                    </Row>
                  </GridContainer>
                </Box>
              </BoundedContent>
            </Box>
          </Box>
        </div>
      </footer>
      <CountrySelector
        {...countrySelector}
        closeButtonLabel={modalDialogCloseLabel}
      />
    </DeepLinkContextProvider>
  );
};
