import * as React from 'react';
import { Icon, Visible } from '@vp/swan';
import { Image } from '../Image';
import TrackableLink from '../TrackableLink';
import { LogoAndTaglineProps } from '../../types';
import { TrackingContext } from 'hooks/useTracking';
import { Logger } from '../../utils/logger';

export const Logos = (props: LogoAndTaglineProps) => {
  const { trackHeaderLinkClicked } = React.useContext(TrackingContext);

  // -- TODO : remove this temporary code later -- // 
  if(props.homeUrl?.includes('undefined')) {
    Logger.instance.info(`full header homepage link contains undefined - ${props.homeUrl}`, {
      actualLogoElementURL : document.querySelector(".site-header-logo a")?.getAttribute("href"),
      bookendsKey : document?.getElementById("bookendsHeader")?.getAttribute("data-headerkey"),
    });
  }
  // -- TODO : remove this temporary code later -- // 

  return (
    <div className="site-header-logo-and-tagline">
      <Visible xs sm>
        <button
          className="site-header-navburger swan-button-skin-unstyled"
          onClick={() => {
            props.mobileNavToggle(true)
            trackHeaderLinkClicked("Hamburger", "TopNav");
          }}
        >
          <span className="swan-visually-hidden">{props.navburgerLabel}</span>
          <span aria-hidden="true" data-dialog-show="navigation-dialog">
            <Icon iconType={"menu"} />
          </span>
        </button>
      </Visible>
      {props.showMobileSearch && <Visible xs>
        <button
          className="swan-button-skin-unstyled mobile-search-toggle"
          onClick={() => props.openMobileSearch()}
        >
          <span className="swan-visually-hidden">{props.searchLabel}</span>
          <span aria-hidden="true" data-dialog-show="navigation-dialog">
            <Icon iconType="search" size="20p" />
          </span>
        </button>
      </Visible>}
      <div className="site-header-logo">
        <TrackableLink
          navigationDetailPath={['homePage']}
          href={props.homeUrl}
          aria-label={props.logoDescription}
          data-fidoprefetchhint={'EAGER'}
        >
          <span aria-hidden="true">
            <Visible sm md lg xl>
              <Image className="logo-fullsize" image={props.inlineLogo.image} />
            </Visible>
            <Visible xs>
              <Image
                className="logo-narrow"
                image={props.inlineSecondaryLogo.image}
              />
            </Visible>
          </span>
        </TrackableLink>
      </div>
    </div>
  );
};