import React from 'react';
import { Divider, Typography } from '@vp/swan';
import { useIsSsrContext } from '../../../../contexts/IsSsrContext';
import TrackableLink from '../../../TrackableLink';
import { Identity, IdentityState, UserFunctionsProps } from '../../../../types';
import MyAccountButton from './MyAccount/MyAccountButton';
import { useNavContext } from '../NavContext';

declare global {
  interface Window {
    tracking: any;
  }
}

export const UserFunctions = (props: UserFunctionsProps) => {
  const [identity, setIdentity] = React.useState<IdentityState | undefined>(
    undefined
  );

  const { isSsr } = useIsSsrContext();
  const { isMxNav } = useNavContext();

  const isSignedIn = identity?.isSignedIn;

  const signOut = (e: any) => {
    e.preventDefault();
    if (window.tracking && window.tracking.reset) {
      window.tracking.reset();
    }
    props.auth.signOut();
  };

  const signIn = () => {
    if (!isSignedIn) {
      props.auth.signIn([], [], { trackingCaller: 'header' });
    }
  };

  React.useEffect(() => {
    if (props.auth) {
      const handleIdentityUpdate = (event: any) => {
        updateState(event.detail);
      };

      const updateState = (identity: Identity) => {
        setIdentity((previousIdentity: any) => {
          const currentIdentity = {
            authorizationHeader: identity && identity.authorizationHeader,
            isSignedIn: !!identity && !!identity.isSignedIn,
            changed: false,
          };

          if (
            !previousIdentity ||
            previousIdentity.authorizationHeader !==
              currentIdentity.authorizationHeader ||
            previousIdentity.isSignedIn !== currentIdentity.isSignedIn
          ) {
            currentIdentity.changed = true;
          }

          return currentIdentity;
        });
      };

      updateState(props.auth.getIdentity?.());

      window.addEventListener('userIdentity', handleIdentityUpdate);

      return () => {
        window.removeEventListener('userIdentity', handleIdentityUpdate);
      };
    }
  }, [props.auth]);

  if (isSsr) {
    return (
      <>
        <a href={props.accountLink.url}>{props.accountLink.text}</a>
        <a href={props.helpLink.url}>{props.helpLink.text}</a>
      </>
    );
  } else {
    return (
      (<section className="site-header-user-functions-section">
        {isMxNav && <Divider m={0} />}
        <nav className="site-header-brands" aria-label={props.accountLink.text}>
          <ul className="site-header-user-functions">
            <li className="site-header-user-functions-item">
              <TrackableLink
                navigationDetailPath={
                  props.helpLink.analyticsId
                    ? [props.helpLink.analyticsId]
                    : ['help']
                }
                href={props.helpLink.url}
              >
                <Typography fontSize={"standard"}>{props.helpLink.text}</Typography>
              </TrackableLink>
            </li>
            {props.myProjectsLink?.text && props.myProjectsLink?.url && (
              <li className="site-header-user-functions-item">
                <TrackableLink
                  navigationDetailPath={
                    props.myProjectsLink?.analyticsId
                      ? [props.myProjectsLink.analyticsId]
                      : ['myProjects']
                  }
                  href={props.myProjectsLink?.url}
                >
                  <Typography fontSize={"standard"}>
                    {props.myProjectsLink?.text}
                  </Typography>
                </TrackableLink>
              </li>
            )}
            <li className="site-header-user-functions-item">
              {isSignedIn ? (
                <MyAccountButton
                  text={props.accountResources?.translation?.messages?.account}
                  accountLink={props.accountLink}
                />
              ) : (
                <button className="swan-button-skin-unstyled" onClick={signIn}>
                  <Typography fontSize={"standard"}>
                    {props.accountResources?.translation?.messages?.signin}
                  </Typography>
                </button>
              )}
            </li>
            {isSignedIn && props.accountResources && (
              <li className="site-header-user-functions-item">
                <button className="swan-button-skin-unstyled" onClick={signOut}>
                  <Typography fontSize={"standard"}>
                    {props.accountResources.translation.messages.signout}
                  </Typography>
                </button>
              </li>
            )}
          </ul>
        </nav>
        {isMxNav ? <Divider my={"3"} /> : <hr />}
      </section>)
    );
  }
};
