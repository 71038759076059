import React, { useState, useContext } from 'react';
import { AlternateLanguageLink } from './AlternateLanguageLink';
import { useDeepLinkCountryLinksContext } from '../Hooks/useDeepLinkCountryLinks';
import { Image } from '../../Image';
import TrackableLink from '../../TrackableLink';
import { FlexBox, Li } from '@vp/swan';
import {
  ComponentSource,
  Country,
  DeepLinkMappedData,
  LocaleList,
} from '../../../types';
import { setSelectedCountry } from '../../FullHeader/GeoIpBar/utils';
import { TrackingContext } from '../../../hooks/useTracking';
import { getRedirectURL } from '../Hooks/usePageAvailability';

export const CountrySelectorCountry = ({
  text,
  alternateLanguages,
  imageUrl,
  url,
  dataPosition,
  cloudflareCountryCode,
  isCookie,
  componentSource,
  currentLocale,
  locale,
}: Country & {
  dataPosition: number;
  cloudflareCountryCode: string;
  isCookie: boolean;
  componentSource?: ComponentSource;
  currentLocale?: string;
  locale: string;
}) => {
  const isGeoIP = componentSource === 'geoIP';
  const searchQuery = `?redirect=true&redirectOrigin=Selected_${cloudflareCountryCode}`;

  const initialURL = isGeoIP ? url + searchQuery : url;
  const [altLanguageUrlData, setAltLanguageUrlData] =
    useState<(LocaleList | undefined)[]>();
  const { linkData } = useDeepLinkCountryLinksContext();
  const { trackFlyOutClicked } = useContext(TrackingContext);

  const generateURL = (redirectUrl: string) => {
    const countryUrl = new URL(url);
    const targetBaseURL = new URL(redirectUrl, initialURL);
    const targetURL = targetBaseURL.href + (isGeoIP ? searchQuery : '');

    const matchingLiveSiteConfiguration = linkData.find(
      (link: DeepLinkMappedData) =>
        countryUrl.host.includes(link.liveSiteData.canonicalDomain)
    );
    if (matchingLiveSiteConfiguration) {
      if (matchingLiveSiteConfiguration.supportedLanguagesUrlData) {
        const altLanguagesUrlData =
          matchingLiveSiteConfiguration.supportedLanguagesUrlData;
        setAltLanguageUrlData(altLanguagesUrlData);
      }
      const newUrlPath =
        matchingLiveSiteConfiguration.urlServiceData?.urlData.countryLinks.url;

      if (newUrlPath) {
        const localizedUrl = new URL(
          newUrlPath + redirectUrl,
          countryUrl.origin
        );
        return localizedUrl.href;
      }
      return targetURL;
    }
    return targetURL;
  };

  const handleClick = async () => {
    const pathname = window?.location?.pathname;
    const redirectUrl = await getRedirectURL(
      pathname,
      locale,
      currentLocale || 'en-us'
    );
    if (isCookie) {
      setSelectedCountry(cloudflareCountryCode);
    }
    if (isGeoIP) {
      trackFlyOutClicked(
        'GeoIP Placement Fly-Out',
        'select',
        cloudflareCountryCode
      );
    }
    const deepURL = generateURL(redirectUrl);
    window.location.href = deepURL;
  };

  const hasAlternateLanguages = alternateLanguages && alternateLanguages.length;
  const alternateLanguageJsx = alternateLanguages.map((locale: any) => {
    const localizedUrl = altLanguageUrlData?.find(
      (altUrlData: any) =>
        altUrlData?.locale.toLowerCase().split('-')[0] ===
        locale.text.toLowerCase()
    )?.urlData.countryLinks.url;
    dataPosition += 1;
    return (
      <AlternateLanguageLink
        key={locale.text}
        link={locale}
        parentCountry={text}
        dataPosition={dataPosition}
        localizedUrl={localizedUrl}
        componentSource={componentSource}
        cloudflareCountryCode={cloudflareCountryCode}
        currentLocale={currentLocale}
      />
    );
  });
  const alternateLanguageList = hasAlternateLanguages ? (
    <FlexBox
      as={'ul'}
      role="list"
      // role="list" is to account for Safari suppressing lists with the bullets removed
      // (see https://www.scottohara.me/blog/2019/01/12/lists-and-safari.html)
      className={'swan-list swan-list-skin-minimal'}
      flexDirection={'row'}
    >
      {alternateLanguageJsx}
    </FlexBox>
  ) : (
    <></>
  );
  return (
    <Li className="country-selector-item swan-mr-6 swan-mb-4">
      <TrackableLink
        useButton
        navigationDetailPath={[`${text.replace(' ', '')}Site`]}
        className="country-selector-country"
        dataPosition={dataPosition}
        onClick={handleClick}
      >
        <div className="country-selector-flag-image-container">
          <Image role="img" image={imageUrl.image} lazy={true} />
          {/* role="img" is to account for a Safari bug that occasionally reads SVGs as multiple images */}
        </div>
        {text}
      </TrackableLink>
      {alternateLanguageList}
    </Li>
  );
};
