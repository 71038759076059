import React from 'react';
import { Icon } from '@vp/swan';
import { useDeepLinkCountryLinksContext } from '../Hooks/useDeepLinkCountryLinks';
import { CountrySelectorButtonProps } from '../../../types';

export const CountrySelectorButton = ({
  countrySelector,
  localeSelectButtonA11yText,
  setCountrySelectorOpen,
}: CountrySelectorButtonProps) => {
  const { retrieveDataForDeepLinks } = useDeepLinkCountryLinksContext();

  return (
    <div className="site-footer-locale-toggle-container">
      <button
        className="site-footer-locale-toggle"
        data-dialog-show="countrySelectorDialog"
        onClick={() => {
          retrieveDataForDeepLinks();
          setCountrySelectorOpen(true);
        }}
      >
        <img
          className="px-s"
          data-dialog-show="countrySelectorDialog"
          alt={countrySelector.currentCountry.text}
          src={countrySelector.currentCountry.imageUrl.image.url}
        />
        <Icon iconType="caretDown" size="20p" skin="white" />
        <span className="swan-visually-hidden">
          {localeSelectButtonA11yText}
        </span>
      </button>
    </div>
  );
};
