import React from 'react';
import classNames from 'classnames';
import { Typography } from '@vp/swan';
import TrackableLink from '../TrackableLink';
import { BrandsProps, BrandLink } from '../../types';

export const Brands = (props: BrandsProps) => {
  return (<>
    {props.brandsLinks.map((brandLink: BrandLink, index: number) => {
      return (
        (<li
          key={`key-${brandLink.text}`}
          className={classNames('site-header-brands-list-item', {
            'site-header-current-brand': brandLink.isCurrentBrand,
          })}
        >
          <TrackableLink
            subSection="TopBar"
            navigationDetailPath={
              brandLink.analyticsId ? [brandLink.analyticsId] : []
            }
            href={brandLink.url}
            dataPosition={index + 1}
          >
            <Typography fontSize={"small"}>{brandLink.text}</Typography>
          </TrackableLink>
        </li>)
      );
    })}
  </>);
};
