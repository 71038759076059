import React, { useContext, useEffect, useRef, useState } from 'react';
import { languageBarEnabledContries } from 'utils/languageBarData';
import {
  getCountryFromLocale,
  getLanguageFromLocale,
  getLocalStorageWithExpiry,
  setLocalStorageWithExpiry,
} from 'utils/utilities';
import InfoTopBar from './InfoTopBar';
import { Button, Link, P, useScreenClass } from '@vp/swan';
import { getCookie, setCookie } from './FullHeader/GeoIpBar/utils';
import { TrackingContext } from 'hooks/useTracking';
import { getRedirectURL } from './FullFooter/Hooks/usePageAvailability';

const LanguageBar = ({ data }: any) => {
  const [showLanguageBar, setShowLanguagebar] = useState(false);
  const { trackFlyOutClicked, trackFlyOutViewed, pageName, pageSection } =
    useContext(TrackingContext);

  const { locale } = data;

  const websiteLanguage: string = getLanguageFromLocale(locale);
  const websiteCountry: string = getCountryFromLocale(locale);

  const screenClass = useScreenClass();

  useEffect(() => {
    const languageBarHidden =
      getLocalStorageWithExpiry('hideLanguageBar') ||
      getCookie('hideLanguageBarForSession');
    const userLocale: string =
      navigator.languages && navigator.languages.length
        ? navigator.languages[0]
        : navigator.language;

    const userPreferredLanguage: string = getLanguageFromLocale(userLocale);

    if (
      websiteLanguage !== userPreferredLanguage &&
      languageBarEnabledContries[websiteCountry] &&
      !languageBarHidden
    ) {
      setShowLanguagebar(true);
    }
  }, [websiteLanguage, websiteCountry, pageName]);

  const savedTrackFlyOutViewed = useRef(trackFlyOutViewed);
  savedTrackFlyOutViewed.current = trackFlyOutViewed;

  const runRef = useRef(false);
  useEffect(() => {
    if (
      showLanguageBar &&
      !runRef.current &&
      pageSection !== 'undefined' &&
      pageSection
    ) {
      savedTrackFlyOutViewed.current(
        'Language Selector Fly-Out',
        'view',
        websiteLanguage
      );
      runRef.current = true;
    }
  }, [websiteLanguage, pageSection, showLanguageBar]);

  const handleCloseClick = () => {
    setShowLanguagebar(false);
    setLocalStorageWithExpiry('hideLanguageBar', 'true', 7);
    trackFlyOutClicked('Language Selector Fly-Out', 'close', websiteLanguage);
  };

  const handleLanguageClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    localeCode: string,
    url: string
  ) => {
    event.preventDefault();
    setCookie('hideLanguageBarForSession', 'true');

    const userLanguageChoice = getLanguageFromLocale(localeCode);

    trackFlyOutClicked(
      'Language Selector Fly-Out',
      'select',
      userLanguageChoice
    );
    if (websiteLanguage === userLanguageChoice) {
      setShowLanguagebar(false);
    } else {
      window.location.href = url;
    }
  };

  const handleClick = async (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    language: any
  ) => {
    const pathname = window?.location?.pathname;
    const redirectUrl = await getRedirectURL(
      pathname,
      language.localeCode,
      locale
    );

    handleLanguageClick(
      event,
      language?.localeCode,
      (redirectUrl === '/' ? language.url : window.location.origin) +
        redirectUrl
    );
  };

  const titleHtml = languageBarEnabledContries[
    websiteCountry
  ]?.alternateLanguages?.map((language: any) => (
    <P key={language?.localeCode} m={0} fontWeight="bold">
      {language?.title}
    </P>
  ));

  const buttonsHtml = languageBarEnabledContries[
    websiteCountry
  ]?.alternateLanguages?.map((language: any) => (
    <Button
      key={language?.localeCode}
      paddingY="3"
      width={screenClass === 'xs' ? 'full-width' : 'standard'}
      bgc={'standard'}
      textColor={'standard'}
      render={(props) => (
        <Link
          className={props.className}
          onClick={(event) => handleClick(event, language)}
          tabIndex={0}
        >
          {props.children}
        </Link>
      )}
    >
      {language?.text}
    </Button>
  ));

  return (
    <>
      {showLanguageBar && (
        <InfoTopBar
          id="language-bar"
          title={titleHtml}
          buttons={buttonsHtml}
          fontSize={'xsmall'}
          handleCloseClick={handleCloseClick}
          style={{
            position: 'relative',
            top: '0px !important',
          }}
        />
      )}
    </>
  );
};

export default LanguageBar;
