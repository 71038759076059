import * as React from 'react';
import { ProgressiveImage } from '@vp/swan';
import { BookendsImageType, BookendsImageProps } from '../types';

export const Image = (
  props: BookendsImageType & React.ComponentProps<'div'> & BookendsImageProps
) => {
  const { className, image, lazy, ariaHidden, role = 'img', alt = `` } = props;

  if (!image) {
    return null;
  }

  if ('contents' in image) {
    return (
      <span
        className={className}
        dangerouslySetInnerHTML={{ __html: image.contents }}
      ></span>
    );
  } else {
    return (
      <ProgressiveImage src={image.url} trigger={lazy ? 'inView' : 100}>
        {(theImg, isLoading) => (
          <img
            alt={alt}
            aria-hidden={ariaHidden}
            role={role}
            style={{ opacity: isLoading ? 0.1 : 1 }}
            src={theImg.src}
            className={className}
          />
        )}
      </ProgressiveImage>
    );
  }
};
