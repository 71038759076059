import * as React from 'react';
import classNames from 'classnames';
import {
  BoundedContent,
  Box,
  StandardTile,
  StandardTileContents,
  StandardTileDescription,
  StandardTileImage,
  StandardTileName,
  StandardTileOverlay,
  Typography,
} from '@vp/swan';
import { useIsSsrContext } from '../../../../contexts/IsSsrContext';
import flattenNavLinks from '../flattenNavLinks';
import { Image } from '../../../Image';
import TrackableLink from '../../../TrackableLink';
import { BookendsBadge } from '../../../BookendsBadge';
import {
  LinkList,
  NavItem,
  TileProps,
  TileListNavFlyoutProps,
} from '../../../../types';
import { useNavContext } from '../NavContext';
import { SeeAllBar } from './SeeAllBar';

export const TileListNavFlyout = (
  props: TileListNavFlyoutProps &
    LinkList<NavItem> &
    React.ComponentProps<'section'>
) => {
  const { isMxNav } = useNavContext();
  const { isSsr } = useIsSsrContext();

  if (!props.children) {
    return null;
  }

  if (isSsr) {
    return (
      <div className="site-header-nav-flyout">
        {flattenNavLinks(props.children)}
      </div>
    );
  } else {
    return (
      <section
        className={classNames(
          'site-header-nav-flyout site-header-nav-flyout-tile-list',
          props.className
        )}
        id={props.id}
        aria-labelledby={props.labelledBy}
      >
        <BoundedContent style={{ width: '100%' }} marginTop={7}>
          <div
            className={classNames(
              'full-width-container-capped',
              'full-width-container',
              'site-header-nav-flyout-content'
            )}
          >
            <ul
              className={classNames(
                'site-header-nav-menu-items',
                'full-width-container'
              )}
            >
              {props.children
                .filter(
                  (tileItem: any) =>
                    tileItem.url && tileItem.image && tileItem.image.url
                )
                .map((tileItem: any, itemIndex: number) => {
                  return (
                    itemIndex < 5 && (
                      <Box
                        className="site-header-nav-flyout-tile-menu-tile"
                        marginLeft={itemIndex === 0 ? undefined : '7'}
                        marginTop={'7'}
                        paddingBottom={'7'}
                        component="li"
                        key={`${tileItem.id}`}
                      >
                        <Tile
                          tileItem={tileItem}
                          parentNavigationDetailPath={
                            props.parentNavigationDetailPath
                          }
                          dataPosition={itemIndex + 1}
                        />
                      </Box>
                    )
                  );
                })}
            </ul>
          </div>
          {!isMxNav && (
            <SeeAllBar
              seeAllText={props.seeAllText}
              seeAllUrl={props.seeAllUrl}
              parentNavigationDetailPath={props.parentNavigationDetailPath}
              analyticsId={props.seeAllAnalyticsId}
            />
          )}
        </BoundedContent>
      </section>
    );
  }
};

const Tile = ({
  tileItem,
  parentNavigationDetailPath,
  dataPosition,
}: TileProps) => {
  return (
    <TrackableLink
      subSection="TopNav"
      navigationDetailPath={
        tileItem.analyticsId
          ? parentNavigationDetailPath.concat([tileItem.analyticsId])
          : []
      }
      linkType="Tile List Tile"
      href={tileItem.url}
      tabIndex={0}
      className={classNames('site-header-nav-secondlevel-js')}
      dataPosition={dataPosition}
    >
      <StandardTile>
        {tileItem?.badge?.text && (
          <StandardTileOverlay>
            <BookendsBadge {...tileItem.badge} layout="visual" />
          </StandardTileOverlay>
        )}
        <StandardTileImage>
          <Image image={tileItem.image} alt={tileItem.text} lazy />
        </StandardTileImage>
        <StandardTileContents>
          <StandardTileName>
            <Typography fontWeight="bold">{tileItem.text}</Typography>
          </StandardTileName>
          <StandardTileDescription>
            {tileItem.description}
          </StandardTileDescription>
        </StandardTileContents>
      </StandardTile>
    </TrackableLink>
  );
};
