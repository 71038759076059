import { type IdentityProviderProps } from '@vp/ubik-context';
import { getEnvironmentSuffix } from '../common/hooks/useAccountResources';

type VPAuthConfig = (typeof IdentityProviderProps)['auth'];

export const createAuthConfig = (culture: string): VPAuthConfig => ({
  culture,
  developmentMode: getEnvironmentSuffix() === 'dev',
  options: {
    requireSession: true,
    customText: 'Sign in to see your account',
  },
});
