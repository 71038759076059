import * as React from 'react';
import {
  chooseVariationFromContext,
  mergeBookendsVariant,
} from '@vp/bookends-ab-testing-utilities';
import { getTests } from '../utils/optimizely';
import { initialize, whenAvailable, getAllExperiments } from '@vp/ab-reader';
import {
  ABTest,
  ABTestData,
  BookendsComponent,
  BookendsResponse,
  BookendsServiceCallParameters,
} from '../types';
import { doRequest } from '../utils/http';

const DEFAULT_HOST = 'https://bookends.cdn.vpsvc.com';

export const useBookendsData = function <
  ComponentType,
  ComponentFetchType extends BookendsServiceCallParameters = BookendsServiceCallParameters
>(
  props: (BookendsResponse<ComponentType> | ComponentFetchType) & ABTestData,
  component: BookendsComponent
): (ComponentType & ABTestData) | undefined {
  const [userAbTestData, setUserAbTestData] = React.useState<
    ABTest[] | undefined
  >(undefined);
  const [bookendsData, setBookendsData] = React.useState<
    ComponentType | undefined
  >(() => {
    // Must provide props to SSR without requiring the useEffect
    if (!isDataSourcedByService(props)) {
      const { abTestData, testIds, generationTimeStamp, variations } = props;
      return {
        ...mergeBookendsVariant(
          props,
          chooseVariationFromContext(
            testIds,
            abTestData || [],
            new Date(generationTimeStamp),
            Object.keys(variations)
          ),
          new Date(generationTimeStamp)
        ),
      };
    }
  });

  const { version, headerOrFooter, variant } = component;

  React.useEffect(() => {
    initialize();

    function setBookendsDataAsync(data: any) {
      async function setData() {
        const headerData = await mergeBaseAndTestData(props, data);
        setBookendsData(headerData);
      }
      if (data.abTestData) {
        setData();
      } else {
        whenAvailable(setData, 5000);
      }
    }

    whenAvailable(() => {
      getTests(getAllExperiments).then((tests) => setUserAbTestData(tests));
    }, 5000);

    if (isDataSourcedByService(props)) {
      const {
        culture,
        tenant,
        requestor,
        host = DEFAULT_HOST,
        hideVat,
        hideSearch,
      } = props;
      if (culture && tenant && requestor) {
        doRequest(
          `${host}/${version}/${tenant}/${culture}/${headerOrFooter}/${variant}?hideVat=${hideVat}&hideSearch=${hideSearch}&requestor=${requestor}`
        )
          .then((response: any) => response)
          .then((data: any) => {
            setBookendsDataAsync(data);
          });
      }
    } else {
      setBookendsDataAsync(props);
    }
  }, [props, version, headerOrFooter, variant]);

  return bookendsData
    ? { ...bookendsData, abTestData: userAbTestData }
    : undefined;
};

function isDataSourcedByService<
  ComponentType,
  ComponentFetchType extends BookendsServiceCallParameters
>(
  props: BookendsResponse<ComponentType> | ComponentFetchType
): props is ComponentFetchType {
  const typedProps = props as ComponentFetchType;
  return !!typedProps.culture && !!typedProps.requestor && !!typedProps.tenant;
}

async function mergeBaseAndTestData(
  props: any & ABTestData,
  data: BookendsResponse<any>
) {
  let { abTestData } = props;
  const { testIds, generationTimeStamp, variations } = data;

  if (!abTestData) {
    abTestData = await getTests(getAllExperiments).then((result) => result);
  }

  return {
    ...mergeBookendsVariant(
      data,
      chooseVariationFromContext(
        testIds,
        abTestData || [],
        new Date(generationTimeStamp),
        Object.keys(variations)
      ),
      new Date(generationTimeStamp)
    ),
  };
}
