import * as React from 'react';
// @ts-ignore
import hoverintent from 'hoverintent';
import { HoverIntentProps } from '../types';

export const useHoverIntent = <T extends HTMLElement>({
  hoverIn,
  hoverOut,
  opts,
}: HoverIntentProps): React.Ref<T> => {
  const elementRef = React.useRef<T>(null);

  React.useEffect(() => {
    const hoverListener = hoverintent(
      elementRef.current,
      function () {
        hoverIn?.();
      },
      function () {
        hoverOut?.();
      }
    ).options(
      opts ?? {
        timeout: 200,
        interval: 50,
        sensitivity: 25,
      }
    );
    return () => {
      hoverListener.remove();
    };
  }, [hoverIn, hoverOut, opts]);

  return elementRef;
};
