import { BaseTrackingData } from '../common/types';

interface TrackingMetaMap {
  pageName: string;
  pageStage: string;
  pageSection: string;
  pageDept: string;
}

export const createTrackingConfiguration = (): BaseTrackingData => {
  const nameMap: Record<string, keyof TrackingMetaMap> = {
    pageName: 'pageName',
    pageStage: 'pageStage',
    pageSection: 'pageSection',
    pageDept: 'pageDept',
  };

  // page templates can add meta tags to the page to configure tracking
  const trackingMetadata = document.querySelectorAll('meta[name^="page"]');

  const trackingData: Partial<TrackingMetaMap> = {};

  Array.from(trackingMetadata).forEach((meta) => {
    const property = meta.getAttribute('name');
    if (property && property in nameMap) {
      const name = nameMap[property];
      const value = meta.getAttribute('content');
      if (name && value) {
        trackingData[name] = value;
      }
    }
  });

  return trackingData as BaseTrackingData;
};
