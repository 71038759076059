import * as React from 'react';
import { Logger } from '../utils/logger';

export type ErrorBoundaryProps = {
  children: React.ReactNode;
  render?: (props: any, state: any) => any;
  suppressError?: boolean;
  component?: string;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    const errorMessage = error instanceof Error ? error.message : undefined;
    const errorStack = error instanceof Error ? error.stack : undefined;
    const context = {
      contextData: {
        error,
        errorMessage,
        errorStack,
        errorInfo,
        component: this.props.component,
      },
    };
    // ADDED: Because some info from the error boundary is not being logged because of JSON.stringify
    console.error('[react-bookends error-boundary]', context);
    Logger.instance.error('Bookends Client Error: ', context);
  }

  render() {
    if (this.state.hasError) {
      if (this.props.render) {
        return this.props.render(this.props, this.state);
      } else if (this.props.suppressError) {
        return '';
      } else {
        return '';
      }
    }

    return this.props.children;
  }
}
