import UAParser from 'ua-parser-js';

interface IEnviroment {
  browser: { name: string; version: string };
  device: { model: string; type: string; vendor: string };
  engine: { name: string; version: string };
  os: { name: string; version: string };
}

interface ILogger {
  debug: (message: string, context?: Metadata) => void;
  info: (message: string, context?: Metadata) => void;
  warning: (message: string, context?: Metadata) => void;
  error: (message: string, context?: Metadata) => void;
}

type Metadata = Record<string, unknown>;

enum LogLevel {
  DEBUG = 'debug',
  INFO = 'info',
  WARNING = 'warn',
  ERROR = 'error',
}

const DEFAULT_DOMAIN = 'prdct';
const LOG_FORWARDER_URL =
  'https://log-forwarder.product-page-layouts.vpsvc.com/';
const SERVICE = 'react-bookends';

function getEnvironment(): IEnviroment {
  const parser = new UAParser();
  const result = parser.getResult();
  return {
    browser: {
      name: result.browser.name ?? '',
      version: result.browser.version ?? '',
    },
    device: {
      model: result.device.model ?? '',
      type: result.device.type ?? '',
      vendor: result.device.vendor ?? '',
    },
    engine: {
      name: result.engine.name ?? '',
      version: result.engine.version ?? '',
    },
    os: {
      name: result.os.name ?? '',
      version: result.os.version ?? '',
    },
  };
}

class BrowserLogger {
  private _environment: IEnviroment;
  private _userAgent: string;
  private _url: string;
  private _domain: string;
  private _service: string;

  public constructor(service: string, domain: string = DEFAULT_DOMAIN) {
    this._environment = getEnvironment();
    this._userAgent = window.navigator.userAgent;
    this._url = window.location.href;
    this._domain = domain;
    this._service = service;
  }

  /**
   * Log a debug message
   * @param message The debug message to be logged
   * @param context Optional metadata to be logged
   */
  public async debug(message: string, context: Metadata = {}): Promise<void> {
    await this.logWithLevel(LogLevel.DEBUG, message, context);
  }

  /**
   * Log a warning message
   * @param message The warning message to be logged
   * @param context Optional metadata to be logged
   */
  public async warning(message: string, context: Metadata = {}): Promise<void> {
    await this.logWithLevel(LogLevel.WARNING, message, context);
  }

  /**
   * Log an error message
   * @param message The error message to be logged
   * @param context Optional metadata to be logged
   */
  public async error(message: string, context: Metadata = {}): Promise<void> {
    await this.logWithLevel(LogLevel.ERROR, message, context);
  }

  /**
   * Log an info message
   * @param message The info message to be logged
   * @param meta Optional metadata to be logged
   */
  public async info(message: string, context: Metadata = {}): Promise<void> {
    await this.logWithLevel(LogLevel.INFO, message, context);
  }

  private async logWithLevel(
    logLevel: LogLevel,
    message: string,
    context: Metadata
  ): Promise<void> {
    await this.sendLog({
      message,
      level: logLevel,
      context,
    });
  }

  private async sendLog(payload: Metadata): Promise<void> {
    const sanitizedUrl = encodeURIComponent(this._url);
    try {
      const response = await fetch(`${LOG_FORWARDER_URL}log`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...payload,
          'labels.domain': this._domain,
          service: this._service,
          environment: this._environment,
          userAgent: this._userAgent,
          url: sanitizedUrl,
        }),
      });

      if (!response.ok) {
        console.error('Error sending log', response.status);
      }
    } catch (error: any) {
      console.error('Unexpected error sending log');
    }
  }
}
class DevLogger implements ILogger {
  info(message: string, context?: Metadata): void {
    console.info(message, context);
  }
  error(message: string, context?: Metadata): void {
    console.error(message, context);
  }
  debug(message: string, context?: Metadata): void {
    console.debug(message, context);
  }
  warning(message: string, context?: Metadata): void {
    console.warn(message, context);
  }
}

class BrowserLoggerWrapper implements ILogger {
  private _browserInstance: BrowserLogger;
  constructor(service: string) {
    this._browserInstance = new BrowserLogger(service);
  }
  info(message: string, context?: Metadata): void {
    this._browserInstance
      .info(message, context)
      .catch((_e) => console.log('Error logging'));
  }
  error(message: string, context?: Metadata): void {
    this._browserInstance
      .error(message, context)
      .catch((_e) => console.log('Error logging'));
  }
  debug(message: string, context?: Metadata): void {
    this._browserInstance
      .debug(message, context)
      .catch((_e) => console.log('Error logging'));
  }
  warning(message: string, context?: Metadata): void {
    this._browserInstance
      .warning(message, context)
      .catch((_e) => console.log('Error logging'));
  }
}

export class Logger {
  private static _browserInstance: ILogger;
  private constructor() {}

  public static get instance(): ILogger {
    if (Logger._browserInstance === undefined) {
      // Logs will go to new relic only in production enviroment and not from local or staging or server.
      Logger._browserInstance =
        typeof window === 'undefined' ||
        window.location.hostname.startsWith('staging.') ||
        window.location.hostname.startsWith('localhost')
          ? new DevLogger()
          : new BrowserLoggerWrapper(SERVICE);
    }

    return Logger._browserInstance;
  }
}
