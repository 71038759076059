import TrackableLink from '../../TrackableLink';
import React from 'react';
import { SimpleLink } from '../../../types';
import { Li, Typography } from '@vp/swan';
import { getRedirectURL } from '../Hooks/usePageAvailability';

export const AlternateLanguageLink = ({
  link,
  fontWeight,
  parentCountry,
  dataPosition,
  localizedUrl,
  componentSource,
  cloudflareCountryCode,
  currentLocale,
}: {
  link: SimpleLink;
  fontWeight?: any;
  parentCountry: string;
  dataPosition: number;
  localizedUrl?: string;
  componentSource?: string;
  cloudflareCountryCode?: string;
  currentLocale?: string;
}) => {
  const deepUrl = localizedUrl
    ? new URL(localizedUrl, link.url).href
    : link.url;

  const isGeoIP = componentSource === 'geoIP';
  const searchQuery = `?redirect=true&redirectOrigin=Selected_${link?.text?.toLowerCase()}-${cloudflareCountryCode}`;

  const handleClick = async () => {
    const pathname = window?.location?.pathname;
    const redirectUrl = await getRedirectURL(
      pathname,
      link.locale || '',
      currentLocale || 'en-us'
    );

    const origin = new URL(deepUrl).origin;
    if (isGeoIP) {
      if (redirectUrl) {
        window.location.href = `${origin}${redirectUrl}${searchQuery}`;
      } else {
        window.location.href = `${deepUrl}${searchQuery}`;
      }
      return;
    }
    window.location.href = deepUrl;
  };

  return (
    <Li pb={0}>
      <Typography as="span" fontWeight={fontWeight} fontSize='small'>
        <TrackableLink
          useButton
          navigationDetailPath={[
            `${link.text}${parentCountry.replace(' ', '')}Site`,
          ]}
          className={`swan-ml-2 country-selector-alternate-language ${
            fontWeight === 'bold' ? 'swan-font-weight-bold' : ''
          }`}
          dataPosition={dataPosition}
          onClick={handleClick}
        >
          {link.text}
        </TrackableLink>
      </Typography>
    </Li>
  );
};
