import React, { type ErrorInfo } from 'react';
import { clientMount } from '@vp/ubik-fragment-react';
import { type MountWithRootOptions } from '@vp/ubik-fragment-types';
import { IdentityProvider } from '@vp/ubik-context';

import { getComponent } from '../ubik-entry/mapping';
import { createTrackingConfiguration } from './tracking-configuration';
import { createAuthConfig } from '../ubik-entry/auth';

// TODO: add support for types
const selectComponent = (props: any) => {
  const key = props.type;
  const trackingConfig = createTrackingConfiguration();

  const Component = getComponent(key);
  return () => (
    <IdentityProvider auth={createAuthConfig(props.locale)}>
      <Component {...(props as any)} trackingConfiguration={trackingConfig} />
    </IdentityProvider>
  );
};

export const mount: MountWithRootOptions<any> = async (
  rootElement,
  ssrProps,
  rootOptions
) => {
  const Component = selectComponent(ssrProps);

  rootOptions.hydrateOptions = {
    onCaughtError: (error: Error, errorInfo: ErrorInfo) => {
      console.error('[react-bookends-fragment] onCaughtError', {
        error,
        causee: error.cause,
        componentStack: errorInfo.componentStack,
      });
    },
    onUncaughtError: (error: Error, errorInfo: ErrorInfo) => {
      console.error('[react-bookends-fragment] onUncaughtError', {
        error,
        causee: error.cause,
        componentStack: errorInfo.componentStack,
      });
    },
    onRecoverableError: (error: Error, errorInfo: ErrorInfo) => {
      console.error('[react-bookends-fragment] onRecoverableError', {
        error,
        causee: error.cause,
        componentStack: errorInfo.componentStack,
      });
    },
  };

  return clientMount(<Component />, rootElement, rootOptions);
};
