import React from 'react';
import { useStyles, useSwanStyleKeys } from '@vp/ubik-context';
import { swanStyleKeys } from '../common/utils/swanStyleKeys';

import { SlimHeader as BaseSlimHeader } from '../common/components/SlimHeader';
import { stylesheet } from '../common/components/SlimHeader/SlimHeader.scss';

export const SlimHeader = (props: any) => {
  useSwanStyleKeys(swanStyleKeys);
  useStyles(stylesheet);

  return <BaseSlimHeader {...props} />;
};
