import * as React from 'react';
import { createCtx } from '../utils/createCtx';
import { IsSsrContextType } from '../types';

export const [useIsSsrContext, IsSsrContext] = createCtx<IsSsrContextType>();

export const IsSsrProvider: React.FC<{}> = props => {
  const [isSsr, setIsSsr] = React.useState<boolean>(true);
  React.useEffect(() => {
    setIsSsr(false);
  }, []);

  return (
    // eslint-disable-next-line prettier/prettier
    (<IsSsrContext value={{ isSsr }}>{props.children}</IsSsrContext>)
  );
};
