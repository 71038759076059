export const languageBarEnabledContries :any = {
  CA : {
    alternateLanguages : [
      {
        url: 'https://www.vistaprint.ca/',
        text: 'English',
        title: 'Please select your preferred language:',
        localeCode: "en-CA",
      },
      {
        url: 'https://www.vistaprint.ca/fr/',
        text: 'Français',
        title: 'Veuillez sélectionner la langue de votre choix:',
        localeCode: "fr-CA",
      },
    ]
  },
  CH : {
    alternateLanguages : [
      {
        url: 'https://www.vistaprint.ch',
        text: 'Deutsch',
        title: 'Bitte wählen Sie Ihre Sprache:',
        localeCode: "de-CH",
      },
      {
        url: 'https://www.vistaprint.ch/fr/',
        text: 'Français',
        title: 'Veuillez sélectionner la langue de votre choix:',
        localeCode: "fr-CH",
      },
      {
        url: 'https://www.vistaprint.ch/it/',
        text: 'Italiano',
        title: 'Scegli la tua lingua preferita:',
        localeCode: "it-CH",
      },
    ]
  },
  BE : {
    alternateLanguages : [
      {
        url: 'https://www.vistaprint.be/',
        text: 'Nederlands',
        title: 'Selecteer je voorkeurstaal:',
        localeCode: "nl-BE",
      },
      {
        url: 'https://www.vistaprint.be/fr/',
        text: 'Français',
        title: 'Veuillez sélectionner la langue de votre choix:',
        localeCode: "fr-BE",
      },
    ]
  }, 
}