import { Box } from '@vp/swan';
import classNames from 'classnames';
import TrackableLink from '../../../TrackableLink';
import React from 'react';
import { SeeAllBarProps } from '../../../../types';
import { useNavContext } from '../NavContext';

export const SeeAllBar = React.forwardRef(
  (
    {
      seeAllUrl,
      seeAllText,
      analyticsId,
      parentNavigationDetailPath,
      level = 2,
      additionalClasses = [],
    }: SeeAllBarProps,
    ref
  ) => {
    let levelClassKey: string = "";
    switch (level) {
      case 3:
        levelClassKey = 'site-header-nav-thirdlevel-js';
        break;
      case 2:
        levelClassKey = 'site-header-nav-secondlevel-js';
        break;
      default:
    }

    const { locale } = useNavContext();

    const capitalizedTextLocales = ['de-de', 'de-at', 'de-ch']

    let classes: string[] = ['site-header-visual-nav-flyout-bottom-link'];

    if (locale && !capitalizedTextLocales.includes(locale.toLowerCase())) {
      classes.push('site-header-visual-nav-flyout-bottom-link-lowercase-text')
    };

    return seeAllText && seeAllUrl ? (
      <Box className={classNames(classes)}>
        <TrackableLink
          subSection="TopNav"
          navigationDetailPath={
            analyticsId ? parentNavigationDetailPath.concat(analyticsId) : []
          }
          href={seeAllUrl}
          className={classNames(
            'swan-link',
            'swan-link-skin-cta',
            {
              [levelClassKey]: levelClassKey !== undefined,
            },
            ...additionalClasses
          )}
          tabIndex={0}
          ref={ref}
        >
          {seeAllText}
        </TrackableLink>
      </Box>
    ) : null;
  }
);
