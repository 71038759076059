import * as React from 'react';
import {
  Callout,
  StandardTile,
  StandardTileImage,
  StandardTileContents,
  StandardTileName,
  StandardTileDescription,
  StandardTileOverlay,
  Typography,
} from '@vp/swan';
import { Image } from '../../../Image';
import TrackableLink from '../../../TrackableLink';
import classNames from 'classnames';
import { useNavContext } from '../NavContext';

export const PromoTile = (props: any) => {
  const { navItem, navigationSection } = props;
  const { isMxNav } = useNavContext();
  return (
    <li key={navItem.id} className="mobile-promo-tile-container">
      <TrackableLink
        href={navItem.url}
        className="site-header-nav-secondlevel-js"
        subSection="TopNav"
        navigationDetailPath={navItem.analyticsId ? [navItem.analyticsId] : []}
        linkType="Promo Tile"
        key={navItem.id + '-heading'}
        navigationSection={navigationSection}
      >
        <StandardTile layout={isMxNav ? 'vertical' : 'horizontal'}>
          {navItem?.badge?.text && (
            <StandardTileOverlay>
              {/* Designs call for inverse announcement skin. Maybe later: skin={navItem.badge.skin} */}
              <Callout
                skin="accent"
                variant="inverse"
                textAllCaps={navItem.badge.allCaps}
              >
                {navItem.badge.text}
              </Callout>
            </StandardTileOverlay>
          )}
          <StandardTileImage>
            <Image image={navItem.image} />
          </StandardTileImage>
          <StandardTileContents>
            <StandardTileName>
              <Typography
                fontWeight="bold"
                className={classNames(navItem.theme)}
              >
                {navItem.text}
              </Typography>
            </StandardTileName>
            <StandardTileDescription>
              {navItem.description}
            </StandardTileDescription>
          </StandardTileContents>
        </StandardTile>
      </TrackableLink>
    </li>
  );
};
