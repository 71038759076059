import React from 'react';
import classNames from 'classnames';
import { Image } from '../../../Image';
import TrackableLink from '../../../TrackableLink';
import { Span } from '@vp/swan';

export const BrandsSection = (props: any) => {
  return (
    (<section
      id="site-header-brand-banner-section"
      className={classNames({ 'swan-visually-hidden': props.isDrilledDown }, "site-header-brands-section")}
    >
      <span className="site-header-brands-heading">{props.brandsHeading}</span>
      <nav className="site-header-brands" aria-label={props.brandNavLabel}>
        <ul
          className={classNames(
            {
              'stylized-dialog-buttons-margin-correction': props.vatLocale,
            },
            `site-header-brands-list`
          )}
        >
          {props.brands?.map((brandLink: any, index: number) => {
            return (
              (<li
                className="site-header-brands-list-item"
                key={`${brandLink.url}-${index}`}
              >
                <TrackableLink
                  subSection="TopBar"
                  navigationDetailPath={
                    brandLink.analyticsId ? [brandLink.analyticsId] : []
                  }
                  href={brandLink.url}
                  dataPosition={index + 1}
                >
                  {brandLink.image && brandLink.image.url ? (
                    <Image
                      className="nav-brand-image"
                      alt={brandLink.text}
                      image={brandLink.image}
                    />
                  ) : (
                    <Span fontWeight="normal" fontSize={"standard"}>
                      {brandLink.text}
                    </Span>
                  )}
                </TrackableLink>
              </li>)
            );
          })}
        </ul>
      </nav>
    </section>)
  );
};
