import { type IdentityProviderProps } from '@vp/ubik-context';
import { getEnvironmentSuffix } from '../hooks/useAccountResources';
type VPAuthConfig = (typeof IdentityProviderProps)['auth'];

export const getAuthHeaderAndShopperId = (auth: any)  => {
  return {
    authHeader: auth.getAuthorizationHeader(),
    shopperId:
      auth.isSignedIn() &&
      auth.getProfile()['https://claims.cimpress.io/canonical_id'],
  };
};

export const createAuthConfig = (culture: string): VPAuthConfig => ({
  culture,
  developmentMode: getEnvironmentSuffix() === 'dev',
  options: {
    requireSession: true,
    customText: 'Sign in to see your account',
  },
});
