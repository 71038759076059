export const BASE_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://bookends-source.prod.merch.vpsvc.com/v3'
    : 'https://bookends-source.sandbox.merch.vpsvc.com/v3';
export const DEFAULT_LOCALE = 'en-US';
export const DEFAULT_TENANT = 'vistaprint';
export const DEFAULT_SOURCE = 'header';
export const DEFAULT_TYPE = 'slim';
export const DEFAULT_REQUESTOR = 'browser';
export const DEFAULT_CACHE_TTL = 120; // stdTTL: 2 mins, It sets the default time in seconds for each cached item to live before it expires.
export const DEFAULT_CACHE_CHECK_PERIOD = 600; // checkperiod: 10 mins, It determines how often the cache performs a cleanup operation to remove expired items.
export const DEV_ENVIRONMENT = 'dev';

// https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/171245584/Custom+JWT+Claims
export const CLAIM_ACCOUNT = 'https://claims.cimpress.io/account';
export const CLAIM_CANONICAL_ID = 'https://claims.cimpress.io/canonical_id';
export const CLAIM_CIMPRESS_INTERNAL =
  'https://claims.cimpress.io/cimpress_internal';
export const CLAIM_IS_CUSTOMER = 'https://claims.cimpress.io/is_customer';
export const CLAIM_UNIFIED_IDENTITIES =
  'https://claims.cimpress.io/unified_identities';
export const CLAIM_WAS = 'https://claims.cimpress.io/was';
