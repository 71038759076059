import * as React from 'react';
import classNames from 'classnames';
import {
  ModalDialog,
  ModalDialogContent,
  ModalDialogCloseButton,
  ModalDialogHeader,
  ModalDialogBody,
  ModalDialogFooter,
  useScreenClass,
} from '@vp/swan';
import { VatToggle } from '../../VatToggle';
import { VatContext } from '../../../../contexts/VatContext';
import { NavContextProvider, useNavContext } from '../NavContext';
import { MobileNavHeader } from './MobileNavHeader';
import { UserFunctions } from './UserFunctions';
import { MobileNavItems } from './MobileNavItems';
import { BrandsSection } from './BrandsSection';
import { useIsSsrContext } from '../../../../contexts/IsSsrContext';
import { FullMobileNavLinksProps, MobileNavProps } from '../../../../types';

import MyAccountLinks from './MyAccount/MyAccountLinks';
import {
  MyAccountMobileContext,
  MyAccountMobileContextProvider,
} from './MyAccount/MyAccountMobileContext';
import { useContext } from 'react';
import { SMALL, XS } from '../../../../utils/screenClassHelper';
import { useAccountProviderData } from '@vp/my-account-pages-dropdown';

export const MobileNav = (props: MobileNavProps) => {
  const screenClass = useScreenClass();
  const { accountResources } = useAccountProviderData();
  if ([SMALL, XS].includes(screenClass)) {
    return (
      <NavContextProvider
        navigation={props.navigation}
        userTests={props.userTests}
        topLevelNavNodeTests={props.topLevelNavNodeTests}
        navigationLabel={props.navigationMenuLabel!}
        navigationMenuTitle={props.navigationMenuTitle}
        isMobileNavOpen={props.isOpen}
        auth={props.auth}
      >
        <MyAccountMobileContextProvider>
          <MobileNavLayout {...props} accountResources={accountResources} />
        </MyAccountMobileContextProvider>
      </NavContextProvider>
    );
  }
  return <></>;
};

const MobileNavLayout = (props: MobileNavProps) => {
  const { vatLocale } = React.useContext(VatContext);
  const accountContext = React.useContext(MyAccountMobileContext);
  const { state, isMobileNavOpen, isMxNav } = useNavContext();
  const { isSsr } = useIsSsrContext();
  const screenClass = useScreenClass();

  return (
    <>
      <ModalDialog
        className={classNames('site-header-nav-reduced-container', {
          'stylized-dialog-panel-pinned': vatLocale,
        })}
        variant="menu"
        isOpen={isMobileNavOpen || false}
        onRequestDismiss={() => {
          props.mobileNavToggle(false);
        }}
      >
        <ModalDialogContent
          aria-label={'mainNav'}
          className={classNames(
            'mobile-nav-menu',
            {
              'mx-nav-menu': isMxNav,
            },
            { 'mx-nav-full-width': screenClass === XS }
          )}
        >
          <ModalDialogCloseButton
            tabIndex={0}
            className={classNames({
              'swan-visually-hidden':
                !isMxNav &&
                (state.focusedCategoryIdx?.toString() || accountContext.isOpen),
            })}
            visuallyHiddenLabel={props.closeButtonLabel}
          />
          {isSsr ? (
            <TrimmedSsrNavLinks {...props} />
          ) : (
            <FullMobileNavLinks
              {...props}
              isDrilledDown={state.focusedCategoryIdx !== undefined}
              vatLocale={vatLocale}
            />
          )}
        </ModalDialogContent>
      </ModalDialog>
    </>
  );
};

const FullMobileNavLinks = (props: FullMobileNavLinksProps) => {
  const { state, navigation, unfocusNav, isMxNav } = useNavContext();

  const accountContext = useContext(MyAccountMobileContext);

  return (
    <>
      <ModalDialogHeader className={classNames('mobile-nav-header')}>
        <MobileNavHeader
          isOpen={!!state.focusedCategoryIdx?.toString()}
          text={
            state.focusedCategoryIdx?.toString()
              ? navigation[state.focusedCategoryIdx].text
              : ''
          }
          onClose={unfocusNav}
          backButtonLabel={props.backButtonLabel}
        />

        {!props.isDrilledDown && !accountContext.isOpen && (
          <UserFunctions
            accountLink={props.accountLink}
            helpLink={props.helpLink}
            accountResources={props.accountResources}
            auth={props.auth}
            myProjectsLink={props.myProjectsLink}
          />
        )}
      </ModalDialogHeader>
      <ModalDialogBody
        className={classNames({ 'mobile-nav-body': props.isDrilledDown })}
      >
        {accountContext.isOpen && (
          <MyAccountLinks
            accountResources={props.accountResources}
            auth={props.auth}
          />
        )}
        {!accountContext.isOpen && (
          <nav
            className={classNames('site-header-nav-reduced', {
              'mobile-nav-body': props.isDrilledDown,
            })}
            aria-label={props.navigationLabel}
          >
            <MobileNavItems />
          </nav>
        )}
        {!props.isDrilledDown &&
          !accountContext.isOpen &&
          props.brands &&
          props.brands.length > 1 && (
            <BrandsSection
              isDrilledDown={props.isDrilledDown}
              brandsHeading={props.brandsHeading}
              brandNavLabel={props.brandNavLabel}
              brands={props.brands}
              vatLocale={props.vatLocale}
            />
          )}
      </ModalDialogBody>
      {props.vatLocale && !accountContext.isOpen && (
        <ModalDialogFooter
          pinned
          className={classNames('mobile-nav-footer', {
            'mx-nav-footer': isMxNav,
          })}
        >
          <div className="stylized-dialog-buttons site-header-nav-vat-area">
            <VatToggle vatPlacement={'Nav'} />
          </div>
        </ModalDialogFooter>
      )}
    </>
  );
};

const TrimmedSsrNavLinks = (props: MobileNavProps) => {
  return (
    <>
      <UserFunctions
        accountLink={props.accountLink}
        helpLink={props.helpLink}
        accountResources={props.accountResources}
        auth={props.auth}
        myProjectsLink={props.myProjectsLink}
      />
      <MobileNavItems />
    </>
  );
};
