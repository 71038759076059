import React, { useState, useContext, useEffect, createContext } from 'react';

import { VatContextProps } from '../../types';
import { TrackingContext } from '../../hooks/useTracking';
import {
  getLocalStorageWithExpiry,
  setLocalStorageWithExpiry,
} from '../../utils/utilities';
import { VAT_BAR_LOCAL_STORAGE_FLAG } from '../../../constants/vatConstants';

export const VatContext = createContext<VatContextProps>({});

const SCRIPT_INITIALIZED_EVENT_V2 = 'PCXT-ModuleInitialized';
const VAT_INCLUSIVITY_CHANGE_EVENT_V2 = 'PCXT-VatInclusivityChanged';

const SCRIPT_INITIALIZED_EVENT_V3 = 'PCXT-V3-ModuleInitialized';
const VAT_INCLUSIVITY_CHANGE_EVENT_V3 = 'PCXT-V3-VatInclusivityChanged';

export const VatContextProvider: React.FC<VatContextProps> = ({
  children,
  vatLocale,
  vatToggleExclusionText,
  vatToggleInclusionText,
  vatToggleLabel,
}) => {
  const [pricingContextScriptLoaded, setPricingContextScriptLoaded] =
    useState<boolean>(false);
  const [vatInclusive, setVatInclusive] = useState<boolean | undefined>(
    undefined
  );
  const { trackVatToggleClicked } = useContext(TrackingContext);

  useEffect(() => {
    const isInitialized =
      document?.pricingContextModule?.isInitialized() ||
      document?.pcxtV3?.isInitialized();

    const setContext = () => {
      setVatInclusive(
        document.pricingContextModule?.getPricingContext().vatInclusive ||
          document.pcxtV3?.getPricingContext().vatInclusive
      );
      setPricingContextScriptLoaded(true);
    };

    if (isInitialized) {
      setContext();
    }

    // Always check for initialization events as pages may re-initialize the pricing context
    document.addEventListener(VAT_INCLUSIVITY_CHANGE_EVENT_V2, setContext);
    document.addEventListener(SCRIPT_INITIALIZED_EVENT_V2, setContext);
    document.addEventListener(VAT_INCLUSIVITY_CHANGE_EVENT_V3, setContext);
    document.addEventListener(SCRIPT_INITIALIZED_EVENT_V3, setContext);

    return () => {
      document.removeEventListener(VAT_INCLUSIVITY_CHANGE_EVENT_V2, setContext);
      document.removeEventListener(SCRIPT_INITIALIZED_EVENT_V2, setContext);
      document.removeEventListener(VAT_INCLUSIVITY_CHANGE_EVENT_V3, setContext);
      document.removeEventListener(SCRIPT_INITIALIZED_EVENT_V3, setContext);
    };
  }, []);

  const toggleVatInclusivity = () => {
    if (!getLocalStorageWithExpiry(VAT_BAR_LOCAL_STORAGE_FLAG)) {
      setLocalStorageWithExpiry(VAT_BAR_LOCAL_STORAGE_FLAG, true, 30);
    }
    setVatInclusivity(!vatInclusive);
    //add tracking
    if (!vatInclusive) {
      trackVatToggleClicked('Include VAT');
    } else {
      trackVatToggleClicked('Exclude VAT');
    }
  };

  const setVatInclusivity = (newValue: boolean) => {
    if (pricingContextScriptLoaded) {
      document.pricingContextModule?.setVatInclusive(newValue);
      document.pcxtV3?.setVatInclusive(newValue);
    } else {
      console.warn(
        'Attempted to set VAT before pricing context module was initialized.'
      );
    }
  };

  return (
    <VatContext.Provider
      value={{
        vatLocale,
        pricingContextScriptLoaded,
        vatInclusive,
        vatToggleExclusionText,
        vatToggleInclusionText,
        vatToggleLabel,
        toggleVatInclusivity,
        setVatInclusivity,
      }}
    >
      {children}
    </VatContext.Provider>
  );
};
