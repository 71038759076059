import * as React from 'react';
import { BoundedContent } from '@vp/swan';
import { VatToggle } from './VatToggle';
import { VatContext } from '../../contexts/VatContext';
import { Brands } from './Brands';
import { BrandBarProps } from '../../types';

export const BrandBar = (props: BrandBarProps) => {
  const { vatLocale } = React.useContext(VatContext);
  const hasDesktopBrands = props.brandsDesktop?.length > 1;
  const hasAnyBrands = props.brands?.length > 1 || hasDesktopBrands;
  const brandBannerHasContent = vatLocale || hasAnyBrands;

  if (!brandBannerHasContent) return null;

  const brandsToRender = hasDesktopBrands ? props.brandsDesktop : props.brands;

  return (
    <div className="site-header-brand-banner-container">
      <BoundedContent className="site-header-brand-banner-bounded-content" display={{ xs: 'none', md: 'block' }}>
        <div className="site-header-brand-banner">
          {hasAnyBrands ? (
            <nav
              className="site-header-brands"
              aria-label={props.brandNavLabel}
            >
              <ul className="site-header-brands-list">
                <Brands
                  brandsLinks={brandsToRender}
                  isDesktopBrands={hasDesktopBrands}
                />
              </ul>
            </nav>
          ) : (
            <span className="site-header-brands"></span>
          )}
          <div className="site-header-brand-banner-vat-container">
            <VatToggle vatPlacement={'BrandBanner'} />
          </div>
        </div>
      </BoundedContent>
    </div>
  );
};
