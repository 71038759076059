import * as React from 'react';
import { SearchBar } from '@vp/omnisearch';
import classNames from 'classnames';
import {
  BoundedContent,
  ScreenClassProvider,
  SkipLink,
  Visible,
} from '@vp/swan';
import { useBookendsData } from '../../hooks/useBookendsData';
import { TrackingProvider } from '../../hooks/useTracking';
import { IsSsrProvider } from '../../contexts/IsSsrContext';
import { VatContextProvider } from '../../contexts/VatContext';
import { DesktopNavigation, MobileNav } from './SiteNavigation';
import { HeaderLinks } from './HeaderLinks';
import { BrandBar } from './BrandBar';
import GeoIpBar from './GeoIpBar';
import { MasksLayout } from './MasksLayout';
import { Logos } from './Logos';
import { MobileSearchCloseButton } from './MobileSearchCloseButton';
import { buildNavigationTree } from './SiteNavigation/buildNavigationTree';
import { ErrorBoundary } from '../ErrorBoundary';
import {
  BookendsResponse,
  FullHeaderProps,
  GenericSharedProps,
  BookendsServiceCallParameters,
  FullHeaderOptionalProps,
  HeaderCommonProps,
} from '../../types';
import { ResourceProvider } from '@vp/my-account-pages-dropdown';
import LanguageBar from 'components/LanguageBar';
import VatSelectionBar from './VatSelectionBar';
import { Logger } from '../../utils/logger';
import { GoogleOneTap } from './GoogleOneTap';

const searchBarInstance = 'full-header-search-bar';

export type FullHeaderPropsForFetch = BookendsServiceCallParameters &
  FullHeaderOptionalProps;

export type Props =
  | (BookendsResponse<FullHeaderProps> & HeaderCommonProps & GenericSharedProps)
  | (FullHeaderPropsForFetch & HeaderCommonProps & GenericSharedProps);

export const FullHeader = (props: Props) => {
  const data = useBookendsData<FullHeaderProps, FullHeaderPropsForFetch>(
    props,
    {
      version: 'v3',
      headerOrFooter: 'header',
      variant: 'full',
    }
  );
  const [showVeil, setShowVeil] = React.useState(false);
  const [isMobileNavOpen, setIsMobileNavOpen] = React.useState(false);
  const [isMobileSearchContainerOpen, setIsMobileSearchContainerOpen] =
    React.useState(false);

  React.useEffect(() => {
    if (isMobileSearchContainerOpen) {
      (
        document.querySelector('input.site-header-search') as HTMLInputElement
      )?.focus();
    }
  }, [isMobileSearchContainerOpen]);

  const mobileSearchButtonClickHandler = () => {
    setIsMobileSearchContainerOpen(true);
  };

  if (!data) return <header></header>;

  const navigation = buildNavigationTree(
    data?.navigation,
    data?.topLevelNavNodes
  );

  if (data.headerLayoutName === 'microsite') {
    return <MasksLayout data={data} auth={props.auth} />;
  }

  return (
    <TrackingProvider
      trackingConfiguration={props.trackingConfiguration}
      bookendName="Header"
    >
      <ErrorBoundary
        suppressError={props.suppressError}
        component="ScreenClassProvider"
      >
        <ScreenClassProvider>
          <VatContextProvider
            vatLocale={data.vatLocale}
            vatToggleExclusionText={data.vatToggleExclusionText}
            vatToggleInclusionText={data.vatToggleInclusionText}
            vatToggleLabel={data.vatToggleLabel}
          >
            <IsSsrProvider>
              <SkipLink>{data.skipToMainContent}</SkipLink>
              <div
                className={classNames('site-header-nav-veil', {
                  'site-header-nav-veil-visible': showVeil,
                })}
                onMouseMove={() => {
                  document.dispatchEvent(new Event('veilMouseMove'));
                }}
              ></div>
              <header className="site-header site-header-full">
                <ErrorBoundary
                  suppressError={props.suppressError}
                  component="GeoIpBar"
                >
                  {(data?.locale === 'en-US' || data?.locale === 'es-US') && (
                    <GeoIpBar data={data} locale={data?.locale} />
                  )}
                </ErrorBoundary>
                <ErrorBoundary
                  suppressError={props.suppressError}
                  component="LanguageBar"
                >
                  <LanguageBar data={data} />
                </ErrorBoundary>
                <ErrorBoundary
                  suppressError={props.suppressError}
                  component="VatSelectionBar"
                >
                  <VatSelectionBar locale={data.locale} />
                </ErrorBoundary>
                <ErrorBoundary
                  suppressError={props.suppressError}
                  component="BrandBar"
                >
                  <BrandBar
                    brandNavLabel={data.brandNavLabel}
                    brands={data.brands}
                    brandsDesktop={data.brandsDesktop}
                  />
                </ErrorBoundary>
                <ErrorBoundary
                  suppressError={props.suppressError}
                  component="BoundedContent"
                >
                  <BoundedContent className="site-header-main-container">
                    <div
                      className="site-header-main full-width-container full-width-container-capped"
                      onMouseMove={() => {
                        document.dispatchEvent(new Event('headerMouseMove'));
                      }}
                    >
                      <ErrorBoundary
                        suppressError={props.suppressError}
                        component="Logos"
                      >
                        <Logos
                          navburgerLabel={data.navigationLabel}
                          homeUrl={data.homeUrl}
                          inlineLogo={data.inlineLogo}
                          inlineNavburger={data.inlineNavburger}
                          inlineSecondaryLogo={data.inlineSecondaryLogo}
                          locale={data.locale}
                          logoDescription={data.logoDescription}
                          searchLabel={data.searchLabel}
                          mobileNavToggle={setIsMobileNavOpen}
                          showMobileSearch={data.showSearch}
                          openMobileSearch={mobileSearchButtonClickHandler}
                        />
                      </ErrorBoundary>
                      {data.showSearch && (
                        <>
                          <Visible xs>
                            <div
                              className={classNames('mobile-search-veil', {
                                'mobile-search-veil-open':
                                  isMobileSearchContainerOpen,
                              })}
                              onClick={() =>
                                setIsMobileSearchContainerOpen(false)
                              }
                            />
                          </Visible>
                          <ErrorBoundary
                            suppressError={props.suppressError}
                            component={'Header Search Bar'}
                          >
                            <div
                              className={classNames(
                                'site-header-search-container',
                                {
                                  'site-header-search-container-open':
                                    isMobileSearchContainerOpen,
                                }
                              )}
                            >
                              <MobileSearchCloseButton
                                label={data.modalDialogCloseLabel}
                                clickHandler={() =>
                                  setIsMobileSearchContainerOpen(false)
                                }
                              />
                              {props.searchBarComponent || (
                                <SearchBar
                                  auth={props.auth}
                                  locale={data.locale}
                                  searchLabel={data.searchLabel}
                                  instanceId={searchBarInstance}
                                  flyoutEnabled={
                                    data.searchTypeaheadData?.enabled
                                  }
                                  logger={Logger.instance}
                                />
                              )}
                            </div>
                          </ErrorBoundary>
                        </>
                      )}
                      <ResourceProvider
                        locale={data.locale}
                        tenant={data.authTenant}
                      >
                        <GoogleOneTap auth={props.auth} />
                        <HeaderLinks
                          homeUrl={data.homeUrl}
                          accountLink={data.accountLink}
                          carePhoneNumber={data.carePhoneNumber}
                          cartLink={data.cartLink}
                          headerLinksLabel={data.headerLinksLabel}
                          helpLink={data.helpLink}
                          siteHeaderHelpLinkA11yText={
                            data.siteHeaderHelpLinkA11yText
                          }
                          auth={props.auth}
                          cartIconComponent={props.cartIconComponent}
                          myProjectsLink={data.myProjectsLink}
                          locale={data.locale}
                          tenant={data.authTenant}
                          suppressError={props.suppressError}
                        />
                      </ResourceProvider>
                    </div>
                    <DesktopNavigation
                      navigation={navigation}
                      topLevelNavNodeTests={data.topLevelNavNodeTests}
                      userTests={data.abTestData}
                      navigationLabel={data.navigationLabel}
                      navigationMenuTitle={data.navigationMenuTitle}
                      auth={props.auth}
                      locale={data.locale}
                      setShowVeil={setShowVeil}
                    />
                    <ResourceProvider
                      locale={data.locale}
                      tenant={data.authTenant}
                    >
                      <MobileNav
                        accountLink={data.accountLink}
                        brandNavLabel={data.brandNavLabel}
                        brands={data.brands}
                        brandsHeading={data.brandsHeading}
                        helpLink={data.helpLink}
                        logo={data.logo}
                        navigation={navigation}
                        topLevelNavNodeTests={data.topLevelNavNodeTests}
                        userTests={data.abTestData}
                        navigationLabel={data.navigationLabel}
                        navigationMenuLabel={data.navigationMenuLabel}
                        navigationMenuTitle={data.navigationMenuTitle}
                        auth={props.auth}
                        isOpen={isMobileNavOpen}
                        mobileNavToggle={setIsMobileNavOpen}
                        closeButtonLabel={data.modalDialogCloseLabel}
                        backButtonLabel={data.modalDialogBackButtonLabel}
                        myProjectsLink={data.myProjectsLink}
                      />
                    </ResourceProvider>
                  </BoundedContent>
                </ErrorBoundary>
              </header>
            </IsSsrProvider>
          </VatContextProvider>
        </ScreenClassProvider>
      </ErrorBoundary>
    </TrackingProvider>
  );
};
