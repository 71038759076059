import { NavItem } from '../../../types';

export function buildNavigationTree(
  flatNavigation: any,
  topLevelNodes: string[] = []
): NavItem[] {
  let results: NavItem[] = [];
  topLevelNodes.forEach((nodeId: string) => {
    let childNode = flatNavigation[nodeId];
    if (childNode) {
      const childNodeCopy = { ...childNode };
      if (childNode.children && childNode.children.length > 0) {
        const childNodes = buildNavigationTree(
          flatNavigation,
          childNode.children
        );
        childNodeCopy.children = childNodes;
      }
      results.push(childNodeCopy);
    }
  });
  results.sort(function(a: NavItem, b: NavItem) {
    return a.order - b.order;
  });
  return results;
}
