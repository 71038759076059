import * as React from 'react';
import { VatContext } from '../../contexts/VatContext';
import {
  ToggleSwitch,
  ToggleSwitchOffLabel,
  ToggleSwitchOnLabel,
} from '@vp/swan';
import { VatToggleProps } from '../../types';

export const VatToggle = (props: VatToggleProps) => {
  const {
    vatLocale,
    toggleVatInclusivity,
    vatInclusive,
    vatToggleInclusionText,
    vatToggleExclusionText,
    vatToggleLabel,
  } = React.useContext(VatContext);

  return vatLocale ? (
      // is data-show-vat-pop used for anything here?
    <div className="site-header-vat-toggle" data-show-vat-pop={false}>
      {vatToggleLabel &&
        <span
          id={`vatToggleLabel${props.vatPlacement}`}
          className="site-header-vat-toggle-label"
          aria-hidden="true"
        >
          {vatToggleLabel}
        </span>
      }
      <ToggleSwitch
        id={`vatToggle${props.vatPlacement}`}
        className="vat-toggle"
        activated={!!vatInclusive}
        onRequestActivatedChange={toggleVatInclusivity}
        aria-labelledby={vatToggleLabel ? `vatToggleLabel${props.vatPlacement}` : undefined}
      >
        <ToggleSwitchOnLabel>{vatToggleInclusionText}</ToggleSwitchOnLabel>
        <ToggleSwitchOffLabel>{vatToggleExclusionText}</ToggleSwitchOffLabel>
      </ToggleSwitch>
    </div>
  ) : null;
};
