import * as React from 'react';
import classNames from 'classnames';
import { useNavContext } from '../NavContext';
import { useHoverIntent } from '../../../../hooks/useHoverIntent';
import TrackableLink from '../../../TrackableLink';
import { BookendsBadge } from '../../../BookendsBadge';
import { NavItemLeafType } from '../../../../types';
import { TOP_NAV_LEVEL } from '../../../../../constants/analytics';
import { HOLIDAY_RED_COLOR_CODE } from '../../../../../constants/styleClasses';
import { isThemeHoliday } from '../../../../utils/utilities';

export const NavItemLeaf = (
  props: NavItemLeafType & React.ComponentProps<'li'>
) => {
  const {
    navigation,
    hoverNavItem,
    keypress,
    addItemRef,
    unfocusNav,
    isMxNav,
  } = useNavContext();
  const navItemRef = useHoverIntent<HTMLLIElement>({
    hoverIn: () => hoverNavItem(props.index, navigation),
  });
  addItemRef(props.index, navItemRef);

  return (
    <li
      ref={navItemRef}
      onFocus={() => hoverNavItem(props.index, navigation)}
      className={classNames(
        'site-header-nav-item',
        'site-header-nav-toplevel',
        'site-header-nav-toplevel-empty',
        'site-header-nav-toplevel-js',
        props.className
      )}
      data-item-title={props.text}
      onKeyDown={(event: React.KeyboardEvent<HTMLLIElement>) => {
        event.persist();
        event.preventDefault();
        keypress(props.index, event, navigation);
      }}
      onBlur={(event) => {
        if (
          event.relatedTarget?.classList === undefined ||
          event.relatedTarget?.classList.contains('search-bar-input')
        ) {
          unfocusNav(props.id, true);
        }
      }}
      tabIndex={0}
    >
      <TrackableLink
        subSection="TopNav"
        navigationDetailPath={props.analyticsId ? [props.analyticsId] : []}
        className={classNames(`site-header-nav-link`, props.theme)}
        style={
          isThemeHoliday(props.theme) ? { color: HOLIDAY_RED_COLOR_CODE } : {}
        }
        href={props.url}
        dataPosition={props.dataPosition}
        navigationSection={isMxNav ? TOP_NAV_LEVEL : undefined}
        onKeyDown={(event: React.KeyboardEvent) => {
          if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            window.location.href = props.url;
          }
        }}
      >
        {props.text}
        {props.badge && <BookendsBadge {...props.badge} layout="visual" />}
      </TrackableLink>
    </li>
  );
};
