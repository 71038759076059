export const VAT_BAR_ENABLED_LOCALES = ['fr-FR'];
export const HAS_SEEN_VAT_BAR_FLAG = 'hasSeenVatSelectionBar';
export const VAT_BAR_LOCAL_STORAGE_FLAG_TTL_IN_DAYS = 30;
export const VAT_SELECTION_BAR_DATA = {
  title:
    'Nos prix sont affichés en HT (TVA de 20 % non incluse). Vous êtes un particulier ?',
  mobilePromptText: 'Vous pouvez modifier cette option dans le menu.',
  vatIncText: 'Oui, passer en TTC',
  vatExcText: 'Non, rester en HT',
};
export const VAT_BAR_LOCAL_STORAGE_FLAG = 'hideVatSelectionBar';
