import { Typography, Button, Divider, FlexBox, Box } from '@vp/swan';
import TrackableLink from '../../TrackableLink';
import React from 'react';

import { SignInFlyOutData, Translations } from 'types';
import TrackElement from './TrackElement';

interface FlyoutContentProps {
  translation: Translations;
  signIn: Function;
  signUp: Function;
  signInFlyOutData: SignInFlyOutData;
  auth: any;
}

function isSignUpEnabled(props: any) {
  try {
    if (!props?.translation?.messages?.signInFlyOutSignUp) return false;
    if (!props?.auth?.version) return false;

    const versions = props.auth.version.split('.');
    const major = parseInt(versions[0]);
    const minor = parseInt(versions[1]);
    const patch = parseInt(versions[2]);

    if (major < 6) {
      return false;
    }

    if (minor < 1) {
      return false;
    }

    if (patch < 100) {
      return false;
    }
  } catch (e) {
    return false;
  }

  return true;
}

export const SignInFlyoutContent = (props: FlyoutContentProps) => {
  return (
    (<Box margin={7}>
      <Typography>{props?.translation?.messages?.signInFlyOutTitle}</Typography>
      <TrackElement
        subSection="MyAccount"
        navigationDetailPath={['MyAccount', 'Sign In']}
      >
        <Button
          mt={"7"}
          skin="primary"
          width="full-width"
          onClick={() => {
            props?.signIn()
          }}
        >
          {props?.translation?.messages?.signInFlyOutSignIn}
        </Button>
      </TrackElement>
      <TrackElement
        subSection="MyAccount"
        navigationDetailPath={['MyAccount', 'Create an Account']}
      >
        {isSignUpEnabled(props.auth) && (
          <Button
            mt={"7"}
            mb={"7"}
            width="full-width"
            onClick={() => {
              props?.signUp();
            }}
          >
            {props?.translation?.messages?.signInFlyOutSignUp}
          </Button>
        )}
      </TrackElement>
      <Divider my="7"></Divider>
      <FlexBox justifyContent="center">
        <TrackableLink
          className="site-header-nav-link"
          subSection="MyAccount"
          navigationDetailPath={['MyAccount', 'Find and Track an Order']}
          href={props?.signInFlyOutData?.urls?.trackOrder}
        >
          <FlexBox flexDirection="row" alignItems="center">
            <FlexBox alignItems="center">
              <img
                alt=''
                src={`data:image/svg+xml;utf8,${encodeURIComponent(
                  props?.signInFlyOutData?.icons?.trackOrder
                )}`}
              />
            </FlexBox>

            <Typography fontSize={"small"} ml="7" fontWeight="bold">
              {props?.translation?.messages?.signInFlyOutTrackOrder}
            </Typography>
          </FlexBox>
        </TrackableLink>
      </FlexBox>
    </Box>)
  );
};
