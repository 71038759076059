import { TrackingContext } from 'hooks/useTracking';
import React, { useContext } from 'react';
import { TrackableLinkProps } from 'types';

const TrackElement = (props: TrackableLinkProps) => {
  const { trackNavigation, labelPrefix } = useContext(TrackingContext);
  // should not be state - needs to be updated and new value read before a state update would be recognized
  // and after the value is changed, and after it's changed, we'll be leaving the page before any re-render

  const {
    subSection,
    navigationDetailPath,
    dataPosition,
    children
  } = props;

  let tracked = false;


  const eventLabel = `${labelPrefix}${subSection ? ':' + subSection : ''}`;
  const navigationDetail = navigationDetailPath.join('|');

  const track = (currentHost: string) => {
    const destinationPath = props.href
      ? (!props.href.includes(currentHost) //some links have different domains so just the path is insufficient
          ? new URL(props.href).host
          : '') + new URL(props.href).pathname //all bookends links are absolute urls
      : '';
    if (!tracked) {
      trackNavigation(navigationDetail, destinationPath, subSection);
      tracked = true;
    }
  };

  return (
    <div
      onClick={() => track(window.location.hostname)}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          track(window.location.hostname);
        }
      }}
      data-position={dataPosition || '0'}
      data-section={eventLabel}
      data-translation={navigationDetail}
    >
      {children}
    </div>
  );
};

export default TrackElement;
