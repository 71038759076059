import React, { useContext } from 'react';
import { TrackingContext } from '../hooks/useTracking';
import { TrackableLinkProps } from '../types';

const TrackableLink = React.forwardRef((props: TrackableLinkProps, ref) => {
  const { trackNavigation, labelPrefix } = useContext(TrackingContext);
  // should not be state - needs to be updated and new value read before a state update would be recognized
  // and after the value is changed, and after it's changed, we'll be leaving the page before any re-render

  const {
    subSection,
    navigationDetailPath,
    dataPosition,
    onClick,
    onKeyDown,
    children,
    linkType,
    useButton,
    href,
    navigationSection,
    ...otherProps
  } = props;

  let tracked = false;

  const eventLabel = `${labelPrefix}${subSection ? ':' + subSection : ''}`;
  const navigationDetail = navigationDetailPath.join('|');

  const track = (currentHost: string) => {
    const destinationPath = href
      ? (!href.includes(currentHost) //some links have different domains so just the path is insufficient
          ? new URL(href).host
          : '') + new URL(href).pathname //all bookends links are absolute urls
      : '';
    if (!tracked) {
      trackNavigation(
        navigationDetail,
        destinationPath,
        subSection,
        linkType,
        navigationSection
      );
      tracked = true;
    }
  };

  const newProps = {
    ...(otherProps as any),
    ref,
    onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      onClick?.(event);
      track(window.location.hostname);
      if (useButton && href) {
        window.location.assign(href);
      }
    },
    onKeyDown: (event: React.KeyboardEvent<HTMLAnchorElement>) => {
      props.onKeyDown?.(event);
      if (event.key === 'Enter' || event.key === 'Space') {
        track(window.location.hostname);
        if (useButton && href) {
          window.location.assign(href);
        }
      }
    },
    'data-position': dataPosition || '0',
    'data-section': eventLabel,
    'data-translation': navigationDetail,
  };

  return useButton ? (
    <button {...newProps}>{children}</button>
  ) : (
    <a {...newProps} href={href}>
      {children}
    </a>
  );
});

export default TrackableLink;
