import * as React from 'react';
import { Callout } from '@vp/swan';
import { BookendsBadgeProps } from '../types';

export const BookendsBadge = (props: BookendsBadgeProps) => {
  if (!props) {
    return null;
  }
  switch (props.treatment) {
    case 'new':
      switch (props.layout) {
        case 'visual':
          return (
            <Callout
              variant="inverse"
              skin="accent"
              textAllCaps={props.allCaps}
            >
              {props.text + ` `}
            </Callout>
          );
        case 'textOnly':
        default:
          return (
            <Callout variant="standard" skin="info" textAllCaps={props.allCaps}>
              {props.text + ` `}
            </Callout>
          );
      }
    default:
      return null;
  }
};
