import * as React from 'react';
import { LinkTypes, TrackingProviderProp } from '../types';
import {
  CATEGORY_SECTION,
  MERCHANDISING_SECTION,
  TOP_NAV_LEVEL,
} from '../../constants/analytics';
declare global {
  interface Window {
    tracking: any;
  }
}

const TrackingContext = React.createContext({
  trackNavigation: (
    _navigationDetail: string,
    _destinationPath: string,
    _subSection?: string,
    _linkType?: LinkTypes,
    _navigationSection?:
      | typeof CATEGORY_SECTION
      | typeof MERCHANDISING_SECTION
      | typeof TOP_NAV_LEVEL
  ) => {},
  trackNavigationHover: (_analyticsId: string, _locale: string) => {},
  trackHeaderLinkClicked: (
    _navigationDetail: string,
    _subSection?: string,
    _navigationSection?:
      | typeof CATEGORY_SECTION
      | typeof MERCHANDISING_SECTION
      | typeof TOP_NAV_LEVEL
  ) => {},
  trackPopInteraction: (
    _action: string,
    _label: string,
    _eventDetail?: string
  ) => {},
  trackFlyOutViewed: (
    _label: string,
    _eventDetail?: string,
    _locale?: string
  ) => {},
  trackGoogleOneTap: (
    _eventName: string,
    _category: string,
    _label: string
  ) => {},
  trackFlyOutClicked: (
    _label: string,
    _eventDetail?: string,
    _locale?: string
  ) => {},
  trackVatToggleClicked: (_label: string) => {},
  labelPrefix: '',
  pageSection: '',
  pageName: '',
  pageStage: '',
});

const TrackingProvider = (
  props: React.PropsWithChildren<TrackingProviderProp>
) => {
  const baseTrackingData = {
    ...props.trackingConfiguration,
    category: props.trackingConfiguration.pageSection,
  };

  const trackNavigation = (
    navigationDetail: string,
    destinationPath: string,
    subSection?: string,
    linkType?: LinkTypes,
    navigationSection?:
      | typeof CATEGORY_SECTION
      | typeof MERCHANDISING_SECTION
      | typeof TOP_NAV_LEVEL
  ) => {
    const eventLabel = `${props.bookendName}${
      subSection ? ':' + subSection : ''
    }`;
    const currentPath = window.location.pathname;
    if (window.tracking?.track) {
      window.tracking.track('Navigation Clicked', {
        label: eventLabel,
        eventDetail: `${currentPath};${destinationPath};${
          subSection || props.bookendName
        };${navigationDetail};${linkType ? linkType : ''}`,
        navigationDetail,
        navigationSection: navigationSection,
        ...baseTrackingData,
      });
    }
  };

  const trackNavigationHover = (analyticsId: string, locale: string) => {
    // currently only tracking for de-de locale
    if (locale.toLowerCase() === 'de-de' && window.tracking?.track) {
      window.tracking.track('Experiment Viewed', {
        experimentName: 'nav_flyout_event',
        analyticsId: analyticsId,
      });
    }
  };

  const trackHeaderLinkClicked = (
    navigationDetail: string,
    subSection?: string,
    navigationSection?:
      | typeof CATEGORY_SECTION
      | typeof MERCHANDISING_SECTION
      | typeof TOP_NAV_LEVEL
  ) => {
    const eventLabel = `${props.bookendName}${
      subSection ? ':' + subSection : ''
    }`;

    if (window.tracking?.track) {
      window.tracking.track('Header Link Clicked', {
        ...baseTrackingData,
        category: 'Header',
        label: eventLabel,
        eventDetail: navigationDetail,
        navigationSection: navigationSection,
      });
    }
  };

  const trackPopInteraction = (
    action: string,
    label: string,
    eventDetail?: string
  ) => {
    if (window.tracking?.track) {
      window.tracking.track(action, {
        ...baseTrackingData,
        category: 'Pop-Up',
        label,
        eventDetail,
      });
    }
  };

  const trackFlyOutViewed = (
    label: string,
    eventDetail?: string,
    locale?: string
  ) => {
    if (window.tracking?.track) {
      window.tracking.track('Fly-Out Viewed', {
        pageSection: baseTrackingData.pageSection,
        pageName: baseTrackingData.pageName,
        pageStage: baseTrackingData.pageStage,
        category: 'Fly-Out',
        label,
        eventDetail: `${eventDetail}_${locale}`, // language instead of locale in case if language bar
      });
    }
  };

  const trackGoogleOneTap = (
    eventName: string,
    category: string,
    label: string
  ) => {
    if (window.tracking?.track) {
      window.tracking.track(eventName, {
        pageSection: baseTrackingData.pageSection,
        pageName: baseTrackingData.pageName,
        pageStage: baseTrackingData.pageStage,
        category,
        label,
      });
    }
  };

  const trackFlyOutClicked = (
    label: string,
    eventDetail?: string,
    locale?: string
  ) => {
    const eventDetailValue = eventDetail
      ? locale
        ? `${eventDetail}_${locale}` // language instead of locale in case if language bar
        : eventDetail
      : '';

    if (window.tracking?.track) {
      window.tracking.track('Fly-Out Clicked', {
        ...baseTrackingData,
        category: 'Fly-Out',
        label,
        eventDetail: eventDetailValue,
      });
    }
  };

  const trackVatToggleClicked = (label: string) => {
    if (window.tracking?.track) {
      window.tracking.track('VAT Option Selected', {
        pageSection: baseTrackingData.pageSection,
        pageName: baseTrackingData.pageName,
        pageStage: baseTrackingData.pageStage,
        category: 'VAT',
        // eventDetail: 'VAT Option Selected',
        label,
      });
    }
  };

  return (
    <TrackingContext.Provider
      value={{
        trackNavigation,
        trackNavigationHover,
        trackHeaderLinkClicked,
        trackPopInteraction,
        trackFlyOutViewed,
        trackGoogleOneTap,
        trackFlyOutClicked,
        trackVatToggleClicked,
        labelPrefix: props.bookendName,
        pageSection: props.trackingConfiguration.pageSection,
        pageName: props.trackingConfiguration.pageName,
        pageStage: props.trackingConfiguration.pageStage,
      }}
    >
      {props.children}
    </TrackingContext.Provider>
  );
};

export { TrackingContext, TrackingProvider };
