import React from 'react';
import classNames from 'classnames';

export const FooterDivider = ({
  className,
  ...rest
}: React.ComponentProps<'div'>) => {
  return (
    <div className={classNames('site-footer-divider', { className })} {...rest}>
      <hr className="hr-skin-strong" />
    </div>
  );
};
