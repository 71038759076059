import * as React from 'react';
import classNames from 'classnames';
import { H3 } from '@vp/swan';
import TrackableLink from '../../../TrackableLink';
import { BookendsBadge } from '../../../BookendsBadge';
import { NavItemLeafType } from '../../../../types';
import { useNavContext } from '../NavContext';
import { TOP_NAV_LEVEL } from '../../../../../constants/analytics';
import { HOLIDAY_RED_COLOR_CODE } from '../../../../../constants/styleClasses';
import { isThemeHoliday } from '../../../../utils/utilities';

export const TopLevelLeaf = (
  props: NavItemLeafType & React.ComponentProps<'li'>
) => {
  const { hoverNavItem, navigation, isMxNav } = useNavContext();
  return (
    <li
      className={classNames(
        'site-header-nav-item',
        'site-header-nav-toplevel',
        'site-header-nav-toplevel-empty',
        'site-header-nav-toplevel-js',
        props.className
      )}
      data-item-title={props.text}
    >
      <H3
        fontWeight="bold"
        fontSize={'standard'}
        className={classNames(`site-header-nav-link`, props.theme)}
        onClick={() => {
          hoverNavItem(props.index, navigation, true);
        }}
        style={isThemeHoliday(props.theme) ? { color: HOLIDAY_RED_COLOR_CODE } : {}}
            >
        <TrackableLink
          subSection="TopNav"
          navigationDetailPath={props.analyticsId ? [props.analyticsId] : []}
          href={props.url}
          dataPosition={props.dataPosition}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              hoverNavItem(props.index, navigation, true);
            }
          }}
          navigationSection={isMxNav ? TOP_NAV_LEVEL : undefined}
        >
          <span>
            {props.text}
            {props.badge && <BookendsBadge {...props.badge} layout="visual" />}
          </span>
        </TrackableLink>
      </H3>
    </li>
  );
};
