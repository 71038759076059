import React from 'react';
import { Box, Button, FlexBox, Icon, useScreenClass, tokens } from '@vp/swan';
import { CountrySelector } from '../FullFooter/CountrySelector';
import { DeepLinkContextProvider } from '../FullFooter/Hooks/useDeepLinkCountryLinks';

const InfoTopBar = (props: any) => {
  const {
    id,
    title,
    buttons,
    handleCloseClick,
    data,
    containerRef,
    countrySelector,
    componentSource,
    currentLocale,
    style,
    isVatSelectionBar,
  } = props;

  const screenClass = useScreenClass();

  return (
    <>
      <DeepLinkContextProvider tenants={data as any}>
        <FlexBox
          id={id || 'geo-container'}
          ref={containerRef}
          justifyContent={'center'}
          alignItems={'center'}
          fontSize={'small'}
          gap={'4'}
          p={'3'}
          textColor="standard"
          style={{
            transition: 'top 1s ease-out',
            overflow: 'hidden',
            top: '-60px',
            backgroundColor: tokens.SwanSemColorBgStrong,
            position: 'absolute',
            ...style,
          }}
          className="swan-dark-mode"
          flexDirection={screenClass === 'xs' ? 'column' : 'row'}
        >
          <FlexBox
            gap={'4'}
            justifyContent={screenClass === 'xs' ? 'space-between' : 'center'}
            style={{
              width: screenClass === 'xs' ? '100%' : undefined,
            }}
          >
            <Box
              style={{
                whiteSpace: 'pre-line',
              }}
            >
              {title}
            </Box>
            {!isVatSelectionBar && (
              <Button
                onClick={handleCloseClick}
                swanStyle={{
                  xs: {
                    display: 'block',
                  },
                  sm: {
                    display: 'none',
                  },
                }}
                style={{
                  border: 'none',
                }}
                skin="unstyled"
              >
                <Icon iconType="close" size="16p" />
              </Button>
            )}
          </FlexBox>
          {buttons && (
            <FlexBox
              justifyContent={'space-between'}
              gap={isVatSelectionBar && screenClass === 'xs' ? '3' : '4'}
              alignItems={'center'}
              style={{
                width: screenClass === 'xs' ? '100%' : undefined,
              }}
              flexDirection={
                isVatSelectionBar && screenClass === 'xs' ? 'column' : 'row'
              }
            >
              {buttons}
            </FlexBox>
          )}

          {!isVatSelectionBar && (
            <Button
              onClick={handleCloseClick}
              swanStyle={{
                xs: {
                  display: 'none',
                },
                sm: {
                  display: 'block',
                },
              }}
              style={{
                border: 'none',
              }}
              skin="unstyled"
              id="geo-cross-desktop"
            >
              <Icon iconType="close" size="16p" />
            </Button>
          )}
        </FlexBox>
        {countrySelector && (
          <CountrySelector
            {...countrySelector}
            componentSource={componentSource}
            isCookie={true}
            currentLocale={currentLocale}
          />
        )}
      </DeepLinkContextProvider>
    </>
  );
};

export default InfoTopBar;
