import { UrlServiceResponseData } from '../../../types';
import { doRequest } from '../../../utils/http';

/**
 * Gets the latest urls for the given urlId in all locales.
 * @param tenant the tenant to retrieve data for.
 * @param urlId the Url Id of the current page.
 */
export const getCountryLink = async (
  tenant: string,
  urlId: string
): Promise<UrlServiceResponseData> => {
  if (!tenant) return Promise.reject(`tenant was not defined`);
  if (!urlId) return Promise.reject(`urlId parameter was not defined`);
  return doRequest(
    `https://url.prod.merch.vpsvc.com/v3/hreflang/${tenant}/${urlId}?requestor=bookendsCountrySelectorV2`
  ).then((res) => res);
};
