import React from 'react';
import { useStyles, useSwanStyleKeys, useIdentity } from '@vp/ubik-context';
import { swanStyleKeys } from '../common/utils/swanStyleKeys';

import { FullHeader as BaseFullHeader } from '../common/components/FullHeader';
import { stylesheet } from '../common/components/FullHeader/FullHeader.scss';
import { stylesheet as accountFlyoutStylesheet } from '../common/components/FullHeader/Account/Flyout.scss';

import { stylesheet as navStylesheet } from '../common/components/FullHeader/SiteNavigation/DesktopNav/navigation-style.scss';
import { stylesheet as visualNavStylesheet } from '../common/components/FullHeader/SiteNavigation/DesktopNav/visual-navigation-style.scss';

import { stylesheet as mobileNavStylesheet } from '../common/components/FullHeader/SiteNavigation/MobileNav/mobile-nav-styles.scss';

export const FullHeader = (props: any) => {
  const { auth } = useIdentity();

  useSwanStyleKeys(swanStyleKeys);
  useStyles(
    stylesheet,
    accountFlyoutStylesheet,
    navStylesheet,
    visualNavStylesheet,
    mobileNavStylesheet
  );

  return <BaseFullHeader {...props} auth={auth} />;
};
