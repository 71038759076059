import { getLiveSite } from '../ServiceClients/LiveSiteClient';
import { LiveSiteLocale, LiveSiteMap, LiveSiteResponse } from '../../../types';

let _liveSiteMap: LiveSiteMap[] | undefined;

/**
 * Remaps the live site data for one tenant to the have keys canonically based.
 * @param liveSiteTenant tenant to match against the live site tenants in the format of `en-IE`.
 */
export function getLiveSiteMap(tenant: string): Promise<LiveSiteMap[]> {
  if (_liveSiteMap) return Promise.resolve(_liveSiteMap);

  return getLiveSite().then((response: LiveSiteResponse) => {
    // @ts-ignore
    _liveSiteMap = Object.entries<LiveSiteLocale>(response.tenant[tenant].sites)
      .filter(([, site]) => {
        return !site.canonicalDomain.startsWith(`next.`);
      })
      .map(([, site]: any) => {
        return {
          locale: site.primaryLanguage,
          value: site,
        };
      });

    return _liveSiteMap;
  });
}
