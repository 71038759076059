import React, { useEffect, useState } from 'react';
import { Visible } from '@vp/swan';
import { AccountFlyout } from './AccountFlyout';
import TrackableLink from '../../TrackableLink';
import { Image } from '../../Image';
import { AccountPropsForFetch, SimpleLink } from '../../../types';
import { useAccountProviderData } from '@vp/my-account-pages-dropdown';

export const Account = ({ accountLink, auth }: AccountPropsForFetch) => {
  const siteHeaderAccountMFEContainerId = 'siteHeaderAccountMFEContainer';
  const siteHeaderAccountClassNames =
    'site-header-link-account site-header-link-menu swan-hidden-xs';

  const { accountResources } = useAccountProviderData();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (mounted && accountResources) {
    return (
      <AccountFlyout
        resources={accountResources}
        accountLink={accountLink}
        auth={auth}
        siteHeaderAccountMFEContainerId={siteHeaderAccountMFEContainerId}
        siteHeaderAccountClassNames={siteHeaderAccountClassNames}
      />
    );
  }

  return (
    <div
      id={siteHeaderAccountMFEContainerId}
      className={siteHeaderAccountClassNames}
    >
      <DefaultAccountLink accountLink={accountLink} />
    </div>
  );
};

interface DefaultAccountLinkProps {
  accountLink: SimpleLink;
}

const DefaultAccountLink: React.FC<DefaultAccountLinkProps> = ({
  accountLink,
}) => {
  return (
    <TrackableLink
      navigationDetailPath={[accountLink.analyticsId || 'account']}
      className="site-header-link site-header-link-content site-header-link-menu-top-item"
      href={accountLink.url}
      aria-label={accountLink.text}
    >
      {accountLink.image && (
        <Image className="site-header-link-icon" image={accountLink.image} ariaHidden={true}/>
      )}
      <Visible lg xl>
        <span className="site-header-link-text site-header-link-text-signin swan-text-1">
          {accountLink.text}
        </span>
      </Visible>
    </TrackableLink>
  );
};
