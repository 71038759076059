import * as React from 'react';
import { Image } from './Image';
import { Box, FlexBox, Li } from '@vp/swan';

export const PaymentIcons = (props: any) => {
  const paymentTypes = props.paymentTypes;
  if (!paymentTypes) {
    return null;
  }
  return (
    <Box className="site-footer-payment-icons">
      <FlexBox
        as="ul"
        role="list"
        // role="list" is to account for Safari suppressing lists with the bullets removed
        // (see https://www.scottohara.me/blog/2019/01/12/lists-and-safari.html)
        className="site-footer-payment-icons-container"
        display="flex"
        alignItems="flex-start"
      >
        {paymentTypes.map((paymentType: any) => (
          <Li className="site-footer-payment-icon" key={paymentType.text}>
            <Image
              alt={paymentType.text}
              image={{ url: paymentType.url }}
              lazy
            />
          </Li>
        ))}
      </FlexBox>
    </Box>
  );
};
