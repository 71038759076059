import * as React from 'react';
import classNames from 'classnames';
import { Accordion, Divider } from '@vp/swan';
import { isEmptyNavItem } from '../isEmptyNavItem';
import { useNavContext } from '../NavContext';
import { SeeAllLink } from './SeeAllLink';
import { SubCategoryWithChildren } from './SubCategoryWithChildren';
import { ThirdLevelTileNavItem } from './ThirdLevelTileNavItem';
import { PromoTile } from './PromoTile';
import {
  LinkList,
  MobileNavFlyoutProps,
  NavItem,
  NavItemLeafType,
  NavItemWithChildrenType,
} from '../../../../types';
import { TrackingContext } from '../../../../hooks/useTracking';
import {
  CATEGORY_SECTION,
  MERCHANDISING_SECTION,
  TOP_NAV,
  TOP_NAV_LEVEL,
} from '../../../../../constants/analytics';

const getNavigationSection = (
  currentIndex: number,
  indexOfStartOfMx: number
) => {
  if (indexOfStartOfMx === -1) {
    return CATEGORY_SECTION;
  }
  return currentIndex < indexOfStartOfMx
    ? CATEGORY_SECTION
    : MERCHANDISING_SECTION;
};

export const SubcategoryList = (
  props: MobileNavFlyoutProps &
    LinkList<NavItem | NavItem> &
    React.ComponentProps<'section'>
) => {
  const { state, hoverSubNavItem, isMxNav } = useNavContext();
  const { trackHeaderLinkClicked } = React.useContext(TrackingContext);
  let promoTile: any = null;

  if (!props.children) {
    return null;
  }
  const isCurrentCategory = state.focusedCategoryIdx === props.categoryId;
  let promoTileRendered = false;
  let listContainsPromoTile = props.children.some((item) => item.isVisual);
  let isTileList =
    props.isVisualNav && props.children.every((item) => isEmptyNavItem(item));

  const indexOfStartOfMx = props.children.findIndex((node) => node.startOfMx);

  return (
    <section
      id={props.parentId + '-accordion'}
      className={classNames('site-header-nav-flyout', props.className)}
      aria-labelledby={props.parentId + '-mob'}
    >
      <div
        className={classNames(
          'full-width-container-capped',
          'full-width-container',
          'site-header-nav-flyout-categories-wrapper',
          'site-header-nav-flyout-content'
        )}
      >
        <Accordion
          component="ul"
          visuallyHidden={!isCurrentCategory}
          onRequestExpandedChange={(collapsibleId, expanded) => {
            if (expanded) {
              hoverSubNavItem(parseInt(collapsibleId));
              const l2analyticsId =
                props.children[parseInt(collapsibleId)].analyticsId;
              const l2navigationDetails =
                props.parentNavigationDetailPath.concat(l2analyticsId || []);
              isMxNav
                ? trackHeaderLinkClicked(
                    l2navigationDetails.join('|'),
                    TOP_NAV,
                    getNavigationSection(
                      parseInt(collapsibleId),
                      indexOfStartOfMx
                    )
                  )
                : trackHeaderLinkClicked(
                    l2navigationDetails.join('|'),
                    TOP_NAV
                  );
            }
          }}
          className={classNames(
            'site-header-nav-flyout-categories',
            'site-header-nav-menu-items',
            'site-header-nav-menu-items-second-level',
            'full-width-container',
            {
              'site-header-nav-menu-items-menu-item-focused': isCurrentCategory,
            },
            {
              'has-promo-tile': listContainsPromoTile,
            },
            {
              'site-header-nav-mobile-tile-list': isTileList,
            }
          )}
        >
          {!isTileList && props.seeAllUrl && props.seeAllText && isMxNav && (
            <SeeAllLink
              className={classNames({
                'site-header-nav-flyout-focused': isCurrentCategory,
              })}
              categoryLink={
                {
                  text: props.seeAllText,
                  url: props.seeAllUrl,
                  analyticsId: props.analyticsId,
                  theme: '',
                } as NavItemLeafType
              }
              parentNavigationDetailPath={props.parentNavigationDetailPath}
              analyticsId={props.analyticsId}
              dataPosition={props.children.length}
              navigationSection={isMxNav ? TOP_NAV_LEVEL : undefined}
            />
          )}

          {props.children.map((subCategoryLink: NavItem, index: number) => {
            if (
              subCategoryLink.isVisual &&
              subCategoryLink.image?.url &&
              subCategoryLink.url &&
              !promoTileRendered
            ) {
              promoTileRendered = true;
              promoTile = (
                <PromoTile
                  key={subCategoryLink.id}
                  navItem={subCategoryLink}
                  navigationSection={
                    isMxNav
                      ? getNavigationSection(index, indexOfStartOfMx)
                      : undefined
                  }
                />
              );
              // We will insert the promo tile at the end
              return null;
            }

            if (isTileList) {
              return (
                <ThirdLevelTileNavItem
                  subflyoutLink={subCategoryLink as NavItemLeafType}
                  parentNavigationDetailPath={props.parentNavigationDetailPath}
                  layoutType="Tile List Tile"
                  key={subCategoryLink.id}
                  dataPosition={index + 1}
                />
              );
            }

            return (
              <>
                {subCategoryLink?.startOfMx && (
                  <Divider my={'3'} className="mx-nav-divider" />
                )}
                {isEmptyNavItem(subCategoryLink) ? (
                  <SeeAllLink
                    className={classNames({
                      'site-header-nav-flyout-focused': isCurrentCategory,
                    })}
                    categoryLink={subCategoryLink as NavItemLeafType}
                    parentNavigationDetailPath={
                      props.parentNavigationDetailPath
                    }
                    analyticsId={subCategoryLink.analyticsId}
                    key={subCategoryLink.id}
                    dataPosition={index + 1}
                    trackTests={true}
                    index={index}
                    navigationSection={
                      isMxNav
                        ? getNavigationSection(index, indexOfStartOfMx)
                        : undefined
                    }
                  />
                ) : (
                  <SubCategoryWithChildren
                    index={index}
                    subCategoryLink={subCategoryLink as NavItemWithChildrenType}
                    isFocused={isCurrentCategory}
                    parentNavigationDetailPath={
                      props.parentNavigationDetailPath
                    }
                    key={(subCategoryLink as NavItemWithChildrenType).id}
                    isVisualNav={props.isVisualNav}
                    navigationSection={
                      isMxNav
                        ? getNavigationSection(index, indexOfStartOfMx)
                        : undefined
                    }
                  />
                )}
              </>
            );
          })}
          {!isTileList && props.seeAllUrl && props.seeAllText && !isMxNav && (
            <SeeAllLink
              className={classNames({
                'site-header-nav-flyout-focused': isCurrentCategory,
              })}
              categoryLink={
                {
                  text: props.seeAllText,
                  url: props.seeAllUrl,
                  analyticsId: props.analyticsId,
                  theme: '',
                } as NavItemLeafType
              }
              parentNavigationDetailPath={props.parentNavigationDetailPath}
              analyticsId={props.analyticsId}
              dataPosition={props.children.length}
            />
          )}
          {promoTile}
          {/* see all link section */}
        </Accordion>
      </div>
    </section>
  );
};
