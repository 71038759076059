import * as React from 'react';
import classNames from 'classnames';
import { useHoverIntent } from '../../../../hooks/useHoverIntent';
import { NavContextProvider, useNavContext } from '../NavContext';
import { isEmptyNavItem } from '../isEmptyNavItem';
import {
  NavItemLeaf,
  TileListNavItemWithChildren,
  TileMenuNavItemWithChildren,
  TextNavItemWithChildren,
} from '../NavItem';
import {
  DesktopNavProps,
  NavItem,
  NavItemLeafType,
  NavItemWithChildrenType,
} from '../../../../types';

export const DesktopNav = ({
  navigation,
  userTests,
  topLevelNavNodeTests,
  navigationLabel,
  navigationMenuTitle,
  auth,
  locale,
  setShowVeil,
}: DesktopNavProps) => {
  return (
    <NavContextProvider
      navigationMenuTitle={navigationMenuTitle}
      navigationLabel={navigationLabel}
      navigation={navigation}
      userTests={userTests}
      topLevelNavNodeTests={topLevelNavNodeTests}
      auth={auth}
      locale={locale}
      setShowVeil={setShowVeil}
    >
      <NavContents />
    </NavContextProvider>
  );
};

const NavContents = () => {
  const {
    navigationLabel,
    navigationMenuTitle,
    state,
    navigation,
    unfocusNav,
    hoverNavItem,
  } = useNavContext();

  const navRef = useHoverIntent({ hoverOut: unfocusNav });

  return (
    (<nav
      ref={navRef}
      className={classNames(
        {
          'site-header-nav-flyout-focused': state.shouldShowVeil,
        },
        'site-header-nav swan-hidden-sm swan-hidden-xs full-width-container full-width-container-capped'
      )}
      aria-label={navigationLabel}
      data-mobile-menu-title={navigationMenuTitle}
    >
      <ul
        className={classNames(
          'site-header-nav-list',
          'site-header-nav-menu-items',
          'site-header-nav-menu-items-top-level',
          'swan-text-1'
        )}
      >
        {navigation.map((navLink: NavItem, index: number) => {
          const isFocusedCategory = state.focusedCategoryIdx === index;
          return isEmptyNavItem(navLink) ? (
            // If there are no children, render a top-level leaf node
            (<NavItemLeaf
              {...(navLink as NavItemLeafType)}
              className={classNames({
                'site-header-nav-flyout-focused': isFocusedCategory,
              })}
              onFocus={() => hoverNavItem(index, navigation)}
              index={index}
              key={navLink.id}
              dataPosition={index + 1}
            />)
          ) : navLink.isVisual ? (
            (navLink as NavItemWithChildrenType).children.every((child) =>
              isEmptyNavItem(child)
            ) ? (
              <TileListNavItemWithChildren
                {...(navLink as NavItemWithChildrenType)}
                className={classNames({
                  'site-header-nav-flyout-focused': isFocusedCategory,
                })}
                index={index}
                onFocus={() => hoverNavItem(index, navigation)}
                isInFocusedCategory={isFocusedCategory}
                key={navLink.id}
                dataPosition={index + 1}
              />
            ) : (
              <TileMenuNavItemWithChildren
                {...(navLink as NavItemWithChildrenType)}
                className={classNames({
                  'site-header-nav-flyout-focused': isFocusedCategory,
                })}
                index={index}
                onFocus={() => hoverNavItem(index, navigation)}
                isInFocusedCategory={isFocusedCategory}
                key={navLink.id}
                dataPosition={index + 1}
              />
            )
          ) : (
            <TextNavItemWithChildren
              {...(navLink as NavItemWithChildrenType)}
              className={classNames({
                'site-header-nav-flyout-focused': isFocusedCategory,
              })}
              index={index}
              onFocus={() => hoverNavItem(index, navigation)}
              isInFocusedCategory={isFocusedCategory}
              key={navLink.id}
              dataPosition={index + 1}
              seeAllCategory={
                (navLink as NavItemWithChildrenType).seeAllCategory
              }
            />
          );
        })}
      </ul>
    </nav>)
  );
};
