import { Profile } from '../types/';
import { doRequest } from '../utils/http';

export const getEnvironmentSuffix = (): 'prod' | 'dev' => {
  if (
    typeof window !== 'undefined' &&
    ((window &&
      window.location &&
      window.location.hostname &&
      window.location.hostname.startsWith('staging.')) ||
      window.location.hostname.startsWith('localhost'))
  ) {
    return 'dev';
  }

  return 'prod';
};

export async function getProfile(token: string): Promise<Profile> {
  if (!token) {
    return Promise.reject('token was undefined');
  }
  // NOTE: This businessAccountId is the tenant ID that holds VistaPrint shoppers profiles in the Profile Service.
  // This is a value supplied by the Access Domain team (#mcp-support-access) and it is required to be passed as per their OpenAPI specs(https://profile.cimpress.io/v1/%7BaccountId%7D/profile/me).
  const businessAccountId = 'ozoDdrmewShEcbUDWX8J3V';
  return doRequest(
    `https://profile.cimpress.io/v1/${businessAccountId}/profile/me`,
    { headers: { Authorization: `${token}` } }
  ).then((res) => res);
}

export async function getInternalUserProfile(token: string): Promise<Profile> {
  if (!token) {
    return Promise.reject('token was undefined');
  }
  return doRequest(
    `https://profile.cimpress.io/v1/profile/me`,
    { headers: { Authorization: `${token}` } }
  ).then((res) => res);
}
