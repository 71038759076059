import { SwanStyleSheetKey } from '@vp/swan';

export const swanStyleKeys: SwanStyleSheetKey[] = [
  'core',
  'button',
  'icon',
  'accordion',
  'hidden',
  'visible',
  'utility',
  'grid',
  'popover',
  'modalDialog',
  'toggleSwitch',
];
