import React, { useContext, useEffect, useState } from 'react';
import {
  VistaGoogleOneTap,
  TRACKING_EVENT_NAMES,
  TRACKING_CATEGORY,
  TRACKING_LABELS,
  GoogleOneTapNotification,
} from '@vp/google-one-tap';
import { TrackingContext } from '../../hooks/useTracking';
import { ErrorBoundary } from '../ErrorBoundary';

export interface GoogleOneTapProps {
  auth: any;
}

export const GoogleOneTap = ({ auth }: GoogleOneTapProps) => {
  const { trackGoogleOneTap } = useContext(TrackingContext);
  const [show, setShow] = useState(false);
  const identity = auth?.getIdentity();
  const isIdentityInitialized = Boolean(identity?.authorizationHeader);
  const isSignedIn = identity?.isSignedIn;

  useEffect(() => {
    setShow(true);
    return () => {
      setShow(false);
    };
  }, []);

  if (!show) {
    return null;
  }

  return (
    <ErrorBoundary component="VistaGoogleOneTap">
      <VistaGoogleOneTap
        authData={{
          auth: auth,
          isIdentityInitialized: isIdentityInitialized,
          isSignedIn: isSignedIn,
        }}
        onCredentialResponse={() => {
          handleCredentialResponse(trackGoogleOneTap, identity?.canonicalId);
        }}
        onNotificationEvent={(
          eventType: GoogleOneTapNotification,
          reason: string
        ) => {
          handleNotificationEvent(trackGoogleOneTap, eventType, reason);
        }}
      />
    </ErrorBoundary>
  );
};

const handleCredentialResponse = (
  trackGoogleOneTap: (
    eventName: string,
    category: string,
    label: string
  ) => void,
  canonicalId: string
) => {
  trackGoogleOneTap(
    TRACKING_EVENT_NAMES.SIGNED_IN,
    TRACKING_CATEGORY,
    canonicalId
  );
};

const handleNotificationEvent = (
  trackGoogleOneTap: (
    eventName: string,
    category: string,
    label: string
  ) => void,
  eventType: GoogleOneTapNotification,
  reason: string
) => {
  trackGoogleOneTap(
    getGoogleOneTapTrackingEventName(eventType),
    TRACKING_CATEGORY,
    getGoogleOneTapTrackingEventLabel(eventType, reason)
  );
};

const getGoogleOneTapTrackingEventName = (
  eventType: GoogleOneTapNotification
): string => {
  switch (eventType) {
    case GoogleOneTapNotification.IS_NOT_DISPLAYED:
      return TRACKING_EVENT_NAMES.NOT_DISPLAYED;
    case GoogleOneTapNotification.IS_SKIPPED_MOMENT:
      return TRACKING_EVENT_NAMES.SKIPPED;
    case GoogleOneTapNotification.IS_DISMISSED_MOMENT:
      return TRACKING_EVENT_NAMES.DISMISSED;
  }
};

const getGoogleOneTapTrackingEventLabel = (
  eventType: GoogleOneTapNotification,
  reason: string
): string => {
  switch (eventType) {
    case GoogleOneTapNotification.IS_NOT_DISPLAYED:
      return reason
        ? `${TRACKING_LABELS.PROMPT_NOT_DISPLAYED} - ${reason}`
        : TRACKING_LABELS.PROMPT_NOT_DISPLAYED;
    case GoogleOneTapNotification.IS_SKIPPED_MOMENT:
      return reason;
    case GoogleOneTapNotification.IS_DISMISSED_MOMENT:
      return reason;
  }
};
