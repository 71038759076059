import { isEmptyNavItem } from '../isEmptyNavItem';
import { Logger } from '../../../../utils/logger';
import { NavContextState, NavItem } from '../../../../types';

export const manageKeydown = (
  currentCategoryIdx: number,
  event: React.KeyboardEvent<HTMLLIElement>,
  topLevelItemReferences: any[],
  state: NavContextState,
  navigation: Array<NavItem>
) => {
  let currentFocus: Element | undefined = document.activeElement || undefined;
  let focusedCategoryIdx: number | undefined = state.focusedCategoryIdx || 0;
  let focusedSubcategoryIdx: number | undefined = state.focusedSubcategoryIdx;
  let focusedThirdLevelIdx: number | undefined = state.focusedThirdLevelIdx;
  let focusAfterRender: boolean = false;
  let secondLvlNav: any;
  let thirdLvlNav: any;
  let isSeeAllLink: any;

  //Todo: see if we can instead of swapping out entire manage keypress function, we can just swap the suite of helpers below? Might be weird with scope issues

  let getSecondLevelNav = (currentCategoryId: number) => {
    return topLevelItemReferences[
      currentCategoryId
    ]?.current.getElementsByClassName('site-header-nav-secondlevel-js');
  };

  let getThirdLevelNav = (currentSecondLevelRef: any) => {
    if (
      currentSecondLevelRef.classList.contains(
        'site-header-nav-tile-menu-secondlevel-js'
      )
    ) {
      return currentSecondLevelRef
        .closest('.site-header-nav-tile-menu-secondlevel-container-js')
        ?.getElementsByClassName('site-header-nav-thirdlevel-js');
    }
    return currentSecondLevelRef.parentElement.getElementsByClassName(
      'site-header-nav-thirdlevel-js'
    );
  };

  let isFlyoutOpen = () => {
    if (
      topLevelItemReferences[
        focusedCategoryIdx || 0
      ]?.current.classList.contains('site-header-nav-toplevel-empty')
    ) {
      return false;
    }
    return topLevelItemReferences[
      focusedCategoryIdx || 0
    ]?.current.classList.contains('site-header-nav-flyout-focused');
  };

  /** Only intended for use when navigating inside an open menu, not between menus */
  let isTileListLayout = () => {
    return topLevelItemReferences[
      focusedCategoryIdx || 0
    ]?.current.classList.contains('site-header-nav-tile-list-layout-js');
  };

  /** Only intended for use when navigating inside an open menu, not between menus */
  let isTileMenuLayout = () => {
    return topLevelItemReferences[
      focusedCategoryIdx || 0
    ]?.current.classList.contains('site-header-nav-tile-menu-layout-js');
  };

  /** Only intended for use when navigating inside an open menu, not between menus */
  let isTextLayout = () => {
    return topLevelItemReferences[
      focusedCategoryIdx || 0
    ]?.current.classList.contains('site-header-nav-text-layout-js');
  };

  let isOnFirstLvl = () => {
    if (currentFocus !== undefined) {
      return (
        currentFocus?.classList.contains('site-header-nav-toplevel-js') ||
        currentFocus?.parentElement?.classList.contains(
          'site-header-nav-toplevel-js'
        )
      );
    }
    return false;
  };

  let isOnSecondLvl = () => {
    if (currentFocus !== undefined) {
      return currentFocus.classList.contains('site-header-nav-secondlevel-js');
    }
    return false;
  };

  let isOnThirdLvl = () => {
    if (currentFocus !== undefined) {
      return currentFocus.classList.contains('site-header-nav-thirdlevel-js');
    }
    return false;
  };

  let selectNextFirstLevel = (canLeaveNav = false) => {
    // Tabbing at end of top lvl menu should tab to body of page
    if (
      canLeaveNav &&
      currentCategoryIdx + 1 === topLevelItemReferences.length
    ) {
      (currentFocus as HTMLElement).blur();

      // check for promobar
      let nextFocusableElement = document.getElementsByClassName(
        'sitewide-promobar-carousel'
      )[0];

      //if it not exists take main element
      if (!nextFocusableElement) {
        nextFocusableElement =
          document.getElementsByClassName('swan-site-main')[0];
      }

      if (nextFocusableElement) {
        const focusableElements = nextFocusableElement.querySelectorAll(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        );
        const firstFocusableElement = Array.from(
          focusableElements
        )[0] as HTMLElement;
        if (firstFocusableElement) {
          firstFocusableElement.focus();
        }
      }
    } else if (currentCategoryIdx + 1 < topLevelItemReferences.length) {
      focusedCategoryIdx = currentCategoryIdx + 1;
      topLevelItemReferences[focusedCategoryIdx]?.current?.focus();
      focusedCategoryIdx = undefined;
      focusedThirdLevelIdx = undefined;
    }
  };

  let selectNextSecondLevel = () => {
    secondLvlNav = getSecondLevelNav(focusedCategoryIdx || 0);
    if (
      secondLvlNav.length > 0 &&
      (focusedSubcategoryIdx || 0) < secondLvlNav.length - 1
    ) {
      if (focusedSubcategoryIdx === undefined) {
        focusedSubcategoryIdx = 0;
      } else {
        focusedSubcategoryIdx = (focusedSubcategoryIdx || 0) + 1;
      }
      focusedThirdLevelIdx = undefined;
      const isLink =
        (secondLvlNav[focusedSubcategoryIdx]?.firstChild as HTMLElement)
          .tagName === 'A';
      const isTile =
        secondLvlNav[focusedSubcategoryIdx]?.firstChild?.classList?.contains(
          'swan-standard-tile'
        );
      isSeeAllLink = secondLvlNav[focusedSubcategoryIdx]?.tagName === 'A';
      if (isLink || isTile || isSeeAllLink) {
        secondLvlNav[focusedSubcategoryIdx].focus();
      } else {
        selectNextThirdLevel();
      }
    } else {
      // Tab on Flyout's last value - closes the flyout and progresses to next
      focusedCategoryIdx = undefined;
      focusedSubcategoryIdx = undefined;
      selectNextFirstLevel();
    }
  };

  let selectNextThirdLevel = () => {
    thirdLvlNav = getThirdLevelNav(
      getSecondLevelNav(focusedCategoryIdx || 0)[focusedSubcategoryIdx || 0]
    );
    if (
      (focusedThirdLevelIdx !== undefined &&
        focusedThirdLevelIdx >= thirdLvlNav.length - 1) ||
      thirdLvlNav.length === 0
    ) {
      selectNextSecondLevel();
    } else {
      focusedThirdLevelIdx =
        focusedThirdLevelIdx !== undefined ? focusedThirdLevelIdx + 1 : 0;
      thirdLvlNav[focusedThirdLevelIdx].focus();
    }
  };

  let selectPreviousFirstLevel = (canLeaveNav = false) => {
    if (
      canLeaveNav &&
      (focusedCategoryIdx === undefined || focusedCategoryIdx === 0)
    ) {
      focusedCategoryIdx = undefined;
      (
        document.body.querySelector(
          '.site-header-link-cart a, .site-header-link-cart button'
        ) as any
      )?.focus();
    }
    if (currentCategoryIdx + 1 <= topLevelItemReferences.length) {
      focusedCategoryIdx = currentCategoryIdx - 1;
      topLevelItemReferences[focusedCategoryIdx]?.current?.focus();
      focusedCategoryIdx = undefined;
      focusedThirdLevelIdx = undefined;
    }
  };

  let selectPreviousSecondLevel = () => {
    secondLvlNav = getSecondLevelNav(focusedCategoryIdx || 0);
    const newIndex = (focusedSubcategoryIdx || 0) - 1;
    if (newIndex < 0) {
      returnToFirstLevel();
    } else {
      const isLink = secondLvlNav[newIndex]?.firstChild?.tagName === 'A';
      const isTile =
        secondLvlNav[newIndex]?.firstChild?.classList?.contains(
          'swan-standard-tile'
        );
      isSeeAllLink = secondLvlNav[newIndex]?.tagName === 'A';
      focusedSubcategoryIdx = newIndex;
      if (isLink || isTile || isSeeAllLink) {
        secondLvlNav[focusedSubcategoryIdx].focus();
        focusedThirdLevelIdx = undefined;
      } else {
        // select previous third level 1st value and not the last
        thirdLvlNav = getThirdLevelNav(
          getSecondLevelNav(focusedCategoryIdx || 0)[newIndex || 0]
        );
        thirdLvlNav[0].focus();
      }
    }
  };

  let selectPreviousThirdLevel = () => {
    thirdLvlNav = getThirdLevelNav(
      getSecondLevelNav(focusedCategoryIdx || 0)[focusedSubcategoryIdx || 0]
    );
    // if we are on a second level and want the previous third, we get it from the previous second level's last third, or we move to 1st level
    if (focusedThirdLevelIdx === undefined) {
      selectPreviousSecondLevel();
      //we are still in flyout if we have a focused subcategory, so see if there's a 3rd level we should be on
      if (focusedSubcategoryIdx !== undefined) {
        secondLvlNav = getSecondLevelNav(focusedCategoryIdx || 0);
        //Tile menu needs to do something special because react conditional rendering
        if (!isTileMenuLayout()) {
          thirdLvlNav = getThirdLevelNav(secondLvlNav[focusedSubcategoryIdx]);
          //Previous second level might not actually have children to select - only move to last child if there is one
          if (thirdLvlNav.length > 0) {
            focusedThirdLevelIdx = thirdLvlNav.length - 1;
            thirdLvlNav[focusedThirdLevelIdx].focus();
          }
        } else {
          thirdLvlNav = secondLvlNav[
            focusedSubcategoryIdx || 0
          ].parentElement.getElementsByClassName(
            'site-header-nav-tile-menu-thirdlevel-js'
          );
          if (thirdLvlNav.length > 0) {
            focusedThirdLevelIdx = thirdLvlNav.length - 1;
            focusAfterRender = true;
            // let component take care of setting focus once tiles actually render
          }
        }
      }
    } else if (focusedThirdLevelIdx === 0) {
      focusedThirdLevelIdx = undefined;
      returnToSecondLevel();
    } else {
      focusedThirdLevelIdx = focusedThirdLevelIdx - 1;
      thirdLvlNav[focusedThirdLevelIdx].focus();
    }
  };

  let returnToFirstLevel = () => {
    topLevelItemReferences[currentCategoryIdx || 0]?.current?.focus();
    focusedSubcategoryIdx = undefined;
    focusedThirdLevelIdx = undefined;
  };
  let returnToSecondLevel = () => {
    const secondLvlNav = getSecondLevelNav(focusedCategoryIdx || 0)[
      focusedSubcategoryIdx || 0
    ];

    if (secondLvlNav.firstChild.tagName === 'A') {
      secondLvlNav.focus();
    } else {
      focusedThirdLevelIdx = undefined;
      selectPreviousThirdLevel();
    }
  };

  try {
    switch (event.key) {
      case 'Tab':
        if (event.shiftKey) {
          if (isOnFirstLvl()) {
            //if 0th first level, go back to Basket
            // selectPreviousFirstLevel(true);
            selectPreviousFirstLevel(true);
          } else if (isOnSecondLvl()) {
            //if we're on >0 second level
            //if the previous second level has children
            //select last of those children
            //else
            //select previous second level
            //else select previous first level
            selectPreviousThirdLevel();
          } else if (isOnThirdLvl()) {
            selectPreviousThirdLevel();
          }
        } else {
          if (isOnFirstLvl() && isFlyoutOpen()) {
            selectNextSecondLevel();
          } else if (isOnFirstLvl() && !isFlyoutOpen()) {
            selectNextFirstLevel(true);
          } else if (isOnSecondLvl()) {
            if (isTextLayout()) {
              selectNextThirdLevel();
            } else {
              selectNextSecondLevel();
            }
          } else if (isOnThirdLvl()) {
            selectNextThirdLevel();
          }
        }
        break;
      case 'ArrowRight':
        if (isOnFirstLvl()) {
          selectNextFirstLevel(true);
        } else if (isTextLayout() || isTileListLayout()) {
          selectNextSecondLevel();
        } else if (isTileMenuLayout()) {
          selectNextThirdLevel();
        }
        break;
      case 'ArrowLeft':
        if (isOnFirstLvl()) {
          selectPreviousFirstLevel();
        } else if (isTextLayout() || isTileListLayout()) {
          selectPreviousSecondLevel();
        } else if (isTileMenuLayout()) {
          selectPreviousThirdLevel();
        }
        break;
      case 'ArrowDown':
        if (isOnFirstLvl() && !isFlyoutOpen()) {
          focusedCategoryIdx = undefined;
        } else if (isOnFirstLvl() && isFlyoutOpen()) {
          selectNextSecondLevel();
        } else if (isTextLayout()) {
          selectNextThirdLevel();
        } else if (isTileMenuLayout()) {
          selectNextSecondLevel();
        }
        break;
      case 'ArrowUp':
        if (isOnFirstLvl() && !isFlyoutOpen()) {
          focusedCategoryIdx = undefined;
        } else if (isTextLayout()) {
          selectPreviousThirdLevel();
        } else if (isTileMenuLayout()) {
          selectPreviousSecondLevel();
        } else if (isTileListLayout()) {
          returnToFirstLevel();
        }
        break;
      case 'Enter':
      case ' ':
        if (isOnFirstLvl()) {
          if (isOnFirstLvl() && isFlyoutOpen()) {
            focusedCategoryIdx = undefined;
            focusedSubcategoryIdx = undefined;
          } else focusedCategoryIdx = currentCategoryIdx;
        } else if (isOnSecondLvl()) {
          selectNextThirdLevel();
        }
        break;
      case 'Escape':
        if (isOnSecondLvl() || isOnThirdLvl()) {
          returnToFirstLevel();
          focusedCategoryIdx = undefined;
          focusedSubcategoryIdx = undefined;
        } else if (isOnFirstLvl() && isFlyoutOpen()) {
          focusedCategoryIdx = undefined;
          focusedSubcategoryIdx = undefined;
        }
        break;
      default:
        // I feel like we should remove this - do we want the focused category to reset when a user presses Shift or 'a'?
        // It doesn't seem like it does anything.
        if (isOnFirstLvl() && !isFlyoutOpen()) {
          focusedCategoryIdx = undefined;
        }
        break;
    }
  } catch (error) {
    Logger.instance.error('Visual Nav Keypress Error: ', {
      contextData: {
        error: error,
      },
    });
  }

  let shouldShowVeil: boolean = false;
  if (focusedCategoryIdx !== undefined) {
    shouldShowVeil = !isEmptyNavItem(navigation[focusedCategoryIdx]);
  } else {
    shouldShowVeil = false;
  }

  return {
    ...state,
    focusedCategoryIdx,
    focusedSubcategoryIdx,
    focusedThirdLevelIdx,
    shouldShowVeil,
    focusAfterRender,
  };
};
