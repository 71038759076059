import * as React from 'react';
import { ScreenClassProvider } from '@vp/swan';
import { useBookendsData } from '../../hooks/useBookendsData';
import { TrackingProvider } from '../../hooks/useTracking';
import { ErrorBoundary } from '../ErrorBoundary';
import { MasksLayout } from './MasksLayout';
import { DefaultLayout } from './DefaultLayout';

import {
  BookendsResponse,
  CountrySelectorProps,
  FullFooterProps,
  FullFooterSharedProps,
  GenericSharedProps,
  BookendsServiceCallParameters,
  Tenants,
} from '../../types';

type Props =
  | (BookendsResponse<FullFooterProps & CountrySelectorProps> &
      FullFooterSharedProps &
      GenericSharedProps)
  | (BookendsServiceCallParameters &
      FullFooterSharedProps &
      GenericSharedProps);

const FullFooterContents = (props: Props) => {
  const data = useBookendsData<
    FullFooterProps & CountrySelectorProps & Tenants
  >(props, {
    version: 'v3',
    headerOrFooter: 'footer',
    variant: 'full',
  });
  if (!data) return <footer></footer>;

  if (data.footerLayoutName === 'microsite') {
    return <MasksLayout data={data} deepLink={props.deepLink} />;
  }

  return <DefaultLayout data={data} deepLink={props.deepLink} />;
};

export const FullFooter = (props: Props) => {
  return (
    <TrackingProvider
      trackingConfiguration={props.trackingConfiguration}
      bookendName="Footer"
    >
      <ErrorBoundary
        suppressError={props.suppressError}
        component="full-footer"
      >
        <ScreenClassProvider>
          <FullFooterContents {...props} />
        </ScreenClassProvider>
      </ErrorBoundary>
    </TrackingProvider>
  );
};
