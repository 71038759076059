import { FlexBox, Icon, Typography } from '@vp/swan';
import React, { useContext} from 'react';
import { SimpleLink } from 'types';
import { MyAccountMobileContext } from './MyAccountMobileContext';
import { RightArrowIcon } from './RightArrowIcon';
import { useNavContext } from '../../NavContext';

type MyAccountButtonProps = {
  text: string | undefined;
  accountLink: SimpleLink;
};

const MyAccountButton = (props: MyAccountButtonProps) => {
  const { text, accountLink } = props;
  const accountContext = useContext(MyAccountMobileContext);
  const { isMxNav } = useNavContext()

  const accountLinkText = text || accountLink.text;

  return (
    (<button
            className="swan-button-skin-unstyled"
            onClick={() => accountContext.setIsOpen(!accountContext.isOpen)}
          >
      <FlexBox alignItems={'center'} justifyContent={"space-between"}>
        <Typography fontSize={"standard"}>{accountLinkText}</Typography>
        {
          isMxNav ? 
          <Icon iconType="chevronRight" size={"16p"} /> :
          <FlexBox alignItems={'center'} ml={"7"} style={{ paddingTop: '2px' }}>
            <RightArrowIcon />
          </FlexBox>
        }
      </FlexBox>
    </button>)
  );

};

export default MyAccountButton;
