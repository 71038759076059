import * as React from 'react';
import { FlexBox, Visible, Span, Li, useScreenClass } from '@vp/swan';
import { Account } from './Account';
import TrackableLink from '../TrackableLink';
import { HeaderLinkProps } from '../../types';
import { Image } from '../Image';
import {
  CartTenantDetails,
  MiniCart,
  getCartTenantDetails,
} from '@vp/cart-client';
import { TrackingContext } from '../../hooks/useTracking';
import { Logger } from '../../utils/logger';
import { FavoritesHeader } from '@vp/my-favorites-header';
import { ErrorBoundary } from '../ErrorBoundary';

export const HeaderLinks = (props: HeaderLinkProps) => {
  const {
    homeUrl,
    accountLink,
    carePhoneNumber,
    cartLink,
    headerLinksLabel,
    helpLink,
    auth,
    cartIconComponent,
    myProjectsLink,
    siteHeaderHelpLinkA11yText,
    locale,
    suppressError,
  } = props;
  const [cartTenantDetails, setCartTenantDetails] = React.useState({});
  const { pageName, pageStage, pageSection } =
    React.useContext(TrackingContext);
  const screenClass = useScreenClass();

  const minicartComponent =
    (cartTenantDetails && (
      <MiniCart
        tenantDetails={cartTenantDetails as CartTenantDetails}
        locale={locale}
        auth={auth}
        pageName={pageName}
        pageSection={pageSection}
        pageStage={pageStage}
      />
    )) ??
    null;

  const getCartComponent = () => {
    // cartIconComponent will be present incase of ESI bookends
    if (cartIconComponent) {

      // -- TODO : remove this temporary code later -- // 
      const cartIconComponentCartURL = cartIconComponent.props?.tenantDetails?.cartUrl;
      const cartIconComponentAbsoluteCartUrl = cartIconComponent.props?.tenantDetails?.absoluteCartUrl;

      if (cartIconComponentCartURL?.includes("undefined") || cartIconComponentAbsoluteCartUrl?.includes("undefined")) {
        Logger.instance.info("cartIconComponent will be loaded with undefined in the url", {
          cartIconComponentCartURL,
          cartIconComponentAbsoluteCartUrl,
          bookendsKey : document?.getElementById("bookendsHeader")?.getAttribute("data-headerkey"),
        });
      }

      // -- TODO : remove this temporary code later -- // 

      return cartIconComponent;
    } else if (minicartComponent) {

      // -- TODO : remove this temporary code later -- // 
      const miniCartComponentCartURL = minicartComponent.props?.tenantDetails?.cartUrl;
      const minicartComponentAbsoluteCartUrl = minicartComponent.props?.tenantDetails?.absoluteCartUrl;

      if (miniCartComponentCartURL?.includes("undefined") || minicartComponentAbsoluteCartUrl?.includes("undefined")) {
        Logger.instance.info("minicartComponent will be loaded with undefined in the url", {
          miniCartComponentCartURL,
          minicartComponentAbsoluteCartUrl,
          bookendsKey : document?.getElementById("bookendsHeader")?.getAttribute("data-headerkey"),
        });
      }
      // -- TODO : remove this temporary code later -- // 

      return minicartComponent;
    }
  };

  React.useEffect(() => {
    getCartTenantDetails('vistaprint', locale, 'production').then((result) => {
      setCartTenantDetails(result);
    });
  }, [locale]);

  // -- TODO : remove this temporary code later -- // 
  if (!getCartComponent() && cartLink?.url?.includes("undefined")) {
    Logger.instance.info(`Cart icon did not load from getCartComponent. Cart url from bookends data - ${cartLink?.url}`, {
      actualCartLogoURL : document.querySelector(".site-header-link-cart a")?.getAttribute("href"),
      bookendsKey : document?.getElementById("bookendsHeader")?.getAttribute("data-headerkey"),
    });
  }
  // -- TODO : remove this temporary code later -- // 
  
  return (
    <nav className="site-header-links" aria-label={headerLinksLabel}>
      <FlexBox
        as="ul"
        role="list"
        // role="list" is to account for Safari suppressing lists with the bullets removed
        // (see https://www.scottohara.me/blog/2019/01/12/lists-and-safari.html)
        className="swan-list swan-list-skin-minimal"
        display="flex"
        alignItems="flex-start"
        flexDirection="row"
      >
        <Li padding={0} className="icon-alignment">
          <div className="site-header-link-menu nohover">
            <TrackableLink
              navigationDetailPath={[helpLink.analyticsId || 'help']}
              className="site-header-link site-header-link-content site-header-link-menu-top-item"
              href={helpLink.url}
              aria-label={siteHeaderHelpLinkA11yText}
            >
              {helpLink.image && (
                <Image
                  className="site-header-link-icon"
                  image={helpLink.image}
                  ariaHidden={true}
                />
              )}
              <Visible lg xl>
                <span className="site-header-link-text swan-text-1">
                  <span className="site-header-link-primary">
                    {helpLink.text}
                  </span>
                  {carePhoneNumber && (
                    <Span
                      textColor="subtle"
                      className="site-header-link-subtext"
                    >
                      {carePhoneNumber}
                    </Span>
                  )}
                </span>
              </Visible>
            </TrackableLink>
          </div>
        </Li>
        {myProjectsLink?.text &&
          myProjectsLink?.url &&
          screenClass !== 'xs' && (
            <Li padding={0} className="icon-alignment">
              <div className="site-header-link-menu swan-hidden-xs">
                <TrackableLink
                  navigationDetailPath={[
                    myProjectsLink.analyticsId || 'myprojects',
                  ]}
                  className="site-header-link site-header-link-content site-header-link-menu-top-item"
                  href={myProjectsLink.url}
                  aria-label={myProjectsLink.text}
                >
                  {myProjectsLink.image && (
                    <Image
                      className="site-header-link-icon site-header-icon-myprojects"
                      image={myProjectsLink.image}
                      ariaHidden={true}
                    />
                  )}
                  <Visible lg xl>
                    <span className="site-header-link-text swan-text-1">
                      <span className="site-header-link-primary">
                        {myProjectsLink.text}
                      </span>
                    </span>
                  </Visible>
                </TrackableLink>
              </div>
            </Li>
          )}
        <Li padding={0} className="icon-alignment">
          <div className="site-header-link-menu site-header-link">
            <ErrorBoundary suppressError={suppressError} component="favorites">
              <FavoritesHeader
                baseUrl={homeUrl}
                locale={locale}
                auth={auth}
                pageName={pageName}
                pageSection={pageSection}
                pageStage={pageStage}
              />
            </ErrorBoundary>
          </div>
        </Li>
        {screenClass !== 'xs' && (
          <Li padding={0} className="icon-alignment">
            <Account accountLink={accountLink} auth={auth} />
          </Li>
        )}
        <Li padding={0} className="icon-alignment" fontSize="small">
          <div className="site-header-link-cart site-header-link-menu nohover">
            {getCartComponent() ? (
              getCartComponent()
            ) : (
              <TrackableLink
                navigationDetailPath={[cartLink.analyticsId || 'cart']}
                className="site-header-link site-header-link-content site-header-link-menu-top-item"
                href={cartLink.url}
                aria-label={cartLink.text}
              >
                <span className="site-header-link-cart-inner">
                  {cartLink.image && (
                    <Image
                      className="site-header-link-icon site-header-icon-cart"
                      image={cartLink.image}
                      ariaHidden={true}
                    />
                  )}
                  <span className="site-header-link-cart-count">
                    <span className="site-header-link-cart-count-value"></span>
                  </span>
                  <Visible lg xl as="span">
                    <span className="site-header-link-text swan-text-1 site-header-link-primary">
                      {cartLink.text}
                    </span>
                  </Visible>
                </span>
              </TrackableLink>
            )}
          </div>
        </Li>
      </FlexBox>
    </nav>
  );
};
