import React, { useState, createContext, FC } from 'react';
import { getPublicPageAvailability } from '../ServiceClients/PageAvailabilityServiceClient';
import { getLiveSiteMap } from '../Services/LiveSiteService';
import { getCountryLink } from '../ServiceClients/UrlServiceClient';
import {
  DeepLinkCountryLinksContext,
  DeepLinkContextProps,
  DeepLinkMappedData,
  LiveSiteMap,
  LocaleList,
  PageAvailabilityServiceData,
} from '../../../types';

const deepLinkContext = createContext<DeepLinkCountryLinksContext | undefined>(
  undefined
);
export const DeepLinkContextProvider: FC<DeepLinkContextProps> = ({
  children,
  deepLink,
  tenants,
}) => {
  const [urlData, setUrlData] = useState<Array<DeepLinkMappedData>>([]);
  const [firedClick, setFiredClick] = useState<boolean>(false);
  const getLiveSiteData = async (): Promise<Array<LiveSiteMap>> => {
    if (!tenants?.liveSiteTenant) {
      return Promise.reject(
        `Minimum context was not set up to retrieve liveSite Data`
      );
    }
    return getLiveSiteMap(tenants.liveSiteTenant);
  };
  /** Pulls the availability data */
  const getPageAvailabilityData = async (): Promise<Array<
    PageAvailabilityServiceData
  >> => {
    if (!tenants?.availabilityServiceTenant || !deepLink?.pageType) {
      return Promise.reject(
        `Minimum context was not set up to retrieve page availability Data`
      );
    }
    return getPublicPageAvailability(
      tenants.availabilityServiceTenant,
      deepLink.pageType
    );
  };
  /** Verifies if a URL is available against the Availability service */
  const getUrlServiceHrefs = async (): Promise<Array<LocaleList>> => {
    if (!deepLink?.id || !tenants?.urlServiceTenant) {
      return Promise.reject(
        `Minimum context was not set up to retrieve links data`
      );
    }
    // Special case: URL svc will return TLP urls that are available
    if (deepLink?.pageType?.toLowerCase() === 'templatelandingpage') {
      const [countryLinks] = await Promise.all([
        getCountryLink(tenants.urlServiceTenant, deepLink.id),
      ]);
      return Object.entries(countryLinks).map(([locale, value]: any) => {
        return {
          locale: locale,
          urlData: {
            isAvailable: true,
            countryLinks: value,
          },
        };
      });
    }
    // Retrieve all availability data for this locale
    const [countryLinks, availabilityData] = await Promise.all([
      getCountryLink(tenants.urlServiceTenant, deepLink.id),
      getPageAvailabilityData(),
    ]);
    return Object.entries(countryLinks).map(([locale, value]: any) => {
      // Filter availability down to the specific case, a url that's public and matches the input id.
      const availablePage = availabilityData.find(
        (element: any) => element.urlId === deepLink?.id
      );
      const isAvailable =
        !!availablePage &&
        availablePage.availability &&
        availablePage.availability.available;

      return {
        locale: locale,
        urlData: {
          isAvailable,
          countryLinks: value,
        },
      };
    });
  };
  /** Triggers a retrieval of data. */
  const retrieveDataForDeepLinks = async () => {
    if (!firedClick) {
      setFiredClick(true);
      try {
        const [liveSiteData, urlData] = await Promise.all([
          getLiveSiteData(),
          getUrlServiceHrefs(),
        ]);
        const data = liveSiteData.map((liveSiteLocale: LiveSiteMap) => {
          return {
            locale: liveSiteLocale.locale,
            liveSiteData: liveSiteLocale.value,
            urlServiceData: urlData.find(
              (urlData: any) =>
                urlData.locale.toLocaleLowerCase() ===
                liveSiteLocale.locale.toLocaleLowerCase()
            ),
            supportedLanguagesUrlData: liveSiteLocale.value.supportedLanguages.map(
              (locale: string) => {
                return urlData.find(
                  (urlData: LocaleList) =>
                    urlData.locale.toLocaleLowerCase() ===
                    locale.toLocaleLowerCase()
                );
              }
            ),
          };
        });
        setUrlData(data);
      } catch (error) {
        console.warn(error);
        // Gracefully fall back to default URLs
      }
    }
  };
  const { Provider } = deepLinkContext;

  return (
    <Provider value={{ linkData: urlData, retrieveDataForDeepLinks }}>
      {children}
    </Provider>
  );
};
export const useDeepLinkCountryLinksContext = () => {
  const context = React.useContext(deepLinkContext);
  if (context === undefined) {
    throw new Error(
      '`useDeepLinkCountryLinksContext` may only be used inside of a DeepLinkContextProvider.'
    );
  }
  return context;
};
export default {
  useDeepLinkCountryLinksContext,
  DeepLinkContextProvider,
};
