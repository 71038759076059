import * as React from 'react';
import classNames from 'classnames';
import { useIsSsrContext } from '../../../../contexts/IsSsrContext';
import { useNavContext } from '../NavContext';
import { isEmptyNavItem } from '../isEmptyNavItem';
import { TopLevelLeaf } from './TopLevelLeaf';
import { TopLevelWithChildren } from './TopLevelWithChildren';
import flattenNavLinks from '../flattenNavLinks';
import { NavItem, NavItemLeafType, NavItemWithChildrenType } from '../../../../types';

export const MobileNavItems = () => {
  const { state, navigation } = useNavContext();
  const listRef = React.useRef(null);
  const { isSsr } = useIsSsrContext();

  React.useEffect(() => {
    // If a user scrolls to a lower category, we must force the dialog to scroll up
    // to show all the subcategories
    if (state.focusedCategoryIdx?.toString()) {
      document.querySelector(
        '.site-header-nav-reduced-container'
      )!.scrollTop = 0;
    }
  }, [state.focusedCategoryIdx]);

  if (isSsr) {
    return <>{flattenNavLinks(navigation)}</>;
  } else {
    return (
      <ul
        ref={listRef}
        className={classNames(
          'site-header-nav-list',
          'site-header-nav-menu-items',
          'site-header-nav-menu-items-top-level',
          'swan-text-2',
          {
            'site-header-nav-menu-items-menu-item-focused':
              state.focusedCategoryIdx?.toString(),
          }
        )}
      >
        {navigation.map((navLink: NavItem, index: number) => {
          return isEmptyNavItem(navLink) ? (
            <TopLevelLeaf
              {...(navLink as NavItemLeafType)}
              key={`${(navLink as NavItemLeafType).url}_${index}`} // TODO key should be ID but not in the API yet
              dataPosition={index + 1}
              index={index}
            />
          ) : (
            <TopLevelWithChildren
              {...(navLink as NavItemWithChildrenType)}
              key={(navLink as NavItemWithChildrenType).id}
              index={index}
            />
          );
        })}
      </ul>
    );
  }
};
