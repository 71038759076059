import { useEffect, useState } from 'react';
import { doRequest } from '../../../utils/http';

const API_BASE_URL = 'https://url.prod.merch.vpsvc.com/v3';
const AVAILABILITY_API_BASE_URL =
  'https://page-availability-service.prod.merch.vpsvc.com/api/v2/vistaprint';
const DISCOVERY_API_BASE_URL =
  'https://discovery-pages.large-assortment.vpsvc.com/v1/discoverypageequivalents/vistaprint';

const ROUTE_TYPE: any = {
  Category: 'CategoryPage',
  Product: 'ProductPage',
  Other: 'HomePage',
  Static: 'StaticPage',
  SpecialOffers: 'SpecialOffersPage',
  Offer: 'SpecialOffersPage',
  MarketingLanding: 'MarketingLandingPage',
  '': 'HomePage',
};

const handleRequest = async (url: string, locale: string, type: string) => {
  const res = await doRequest(
    `${API_BASE_URL}/${type}/vistaprint/${locale}/${url}?requestor=test`
  );
  return res;
};

const urlValidation = async (id: string, type: string, locale: string) => {
  const availableRoutes = await doRequest(
    `${AVAILABILITY_API_BASE_URL}/${type}/url/${locale}`
  );
  const findRoute = availableRoutes.find((route: any) => route.urlId === id);
  if (!findRoute) {
    throw new Error('URL is not active');
  }
  return;
};

const handleDiscoveryPage = async (discoveryId: string, locale: string) => {
  const data = await doRequest(
    `${DISCOVERY_API_BASE_URL}/${discoveryId}?requestor=test`
  );
  if (data?.[locale]) {
    return true;
  }
  throw new Error('URL is not active');
};

export const getRedirectURL = async (
  currentUrl: string,
  requestLocale: string,
  locale: string
) => {
  try {
    if (currentUrl === '/search') {
      return `/search${window.location.search}`;
    }
    const encodedURL = encodeURIComponent(currentUrl);
    const data = await handleRequest(encodedURL, locale, 'id');
    const requestURL = await handleRequest(data.id, requestLocale, 'url');
    let type = requestURL.type;
    if (data.id === 'other:offers') {
      type = 'Offer';
    }
    if (ROUTE_TYPE[type] === 'DiscoveryPage') {
      await handleDiscoveryPage(data.id, requestLocale.toLowerCase());
    } else {
      await urlValidation(
        data.id,
        ROUTE_TYPE[type],
        requestLocale.toLowerCase()
      );
    }
    return requestURL.url;
  } catch (err) {
    return '/';
  }
};

const usePageAvailability = (
  currentUrl: string,
  locale: string,
  requestLocale?: string
) => {
  const [redirectURL, setRedirectURL] = useState('/');

  useEffect(() => {
    if (!requestLocale) {
      setRedirectURL('/');
      return;
    }
    (async () => {
      const url = await getRedirectURL(currentUrl, requestLocale, locale);
      if (url !== '/') {
        setRedirectURL(url);
        return;
      }
      const split_url = currentUrl.split('/');
      if (split_url?.length >= 1) {
        const url = await getRedirectURL(
          '/' + split_url[1],
          requestLocale,
          locale
        );
        setRedirectURL(url);
        return;
      }
      setRedirectURL('/');
    })();
  }, [requestLocale, locale, currentUrl]);

  return redirectURL;
};

export default usePageAvailability;
