import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, P, tokens, useScreenClass } from '@vp/swan';
import { isCookieConsentPending } from '@vp/cookie-consent-client-reader';

import {
  getLocalStorageWithExpiry,
  setLocalStorageWithExpiry,
} from '../../utils/utilities';

import InfoTopBar from '../InfoTopBar';
import { VatContext } from '../../contexts/VatContext';
import { TrackingContext } from '../../hooks/useTracking';
import { getCookie, setCookie } from './GeoIpBar/utils';
import {
  VAT_BAR_ENABLED_LOCALES,
  VAT_BAR_LOCAL_STORAGE_FLAG,
  HAS_SEEN_VAT_BAR_FLAG,
  VAT_BAR_LOCAL_STORAGE_FLAG_TTL_IN_DAYS,
  VAT_SELECTION_BAR_DATA,
} from '../../../constants/vatConstants';

const VatSelectionBar = ({ locale }: { locale: string }) => {
  const [showVatSelectionBar, setShowVatSelectionBar] = useState(false);
  const [showVatSelectionPromptMobile, setShowVatSelectionPromptMobile] =
    useState(false);

  const { vatInclusive, setVatInclusivity } = useContext(VatContext);
  const { trackFlyOutClicked, trackFlyOutViewed, pageSection } =
    useContext(TrackingContext);

  const screenClass = useScreenClass();
  const isMobile = screenClass === 'xs' || screenClass === 'sm';

  const invalidPageSectionsForVatBar = ['Gallery', 'Cart', 'undefined'];
  const isPageSectionValidForVatSelector =
    !invalidPageSectionsForVatBar.includes(pageSection) && !!pageSection;

  useEffect(() => {
    const vatSelectionBarEnabled =
      VAT_BAR_ENABLED_LOCALES.includes(locale) &&
      vatInclusive !== undefined &&
      !vatInclusive &&
      !getLocalStorageWithExpiry(VAT_BAR_LOCAL_STORAGE_FLAG) &&
      !getCookie(HAS_SEEN_VAT_BAR_FLAG) &&
      isPageSectionValidForVatSelector;

    setShowVatSelectionBar(vatSelectionBarEnabled);
  }, [locale, vatInclusive, isPageSectionValidForVatSelector]);

  const savedTrackFlyOutViewed = useRef(trackFlyOutViewed);
  savedTrackFlyOutViewed.current = trackFlyOutViewed;

  const runRef = useRef(false);

  useEffect(() => {
    if (
      showVatSelectionBar &&
      !runRef.current &&
      isPageSectionValidForVatSelector
    ) {
      savedTrackFlyOutViewed.current('Vat Selector Fly-Out', 'view', locale);
      runRef.current = true;
      if (!isCookieConsentPending()) {
        setCookie(HAS_SEEN_VAT_BAR_FLAG, 'true');
      }
    }
  }, [locale, isPageSectionValidForVatSelector, showVatSelectionBar]);

  const handleVatExcluded = () => {
    setVatInclusivity?.(false);
    setLocalStorageWithExpiry(
      VAT_BAR_LOCAL_STORAGE_FLAG,
      true,
      VAT_BAR_LOCAL_STORAGE_FLAG_TTL_IN_DAYS
    );
    setShowVatSelectionBar(false);
    if (isMobile) {
      setShowVatSelectionPromptMobile(true);
    }
    trackFlyOutClicked('Vat Selector Fly-Out', 'VATex');
  };

  const handleVatIncluded = () => {
    setVatInclusivity?.(true);
    setLocalStorageWithExpiry(
      VAT_BAR_LOCAL_STORAGE_FLAG,
      true,
      VAT_BAR_LOCAL_STORAGE_FLAG_TTL_IN_DAYS
    );
    setShowVatSelectionBar(false);
    if (isMobile) {
      setShowVatSelectionPromptMobile(true);
    }
    trackFlyOutClicked('Vat Selector Fly-Out', 'VATinc');
  };

  const titleHTML = <P m={0}>{VAT_SELECTION_BAR_DATA.title}</P>;

  const mobileVatPrompt = (
    <P m={0}>{VAT_SELECTION_BAR_DATA.mobilePromptText}</P>
  );

  const buttonHTML = (
    <>
      <Button
        paddingY="3"
        width={screenClass === 'xs' ? 'full-width' : 'standard'}
        bgc={'standard'}
        textColor={'standard'}
        onClick={handleVatExcluded}
      >
        {VAT_SELECTION_BAR_DATA.vatExcText}
      </Button>

      <Button
        paddingY="3"
        width={screenClass === 'xs' ? 'full-width' : 'standard'}
        bgc={'standard'}
        textColor={'standard'}
        onClick={handleVatIncluded}
      >
        {VAT_SELECTION_BAR_DATA.vatIncText}
      </Button>
    </>
  );

  return (
    <>
      {showVatSelectionBar && (
        <InfoTopBar
          id="vat-selection-bar"
          title={titleHTML}
          buttons={buttonHTML}
          style={{
            position: 'relative',
            top: tokens.SwanBaseSpace0,
          }}
          isVatSelectionBar
        />
      )}

      {showVatSelectionPromptMobile && (
        <InfoTopBar
          id="vat-selection-prompt"
          title={mobileVatPrompt}
          style={{
            position: 'relative',
            top: tokens.SwanBaseSpace0,
          }}
          isVatSelectionBar
        />
      )}
    </>
  );
};

export default VatSelectionBar;
