import React, { useState, useEffect } from 'react';
import { Box, Button, FlexBox, Visible } from '@vp/swan';
import { useHoverIntent } from '../../../hooks/useHoverIntent';
import {
  getProfile,
  getInternalUserProfile,
} from '../../../hooks/useAccountResources';
import { fireImpression, getVariation, whenAvailable } from '@vp/ab-reader';
import { SignInFlyoutContent } from './SignInFlyoutContent';
import { Image } from '../../Image';
import {
  AccountFlyoutProps,
  Identity,
  IdentityState,
  Profile,
} from '../../../types';
import { Logger } from '../../../utils/logger';
import { MyAccountContent } from '@vp/my-account-pages-dropdown';
import TrackableLink from 'components/TrackableLink';
import { getAuthHeaderAndShopperId } from '../../../utils/authUtils';
import { CLAIM_CIMPRESS_INTERNAL } from '../../../../constants/configs';

declare global {
  interface Window {
    tracking: any;
  }
}

export const AccountFlyout = ({
  resources,
  accountLink,
  auth,
  siteHeaderAccountMFEContainerId,
  siteHeaderAccountClassNames,
}: AccountFlyoutProps) => {
  const [isSignedIn, setIsSignedIn] = useState<boolean>(false);
  const [identity, setIdentity] = useState<IdentityState | undefined>(
    undefined
  );
  const [profile, setProfile] = useState<Profile | undefined>(undefined);
  const [flyoutVisibleClick, setFlyoutVisibleClick] = useState<boolean>(false);
  const [flyoutVisibleHover, setFlyoutVisibleHover] = useState<boolean>(false);
  const [, setDelayedImpressions] = useState<(() => void)[]>([]);

  const [token, setToken] = useState<string>('');
  const [shopperId, setShopperId] = useState<string>('');

  const [isBiaRendered, setIsBiaRendered] = useState<boolean>(false);

  useEffect(() => {
    if (auth) {
      const handleIdentityUpdate = (event: any) => {
        updateState(event.detail);
      };

      const updateState = (identity: Identity) => {
        setIdentity((previousIdentity) => {
          const currentIdentity = {
            authorizationHeader: identity && identity.authorizationHeader,
            isSignedIn: !!identity && !!identity.isSignedIn,
            profile: identity?.profile,
            changed: false,
          };

          if (
            !previousIdentity ||
            previousIdentity.authorizationHeader !==
              currentIdentity.authorizationHeader ||
            previousIdentity.isSignedIn !== currentIdentity.isSignedIn
          ) {
            currentIdentity.changed = true;
          }

          return currentIdentity;
        });
      };

      updateState(auth.getIdentity?.());

      window.addEventListener('userIdentity', handleIdentityUpdate);

      return () => {
        window.removeEventListener('userIdentity', handleIdentityUpdate);
      };
    }
  }, [auth]);

  useEffect(() => {
    setIsSignedIn(!!identity && identity.isSignedIn);

    if (
      identity &&
      identity.isSignedIn &&
      identity.authorizationHeader &&
      identity.changed
    ) {
      const internalUser = identity.profile?.[CLAIM_CIMPRESS_INTERNAL];
      if (internalUser) {
        getInternalUserProfile(identity.authorizationHeader)
          .then((profile) => setProfile(profile))
          .catch((error) => {
            Logger.instance.warning('Unable to fetch internal user profile', {
              experience: 'Bookends flyout',
              error,
            });
          });
      } else {
        // set identity.profile
        getProfile(identity.authorizationHeader)
          .then((profile) => setProfile(profile))
          .catch((error) => {
            Logger.instance.warning('Unable to fetch user profile', {
              experience: 'Bookends flyout',
              error,
            });
          });
      }
    }
  }, [identity]);

  useEffect(() => {
    if (auth && auth.getAuthorizationHeader) {
      const { authHeader, shopperId } = getAuthHeaderAndShopperId(auth);
      setToken(authHeader);
      setShopperId(shopperId);
    }
  }, [profile]);

  const signOut = (e: any) => {
    e.preventDefault();
    if (window.tracking && window.tracking.reset) {
      window.tracking.reset();
    }
    auth.signOut();
  };

  const signIn = () => {
    if (!isSignedIn) {
      auth.signIn([], [], { trackingCaller: 'header' });
      return false;
    } else return true;
  };

  const signUp = () => {
    if (!isSignedIn) {
      auth.signIn([], [], { trackingCaller: 'header', navHint: 'signUp' });
      return false;
    }
    return true;
  };

  const handleClick = (e: any) => {
    e.preventDefault();
    if (flyoutVisibleClick) {
      setFlyoutVisibleClick(false);
      setFlyoutVisibleHover(false);
    } else {
      setFlyoutVisibleClick(true);
    }
  };

  const accountRef: any = useHoverIntent<HTMLDivElement>({
    hoverIn: () => setFlyoutVisibleHover(true),
    hoverOut: () => setFlyoutVisibleHover(false),
  });

  const accountButtonRef = React.useRef<HTMLButtonElement>(null);

  //make sure the flyout closes when a user either clicks off or tabs to something else
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (accountRef.current && !accountRef.current.contains(event.target)) {
        setFlyoutVisibleClick(false);
        setFlyoutVisibleHover(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('focusin', handleClickOutside);
    return () => {
      // remove listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('focusin', handleClickOutside);
    };
  }, [accountRef]);

  useEffect(() => {
    const flyoutIsVisible = flyoutVisibleClick || flyoutVisibleHover;
    const handleEscape = (event: any) => {
      if (accountButtonRef.current) {
        if (event.key === 'Escape' && flyoutIsVisible) {
          accountButtonRef.current.focus();
          setFlyoutVisibleClick(false);
          setFlyoutVisibleHover(false);
        }
      }
    };

    document.addEventListener('keydown', handleEscape);
    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [accountRef, flyoutVisibleClick, flyoutVisibleHover]);

  useEffect(() => {
    const flyoutIsVisible = flyoutVisibleClick || flyoutVisibleHover;

    if (isSignedIn && flyoutIsVisible) {
      setDelayedImpressions((existingImpressions) => {
        existingImpressions.forEach((delayedImpression) => delayedImpression());
        return [];
      });
    }
  }, [isSignedIn, flyoutVisibleClick, flyoutVisibleHover]);

  const { translation, signInFlyOut: signInFlyOutData } = resources;

  return (
    <div
      id={siteHeaderAccountMFEContainerId}
      className={siteHeaderAccountClassNames}
      ref={accountRef}
    >
      <button
        className="swan-button-skin-unstyled site-header-link site-header-link-content site-header-link-menu-top-item"
        id="header-my-account-link"
        {...getHeaderAttributes(isSignedIn)}
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
          handleClick(e)
        }
        aria-label={
          isSignedIn
            ? translation.messages.account
            : translation.messages.signin
        }
        ref={accountButtonRef}
      >
        {accountLink.image && (
          <Image className="site-header-link-icon" image={accountLink.image} />
        )}
        <Visible lg xl as="span">
          <span
            className="site-header-link-text site-header-link-text-signin small"
            id="header-my-account-link-label"
          >
            {isSignedIn
              ? translation.messages.account
              : translation.messages.signin}
          </span>
        </Visible>
      </button>
      {isSignedIn && (
        <Box
          className={`accounts-dropdown ${
            isBiaRendered && 'accounts-dropdown-with-items'
          }`}
          display={flyoutVisibleClick || flyoutVisibleHover ? 'block' : 'none'}
        >
          <aside
            aria-label={translation.messages.account}
            className={`my-account-flyout my-account-flyout-account-container my-account-flyout-position-bottom ${
              !isBiaRendered
                ? 'my-account-flyout-width-small'
                : 'my-account-flyout-width-large'
            }`}
          >
            <MyAccountContent
              getVariationAsync={(pageExperimentKey: string) => {
                return new Promise((resolve) => {
                  whenAvailable(() => {
                    const variation = getVariation(pageExperimentKey);
                    resolve(variation);
                  }, 1000);
                });
              }}
              fireTestImpression={(
                experimentKey: string,
                variationKey: string
              ) => {
                setDelayedImpressions((current) => [
                  ...current,
                  () => fireImpression(experimentKey, variationKey),
                ]);
              }}
              trackableLink={(
                subSection: string,
                className: string,
                href: string,
                navigationDetailPath: string[],
                children: React.ReactNode
              ) => {
                return (
                  <TrackableLink
                    subSection={subSection}
                    className={className}
                    href={href}
                    navigationDetailPath={navigationDetailPath}
                  >
                    {children}
                  </TrackableLink>
                );
              }}
              authHeader={token}
              shopperId={shopperId}
              header={() => {
                return (
                  <div
                    className={`my-account-flyout-section ${
                      !isBiaRendered && 'my-account-flyout-section-prev'
                    }`}
                    data-testid="links-continer"
                  >
                    <div
                      className={`my-account-flyout-intro ${
                        !isBiaRendered && 'my-account-flyout-intro-prev'
                      }`}
                    >
                      <span>
                        {resources?.translation?.messages.hello}{' '}
                        {profile && profile.firstName}
                      </span>
                      <span id="my-account-flyout-profile-info"></span>
                    </div>
                    {!isBiaRendered && (
                      <a
                        className="my-account-flyout-link my-account-flyout-black-link small"
                        href={accountLink.url}
                      >
                        {accountLink.text}
                      </a>
                    )}
                  </div>
                );
              }}
              footer={() => {
                return (
                  <FlexBox
                    justifyContent="center"
                    className={`${
                      !isBiaRendered && 'my-account-flyout-footer'
                    }`}
                  >
                    <Button
                      id="my-account-logout-btn"
                      size="mini"
                      mt={'3'}
                      onClick={(e: React.MouseEvent<HTMLElement>) => signOut(e)}
                    >
                      {resources?.translation?.messages.signout ?? 'Sign Out'}
                    </Button>
                  </FlexBox>
                );
              }}
              mode={'row'}
              onStatusRendered={setIsBiaRendered}
              // will add a prop showBia when the tests are configured
            />
          </aside>
        </Box>
      )}
      {(flyoutVisibleClick || flyoutVisibleHover) && !isSignedIn && (
        <aside
          aria-label={translation.messages.account}
          className="my-account-flyout my-account-flyout-sign-in-container my-account-flyout-position-bottom"
        >
          <SignInFlyoutContent
            translation={translation}
            signIn={signIn}
            signUp={signUp}
            signInFlyOutData={signInFlyOutData}
            auth={auth}
          />
        </aside>
      )}
    </div>
  );
};

function getHeaderAttributes(isSignedIn: boolean) {
  if (isSignedIn) {
    return {
      'data-state': 'signed-in',
    };
  }
  return {
    'data-state': 'signed-out',
  };
}
