import * as React from 'react';
import { Image } from '../Image';
import TrackableLink from '../TrackableLink';
import { HelpLinkProps } from '../../types';

export const HelpLink = (props: HelpLinkProps) => {
  const { helpLink: simpleLink } = props;
  return (
    <button className="site-header-link site-header-link-menu nohover swan-hidden-sm swan-hidden-xs">
      <TrackableLink
        className="site-header-link site-header-link-content"
        href={simpleLink.url}
        navigationDetailPath={['helpPage']}
      >
        {simpleLink.image && (
          <Image className={'site-header-link-icon'} image={simpleLink.image} ariaHidden={true}/>
        )}
        <span className="site-header-link-text swan-text-1">
          <span className="site-header-link-primary">{simpleLink.text}</span>
        </span>
      </TrackableLink>
    </button>
  );
};
