import * as React from 'react';
import classNames from 'classnames';
import { isEmptyNavItem } from '../isEmptyNavItem';
import { useNavContext } from '../NavContext';
import { useHoverIntent } from '../../../../hooks/useHoverIntent';
import { TileListNavFlyout } from '../DesktopNav/TileListNavFlyout';
import { BookendsBadge } from '../../../BookendsBadge';
import TrackableLink from '../../../TrackableLink';
import { NavItemLeafType, NavItemWithChildrenType } from '../../../../types';
import { HOLIDAY_RED_COLOR_CODE } from '../../../../../constants/styleClasses';
import { isThemeHoliday } from '../../../../utils/utilities';
export const TileListNavItemWithChildren = (
  props: NavItemWithChildrenType & React.ComponentProps<'li'>
) => {
  const { navigation, hoverNavItem, unfocusNav, keypress, addItemRef } =
    useNavContext();

  const itemRef = useHoverIntent<HTMLLIElement>({
    hoverIn: () => {
      hoverNavItem(props.index, navigation);
    },
    hoverOut: () => {
      unfocusNav(props.id);
    },
  });
  addItemRef(props.index, itemRef);

  const url =
    props.url || (props.children.find(isEmptyNavItem) as NavItemLeafType)?.url;
  const navigationDetailPath = props.analyticsId ? [props.analyticsId] : [];

  return (
    <li
      id={props.id}
      ref={itemRef}
      className={classNames(
        'site-header-nav-item',
        'site-header-nav-toplevel',
        'site-header-nav-toplevel-js',
        'site-header-nav-tile-list-layout-js',
        props.className
      )}
      data-item-title={props.text}
      onKeyDown={(event: React.KeyboardEvent<HTMLLIElement>) => {
        event.persist();
        event.preventDefault();
        keypress(props.index, event, navigation);
      }}
      onBlur={(event) => {
        if (
          event.relatedTarget?.classList === undefined ||
          event.relatedTarget?.classList.contains('search-bar-input')
        ) {
          unfocusNav(props.id, true);
        }
      }}
      tabIndex={0}
    >
      <TrackableLink
        data-fidoprefetchhint="IO"
        className={`site-header-nav-link ${props.theme}`}
        style={isThemeHoliday(props.theme) ? { color: HOLIDAY_RED_COLOR_CODE } : {}}
        href={url}
        navigationDetailPath={navigationDetailPath}
        subSection="TopNav"
        aria-controls={`${props.id}-flyout`}
        aria-expanded={props.isInFocusedCategory}
        dataPosition={props.dataPosition}
        useButton={true}
      >
        <span id={`${props.id}-nav-name`}>
          {props.text}
          {props.badge && <BookendsBadge {...props.badge} layout="visual" />}
        </span>
      </TrackableLink>
      <TileListNavFlyout
        children={props.children}
        text={props.text}
        parentNavigationDetailPath={navigationDetailPath}
        id={`${props.id}-flyout`}
        labelledBy={`${props.id}-nav-name`}
        className={props.className}
        isInFocusedCategory={props.isInFocusedCategory}
        onFocus={props.onFocus}
        seeAllText={props.seeAllCategory}
        seeAllAnalyticsId={props.analyticsId}
        seeAllUrl={props.url}
      />
    </li>
  );
};
