import * as React from 'react';
import {
  StandardTile,
  StandardTileImage,
  StandardTileContents,
  StandardTileName,
  StandardTileDescription,
  StandardTileOverlay,
  Typography,
} from '@vp/swan';
import { Image } from '../../../Image';
import TrackableLink from '../../../TrackableLink';
import { BookendsBadge } from '../../../BookendsBadge';
import { ThirdLevelTileNavItemType } from '../../../../types';
import classNames from 'classnames';

export const ThirdLevelTileNavItem = ({
  subflyoutLink,
  parentNavigationDetailPath,
  dataPosition,
  layoutType,
  navigationSection,
}: ThirdLevelTileNavItemType) => {
  if (!subflyoutLink.url || !subflyoutLink.image || !subflyoutLink.image.url) {
    return null;
  }
  return (
    <li
      className="site-header-nav-item site-header-nav-thirdlevel site-header-nav-item-visual-tile-mobile"
      data-item-title={subflyoutLink.text}
    >
      <TrackableLink
        href={subflyoutLink.url}
        key={subflyoutLink.url}
        dataPosition={dataPosition}
        subSection="TopNav"
        navigationDetailPath={
          subflyoutLink.analyticsId
            ? parentNavigationDetailPath.concat([subflyoutLink.analyticsId])
            : []
        }
        linkType={layoutType}
        navigationSection={navigationSection}
      >
        <StandardTile>
          {subflyoutLink?.badge?.text && (
            <StandardTileOverlay>
              <BookendsBadge {...subflyoutLink.badge} layout="visual" />
            </StandardTileOverlay>
          )}
          <StandardTileImage>
            <Image image={subflyoutLink.image} alt={subflyoutLink.text} />
          </StandardTileImage>
          <StandardTileContents>
            <StandardTileName>
              <Typography
                fontWeight="bold"
                className={classNames(subflyoutLink.theme)}
              >
                {subflyoutLink.text}
              </Typography>
            </StandardTileName>
            <StandardTileDescription>
              {subflyoutLink.description}
            </StandardTileDescription>
          </StandardTileContents>
        </StandardTile>
      </TrackableLink>
    </li>
  );
};
