import React from 'react';

export const BackButtonIcon = () => (
  <svg
    width="14"
    height="10"
    viewBox="0 0 14 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.53033 0.46967C5.82322 0.762563 5.82322 1.23744 5.53033 1.53033L2.81066 4.25H13C13.4142 4.25 13.75 4.58579 13.75 5C13.75 5.41421 13.4142 5.75 13 5.75H2.81066L5.53033 8.46967C5.82322 8.76256 5.82322 9.23744 5.53033 9.53033C5.23744 9.82322 4.76256 9.82322 4.46967 9.53033L0.46967 5.53033C0.176777 5.23744 0.176777 4.76256 0.46967 4.46967L4.46967 0.46967C4.76256 0.176777 5.23744 0.176777 5.53033 0.46967Z"
      fill="black"
    />
  </svg>
);
