import React, { useEffect, useRef, useState } from 'react';
import { Button, P, Icon, tokens } from '@vp/swan';
import { supportedCountries, currentCountry } from './country';
import {
  checkForCurrentCountry,
  getCookie,
  setCookie,
  redirectToDefaultCountry,
} from './utils';
import { ComponentSource, Country } from 'types';
import InfoTopBar from 'components/InfoTopBar';
import { TrackingContext } from 'hooks/useTracking';
import { Logger } from '../../../utils/logger';
import usePageAvailability from 'components/FullFooter/Hooks/usePageAvailability';

interface IPreferredCountry extends Country {
  cloudflareCountryCode: string;
  geoIpBarText?: string;
  locale?: string;
  continueText?: string;
}

const GeoIpBar = ({ data, locale }: any) => {
  const [showGeoIpBar, setShowGeoIpBar] = useState(false); // to show/hide the bar
  const [isCountrySelectorOpen, setIsCountrySelectorOpen] =
    useState<boolean>(false); // to show/hide the country selector
  const [preferredCountry, setPreferredCountry] = useState<
    IPreferredCountry | undefined
  >(undefined); // to show the preferred country in the dropdown
  const wrapperRef = useRef<null | HTMLDivElement>(null);
  const { trackFlyOutViewed, trackFlyOutClicked, pageSection } =
    React.useContext(TrackingContext);
  const componentSource: ComponentSource = 'geoIP';
  let currentUrl = '';
  if (typeof window !== 'undefined') {
    currentUrl = window.location.pathname;
  }
  const redirect_url = usePageAvailability(
    currentUrl,
    locale,
    preferredCountry?.locale
  );

  useEffect(() => {
    const isConfirmed = getCookie('isUserConfirmedMessage');
    const currentCountry = checkForCurrentCountry();
    if (isConfirmed === 'true' || currentCountry === 'US') {
      Logger.instance.info(`user current country ${currentCountry}`);
      setShowGeoIpBar(false);
    } else {
      // If the user country is not US, then show the bar and find out the country if we are supporting it
      // If not, then preferred country would be undefined.
      const country = supportedCountries.find(
        (item) => item.cloudflareCountryCode === currentCountry
      );
      setShowGeoIpBar(true);
      setTimeout(() => {
        if (wrapperRef.current) {
          wrapperRef.current.style.top = '0px';
          wrapperRef.current.style.position = 'relative';
        }
      }, 300);

      setPreferredCountry(country);
      Logger.instance.info(
        `detected supported country: ${JSON.stringify(country)}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const runRef = useRef(false);

  useEffect(() => {
    if (
      preferredCountry &&
      !runRef.current &&
      pageSection !== 'undefined' &&
      pageSection
    ) {
      Logger.instance.info(
        `preferredCountry: ${JSON.stringify(preferredCountry)}`
      );
      Logger.instance.info(`user's locale: ${JSON.stringify(locale)}`);
      const currentCountry = checkForCurrentCountry();
      const country = supportedCountries.find(
        (item) => item.cloudflareCountryCode === currentCountry
      );
      if (country && showGeoIpBar) {
        trackFlyOutViewed('GeoIP Placement Fly-Out', 'view', locale);
        runRef.current = true;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSection, locale, preferredCountry, trackFlyOutViewed]);

  const handleDropdownClick = () => {
    setIsCountrySelectorOpen(true);
    trackFlyOutClicked(
      'GeoIP Placement Fly-Out',
      'dropdown',
      getCountryText(preferredCountry)
    );
  };

  const handleCloseClick = () => {
    setIsCountrySelectorOpen(false);
    setShowGeoIpBar(false);
    setCookie('isUserConfirmedMessage', 'true', 365);
    trackFlyOutClicked('GeoIP Placement Fly-Out', 'close', locale);
  };

  const getCountryText = (country?: IPreferredCountry) => {
    if (!country) return;

    if (country?.alternateLanguages?.[0]?.text) {
      return `${country?.alternateLanguages?.[0]?.text?.toLowerCase()}-${
        country?.cloudflareCountryCode
      }`;
    }
    return country?.cloudflareCountryCode;
  };

  const handleContinueClick = () => {
    setShowGeoIpBar(false);
    redirectToDefaultCountry(
      preferredCountry?.url +
        redirect_url +
        '?redirect=true&redirectOrigin=Suggested_' +
        getCountryText(preferredCountry)
    );
    trackFlyOutClicked(
      'GeoIP Placement Fly-Out',
      'continue',
      getCountryText(preferredCountry)
    );
  };

  const countrySelector = data;
  countrySelector.isCountrySelectorOpen = isCountrySelectorOpen;
  countrySelector.setIsCountrySelectorOpen = setIsCountrySelectorOpen;
  countrySelector.otherCountries = supportedCountries;
  countrySelector.currentCountry = currentCountry;

  // If showGeoIpBar is false, then don't show the bar and preferred country is undefined, then don't show the bar
  if (!showGeoIpBar || !preferredCountry) return null;

  return (
    <InfoTopBar
      title={
        preferredCountry?.geoIpBarText ? (
          <P m={0} fontWeight={'bold'}>
            {preferredCountry?.geoIpBarText}
          </P>
        ) : (
          <>
            <P m={0} fontWeight="bold">
              You are viewing the U.S. website.
            </P>
            <P m={0} fontWeight="bold">
              For a better experience, we suggest browsing the site for your
              region.
            </P>
          </>
        )
      }
      buttons={
        <>
          <Button
            size="mini"
            id="geo-ip-dropdown"
            onClick={handleDropdownClick}
            swanStyle={{
              padding: '3',
              fontSize: 'small',
            }}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderRadius: '8px',
              border: '1px solid',
              height: tokens.SwanSemSpace7,
              flex: 1,
              minWidth: `calc(${tokens.SwanSemSpace7} * 5)`,
            }}
          >
            <div
              style={{
                paddingRight: tokens.SwanSemSpace4,
              }}
            >
              {preferredCountry.text}{' '}
              {preferredCountry?.alternateLanguages.length > 0 &&
                ` - ${preferredCountry?.alternateLanguages[0].text}`}
            </div>
            <Icon iconType="caretDown" size="20p" marginRight={0} />
          </Button>
          <Button
            size="mini"
            onClick={handleContinueClick}
            id="geo-ip-continue"
            style={{
              padding: '0 70px !important',
            }}
          >
            {preferredCountry?.geoIpBarText
              ? preferredCountry?.continueText || 'Continue'
              : 'Continue'}
          </Button>
        </>
      }
      componentSource={componentSource}
      containerRef={wrapperRef}
      handleCloseClick={handleCloseClick}
      data={data}
      countrySelector={countrySelector}
      currentLocale={locale}
    />
  );
};

export default GeoIpBar;
