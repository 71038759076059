import React from 'react';
import {
  Box,
  ModalDialog,
  ModalDialogBody,
  ModalDialogContent,
  ModalDialogCloseButton,
  ModalDialogHeader,
  ModalDialogTitle,
  Typography,
  FlexBox,
  ModalDialogNav,
} from '@vp/swan';
import { Image } from '../../Image';
import { CountrySelectorCountry } from './CountrySelectorCountry';
import { AlternateLanguageLink } from './AlternateLanguageLink';
import { CountrySelectorProps } from '../../../types';

export const CountrySelector = ({
  countrySelectorDialogHeader,
  currentCountry,
  otherCountries,
  locale,
  tenant,
  isCountrySelectorOpen,
  setIsCountrySelectorOpen,
  closeButtonLabel,
  isCookie,
  componentSource,
  currentLocale,
}: CountrySelectorProps) => {
  let dataPosition = 0;

  const hasAlternateLanguages =
    currentCountry.alternateLanguages &&
    currentCountry.alternateLanguages.length;

  const alternateLanguageJsx = currentCountry.alternateLanguages.map(
    (locale: any) => {
      dataPosition += 1;
      return (
        <AlternateLanguageLink
          fontWeight={'bold'}
          key={locale.text}
          link={locale}
          parentCountry={currentCountry.text}
          dataPosition={dataPosition}
          componentSource={componentSource}
          cloudflareCountryCode={currentCountry?.cloudflareCountryCode}
          currentLocale={currentLocale}
        />
      );
    }
  );

  const alternateLanguageList = hasAlternateLanguages ? (
    <FlexBox
      as={'ul'}
      role="list"
      // role="list" is to account for Safari suppressing lists with the bullets removed
      // (see https://www.scottohara.me/blog/2019/01/12/lists-and-safari.html)
      className="swan-list swan-list-skin-minimal"
      flexDirection={'row'}
    >
      {alternateLanguageJsx}
    </FlexBox>
  ) : (
    <></>
  );

  return (
    <ModalDialog
      className="country-selector-stylized-dialog"
      isOpen={isCountrySelectorOpen}
      id="countrySelectorDialog"
      onRequestDismiss={() => {
        setIsCountrySelectorOpen(false);
      }}
    >
      <ModalDialogContent aria-label={'countrySelector'}>
        <ModalDialogNav>
          <ModalDialogCloseButton
            visuallyHiddenLabel={closeButtonLabel}
          ></ModalDialogCloseButton>
        </ModalDialogNav>
        <ModalDialogHeader>
          <ModalDialogTitle>
            <Typography fontSize={'x3large'}>
              {countrySelectorDialogHeader}
            </Typography>
          </ModalDialogTitle>
        </ModalDialogHeader>
        <ModalDialogBody>
          <Box
            as="ul"
            role="list"
            // role="list" is to account for Safari suppressing lists with the bullets removed
            // (see https://www.scottohara.me/blog/2019/01/12/lists-and-safari.html)
            aria-label={countrySelectorDialogHeader}
            id="bookendsCountrySelectorContainer"
            className="country-selector-container swan-text-1"
            data-bookends-locale={locale}
            data-bookends-tenant={tenant}
          >
            <Typography
              as="li"
              fontSize="small"
              fontWeight="bold"
              className="country-selector-item swan-mr-6 swan-mb-4"
            >
              <Box className="country-selector-flag-image-container">
                <Image role="img" image={currentCountry.imageUrl.image} />
              </Box>
              {currentCountry.text}
              {alternateLanguageList}
            </Typography>
            {otherCountries.map((locale: any) => {
              const currPosition = dataPosition + 1;
              dataPosition += locale.alternateLanguages.length;
              return (
                <CountrySelectorCountry
                  key={locale.text}
                  {...locale}
                  isCookie={isCookie}
                  dataPosition={currPosition}
                  componentSource={componentSource}
                  currentLocale={currentLocale}
                />
              );
            })}
          </Box>
        </ModalDialogBody>
      </ModalDialogContent>
    </ModalDialog>
  );
};
