import * as React from 'react';
import classNames from 'classnames';
import {
  BoundedContent,
  Box,
  StandardTile,
  StandardTileImage,
  StandardTileContents,
  StandardTileName,
  StandardTileDescription,
  StandardTileOverlay,
  Typography,
  Span,
  tokens,
} from '@vp/swan';
import { useIsSsrContext } from '../../../../contexts/IsSsrContext';
import { Image } from '../../../Image';
import flattenNavLinks from '../flattenNavLinks';
import TrackableLink from '../../../TrackableLink';
import { BookendsBadge } from '../../../BookendsBadge';
import { LinkList, TextNavFlyoutProps, NavItem } from '../../../../types';
import { MAX_THIRD_LEVEL_ITEMS } from '../constants';
import { SeeAllBar } from './SeeAllBar';
import { useNavContext } from '../NavContext';
import {
  CATEGORY_SECTION,
  MERCHANDISING_SECTION,
} from '../../../../../constants/analytics';

// The layout calls for 6 columns
const NUM_COLUMNS = 6;
const stylings = {
  width: '1px',
  backgroundColor: tokens.SwanSemColorBorderStandard,
  border: 'none',
  marginLeft: '0px',
  marginRight: tokens.SwanSemSpace6,
};

const numEmptyColumns = (columnCountArray: number[]) => {
  let numEmptyColumns = 0;
  for (let i = 0; i < columnCountArray.length; i++) {
    if (columnCountArray[i] === 0) {
      numEmptyColumns++;
    }
  }
  return numEmptyColumns;
};

const layOutNavItems = (
  navItems: NavItem[],
  parentNavigationDetailPath: any,
  isMxSection?: boolean
) => {
  // Function to capture layout logic for putting categories into columns
  let columnItemCounts = [0, 0, 0, 0, 0, 0]; // Total number of third level items per column
  let columnContents: JSX.Element[][] = [[], [], [], [], [], []];
  let currentColumn = 0;
  let visualItemRendered = false;
  let analyticsDataPosition = 1;
  let countColumnsRenderedAfterMx: number | undefined = undefined;
  let mxTrueColumnIndex: number | undefined = undefined;
  const mxSectionMaxColumns = 3;

  navItems.map((navItem: any, navItemIndex: number) => {
    if (navItem.isVisual && navItem.url && navItem.image && navItem.image.url) {
      // Render the promo tile visual treatment
      if (visualItemRendered) {
        // only show one visual tile per flyout
        return null;
      }

      // The promo tile will take precedence over whatever was in the 6th column (if anything)
      visualItemRendered = true;
      let visualItemIndex =
        (mxTrueColumnIndex && mxTrueColumnIndex + (mxSectionMaxColumns - 1)) ||
        NUM_COLUMNS - 1;
      columnItemCounts[visualItemIndex] = MAX_THIRD_LEVEL_ITEMS;
      columnContents[visualItemIndex] = [];
      columnContents[visualItemIndex].push(
        <PromoTile
          navItem={navItem}
          parentNavigationDetailPath={parentNavigationDetailPath}
          key={navItemIndex + '-promo-tile'}
          navigationSection={
            countColumnsRenderedAfterMx
              ? MERCHANDISING_SECTION
              : CATEGORY_SECTION
          }
        />
      );
      for (
        let content = visualItemIndex + 1;
        content < NUM_COLUMNS;
        content++
      ) {
        columnContents[content] = [];
      }
      return;
    }

    const itemChildren = navItem.children.slice(0, MAX_THIRD_LEVEL_ITEMS);
    let pushtoNextColumn = navItem?.pushToNextColumn && isMxSection;
    let startOfMx = navItem?.startOfMx;
    if (startOfMx && countColumnsRenderedAfterMx === undefined) {
      countColumnsRenderedAfterMx = 0;
      mxTrueColumnIndex = currentColumn + 1;
    }

    while (
      columnItemCounts[currentColumn] !== undefined &&
      (pushtoNextColumn ||
        startOfMx ||
        columnItemCounts[currentColumn] + itemChildren.length >
          MAX_THIRD_LEVEL_ITEMS)
    ) {
      // if the column is full, advance to the next one
      // This is a loop so that we skip any pre-filled columns, such as the promo tile one.
      pushtoNextColumn = false;
      startOfMx = false;
      if (countColumnsRenderedAfterMx !== undefined)
        countColumnsRenderedAfterMx++;
      currentColumn++;
    }

    if (
      currentColumn >= NUM_COLUMNS ||
      (countColumnsRenderedAfterMx || 0) > mxSectionMaxColumns
    ) {
      //We have run out of columns to fill
      return;
    }

    columnItemCounts[currentColumn] += itemChildren.length;
    columnContents[currentColumn].push(
      <SubCategory
        navItem={navItem}
        itemChildren={itemChildren}
        totalCategories={navItems.length}
        parentNavigationDetailPath={parentNavigationDetailPath}
        key={navItem.id + '-subcategory'}
        dataPosition={analyticsDataPosition}
        navigationSection={
          countColumnsRenderedAfterMx ? MERCHANDISING_SECTION : CATEGORY_SECTION
        }
      />
    );

    if (navItem.url) {
      analyticsDataPosition++;
    }
    analyticsDataPosition += itemChildren.length;

    if (
      !isMxSection &&
      navItems.length - (navItemIndex + 1) <= numEmptyColumns(columnItemCounts)
    ) {
      //After a category is placed, if categories <= empty columns, do not stack. Fill the next column instead.
      currentColumn++;
    }
  });

  return columnContents;
};

export const TextNavFlyout = (
  props: TextNavFlyoutProps &
    LinkList<NavItem> &
    React.ComponentProps<'section'>
) => {
  const { isSsr } = useIsSsrContext();
  const { isMxNav } = useNavContext();

  if (!props.children) {
    return null;
  }

  if (isSsr) {
    return (
      <div className="site-header-nav-flyout">
        {flattenNavLinks(props.children)}
      </div>
    );
  } else {
    const laidOutNavItems = layOutNavItems(
      props.children,
      props.parentNavigationDetailPath,
      props.hasMx
    );

    return (
      <section
        className={classNames(
          'site-header-nav-flyout site-header-nav-flyout-text',
          props.className
        )}
        id={props.id}
        aria-labelledby={props.labelledBy}
      >
        <BoundedContent style={{ width: '100%' }}>
          <Box
            paddingTop={7}
            className={classNames(
              'full-width-container-capped',
              'full-width-container',
              'site-header-nav-flyout-categories-wrapper',
              'site-header-nav-flyout-content'
            )}
          >
            <Box
              component="div"
              paddingBottom={'7'}
              className={classNames(
                'site-header-nav-flyout-categories',
                'site-header-nav-menu-items',
                'full-width-container'
              )}
              style={{
                justifyContent: props.hasMx ? 'center' : 'space-between',
              }}
            >
              {laidOutNavItems.map((column: any, columnIndex: number) => {
                if (props.hasMx) {
                  if (column.length) {
                    const isMxStartHere = column[0].props?.navItem?.startOfMx;
                    return (
                      <>
                        {isMxStartHere ? <hr style={stylings}></hr> : <></>}
                        <Box
                          component="ul"
                          className="nav-flyout-text-column"
                          key={columnIndex}
                        >
                          {column.map((item: any) => item)}
                        </Box>
                      </>
                    );
                  }
                  return <></>;
                } else {
                  return (
                    <>
                      <Box
                        component="ul"
                        className="nav-flyout-text-column"
                        key={columnIndex}
                      >
                        {column.map((item: any) => item)}
                      </Box>
                    </>
                  );
                }
              })}
            </Box>
            {!isMxNav && (
              <SeeAllBar
                seeAllText={props.seeAllText}
                seeAllUrl={props.seeAllUrl}
                parentNavigationDetailPath={props.parentNavigationDetailPath}
                analyticsId={props.seeAllAnalyticsId}
              />
            )}
          </Box>
        </BoundedContent>
      </section>
    );
  }
};

const SubCategory = ({
  navItem,
  itemChildren,
  totalCategories,
  parentNavigationDetailPath,
  dataPosition = 0,
  navigationSection,
}: any) => {
  const { isMxNav } = useNavContext();
  return (
    <Box
      className="nav-flyout-text-category"
      marginBottom={'7'}
      component="li"
      key={navItem.id}
    >
      {(totalCategories > 1 || !itemChildren || !itemChildren.length) && (
        // Only show category headers when there is more than one category (or that one category has no children)
        <>
          <Span
            fontWeight="bold"
            className={classNames(
              'site-header-nav-secondlevel-js',
              navItem.theme
            )}
            tabIndex={0}
            fontSize="small"
            key={navItem.id + '-title'}
          >
            {
              navItem.url ? (
                <TrackableLink
                  href={navItem.url}
                  subSection="TopNav"
                  className="link"
                  navigationDetailPath={
                    navItem.analyticsId
                      ? parentNavigationDetailPath.concat(navItem.analyticsId)
                      : []
                  }
                  linkType="Text Layout Link"
                  key={navItem.id + '-heading'}
                  dataPosition={dataPosition}
                  navigationSection={isMxNav ? navigationSection : undefined}
                >
                  {navItem.text}
                </TrackableLink>
              ) : (
                <span
                  key={navItem.id + '-heading'}
                  style={{
                    color: isMxNav ? tokens.SwanBaseColorGrey700 : 'black',
                  }}
                >
                  {navItem.text}
                </span>
              )
              /* TODO:
               * check to make sure we don't actually want 'site-header-nav-secondlevel-js' on the inside level with the link tag
               * Are there any aria indicators that this is the list heading that we should add? (What about the no children case)
               * callout segments look clickable in the subitems, but not in the case of links here
               */
            }
            {navItem?.badge?.text && (
              <>
                <BookendsBadge {...navItem.badge} layout="visual" />
              </>
            )}
          </Span>
        </>
      )}
      {itemChildren && itemChildren.length > 0 && (
        <ul key={navItem.id + '-children'}>
          {itemChildren.map((subNavItem: any, subNavItemIndex: number) => {
            return (
              <li
                className="nav-flyout-text-category-item"
                key={subNavItemIndex}
                style={{ fontSize: 'small' }}
              >
                <TrackableLink
                  href={subNavItem.url}
                  className={classNames(
                    'site-header-nav-thirdlevel-js',
                    'link',
                    subNavItem.theme
                  )}
                  subSection="TopNav"
                  key={subNavItemIndex + '-link'}
                  dataPosition={
                    dataPosition + subNavItemIndex + (navItem.url ? 1 : 0)
                  }
                  navigationDetailPath={
                    subNavItem.analyticsId
                      ? parentNavigationDetailPath
                          .concat(
                            navItem.analyticsId ? navItem.analyticsId : []
                          )
                          .concat(subNavItem.analyticsId)
                      : []
                  }
                  linkType="Text Layout Link"
                  navigationSection={isMxNav ? navigationSection : undefined}
                >
                  {subNavItem.text}
                  {subNavItem.badge?.text && (
                    <BookendsBadge {...subNavItem.badge} layout="visual" />
                  )}
                </TrackableLink>
              </li>
            );
          })}
        </ul>
      )}
    </Box>
  );
};

interface PromoTileProps {
  navItem: any;
  parentNavigationDetailPath: any;
  navigationSection?: typeof CATEGORY_SECTION | typeof MERCHANDISING_SECTION;
}
const PromoTile = ({
  navItem,
  parentNavigationDetailPath,
  navigationSection,
}: PromoTileProps) => (
  <li key={navItem.id}>
    <TrackableLink
      href={navItem.url}
      className="site-header-nav-secondlevel-js"
      subSection="TopNav"
      navigationDetailPath={
        navItem.analyticsId
          ? parentNavigationDetailPath.concat(navItem.analyticsId)
          : []
      }
      linkType="Promo Tile"
      key={navItem.id + '-heading'}
      navigationSection={navigationSection}
    >
      <StandardTile>
        {navItem?.badge?.text && (
          <StandardTileOverlay>
            <BookendsBadge {...navItem.badge} layout="visual" />
          </StandardTileOverlay>
        )}
        <StandardTileImage>
          <Image image={navItem.image} alt={navItem.text} />
        </StandardTileImage>
        <StandardTileContents>
          <StandardTileName>
            <Typography fontWeight="bold" className={classNames(navItem.theme)}>
              {navItem.text}
            </Typography>
          </StandardTileName>
          <StandardTileDescription>
            {navItem.description}
          </StandardTileDescription>
        </StandardTileContents>
      </StandardTile>
    </TrackableLink>
  </li>
);
