import React, { useState } from 'react';

type MyAccountMobileContextProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export const MyAccountMobileContext =
  React.createContext<MyAccountMobileContextProps>({
    isOpen: false,
    setIsOpen: () => {},
  });

export const MyAccountMobileContextProvider = (props: { children: any }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <MyAccountMobileContext.Provider value={{ isOpen, setIsOpen }}>
      {props.children}
    </MyAccountMobileContext.Provider>
  );
};
