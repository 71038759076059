import * as React from 'react';
import classNames from 'classnames';
import TrackableLink from '../../../TrackableLink';
import { BookendsBadge } from '../../../BookendsBadge';
import { ThirdLevelNavItemType } from '../../../../types';

export const ThirdLevelNavItem = ({
  subflyoutLink,
  parentNavigationDetailPath,
  dataPosition,
  seeAllText,
  navigationSection,
}: ThirdLevelNavItemType) => {
  if (!subflyoutLink.url) {
    return null;
  }
  return (
    <li
      className="site-header-nav-item site-header-nav-thirdlevel"
      data-item-title={seeAllText ? seeAllText : subflyoutLink.text}
    >
      <TrackableLink
        subSection="TopNav"
        navigationDetailPath={
          subflyoutLink.analyticsId
            ? parentNavigationDetailPath.concat([subflyoutLink.analyticsId])
            : []
        }
        linkType="Text Layout Link"
        className={classNames(`site-header-nav-link`, subflyoutLink.theme)}
        href={subflyoutLink.url}
        dataPosition={dataPosition}
        navigationSection={navigationSection}
      >
        <span>
          {seeAllText ? seeAllText : subflyoutLink.text}
          {subflyoutLink.badge && (
            <BookendsBadge {...subflyoutLink.badge} layout="visual" />
          )}
        </span>
      </TrackableLink>
    </li>
  );
};
