import { fireImpression, getAllExperiments } from '@vp/ab-reader';
import { ABTest } from '../types';

export const getTests = async (
  experimentsGetter: typeof getAllExperiments
): Promise<ABTest[]> => {
  const experiments = experimentsGetter();
  const abTestData = experiments.map((e: any) => {
    return { Experiment: e.experimentKey, Variation: e.variationKey };
  });
  return abTestData;
};

const trackedTests: string[] = [];

export const trackImpression = async (
  tests: ABTest[],
  userTests?: ABTest[]
) => {
  if (!userTests) {
    return;
  }

  /*
    The data structure for node level ab tests and site header level ab tests is different.
    Thus, we are implementing this hotfix to accommodate both data structures.
  */
  const siteHeaderLevelExperiments = tests
    ?.map((test) => test?.Experiment)
    ?.filter((experiment) => experiment);
  const matchingTests = userTests.filter((userTest) => {
    return (
      // @ts-ignore
      (tests?.includes(userTest.Experiment) ||
        siteHeaderLevelExperiments?.includes(userTest.Experiment)) &&
      !trackedTests.includes(userTest.Experiment)
    );
  });

  if (matchingTests.length === 0) {
    return;
  }

  matchingTests.forEach(async (matchingTest) => {
    trackedTests.push(matchingTest.Experiment);
    await fireImpression(matchingTest.Experiment, matchingTest.Variation);
  });
};
