import * as React from 'react';
import { createCtx } from '../../../utils/createCtx';
import { isEmptyNavItem } from './isEmptyNavItem';
import { manageKeydown } from './DesktopNav/manageKeypress';
import { trackImpression } from '../../../utils/optimizely';
import {
  ABTest,
  NavContextProps,
  NavContextState,
  NavContextType,
  NavItem,
  NavItemWithChildrenType,
  UpdateNavActions,
} from '../../../types';

let topLevelItemReferences: any[] = [];

const reducer: React.Reducer<NavContextState, UpdateNavActions> = (
  initialState,
  action
) => {
  switch (action.type) {
    case 'focus category':
      return {
        ...initialState,
        backButtonShouldFocus: true,
        focusAfterRender: false,
        focusedCategoryIdx: action.categoryIdx,
        focusedSubcategoryIdx:
          action.categoryIdx === initialState.focusedCategoryIdx
            ? initialState.focusedSubcategoryIdx
            : undefined,
        shouldShowVeil: !isEmptyNavItem(action.navigation[action.categoryIdx]),
      };
    case 'focus subcategory':
      return {
        ...initialState,
        focusedSubcategoryIdx: action.subcategoryIdx,
      };
    case 'unfocus category':
      return {
        ...initialState,
        backButtonShouldFocus: false,
        focusAfterRender: false,
        focusedCategoryIdx: undefined,
        focusedSubcategoryIdx: undefined,
        focusedThirdLevelIdx: undefined,
        shouldShowVeil: false,
      };
    case 'keypress':
      return manageKeydown(
        action.categoryIdx,
        action.event,
        topLevelItemReferences,
        initialState,
        action.navigation
      );
    default:
      break;
  }
  return initialState;
};

export const [useNavContext, NavContext] = createCtx<NavContextType>();

export const NavContextProvider: React.FC<NavContextProps> = ({
  children,
  navigation = [],
  userTests,
  topLevelNavNodeTests,
  isMobileNavOpen,
  navigationLabel,
  navigationMenuTitle,
  auth,
  locale,
  setShowVeil,
}) => {
  const initialState = {
    backButtonShouldFocus: false,
    focusedCategoryIdx: undefined,
    focusedSubcategoryIdx: undefined,
    focusedThirdLevelIdx: undefined,
    focusAfterRender: false,
    shouldShowVeil: false,
    isMobileNavOpen: isMobileNavOpen || false,
    impressionQueue: [],
  };

  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [impressionQueue, setImpressionQueue] = React.useState([] as any[]);

  const backButtonRef = React.useRef(null);
  React.useEffect(() => {
    if (state.backButtonShouldFocus) {
      (backButtonRef.current as HTMLElement | null)?.focus();
    }
  }, [state.backButtonShouldFocus]);

  React.useEffect(() => {
    setShowVeil?.(state.shouldShowVeil);
  }, [state.shouldShowVeil]);

  React.useEffect(() => {
    if (
      auth &&
      userTests &&
      userTests.length > 0 &&
      impressionQueue.length > 0
    ) {
      trackImpression(impressionQueue, userTests);
      setImpressionQueue([] as any[]);
    }
  }, [auth, userTests, impressionQueue]);

  const triggerImpression = (
    tests: string[],
    userTests: ABTest[] | undefined,
    impressionQueue: any[]
  ) => {
    if (!userTests) {
      return;
    }
    setImpressionQueue(
      tests.reduce(
        (accum: any[], test: string) => {
          if (!accum.includes(test)) {
            accum.push(test);
          }
          return accum;
        },
        [...impressionQueue]
      )
    );
  };

  React.useEffect(() => {
    triggerImpression(topLevelNavNodeTests || [], userTests, impressionQueue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topLevelNavNodeTests, userTests]);
  //This is purposefully non-exhaustive. We want to fire these only when the set of tests that could be fired changes

  const hoverNavItem = React.useCallback(
    (categoryIdx: number, navigation: Array<NavItem>, mobile?: boolean) => {
      /* When using keyboard focus events, changing focus to subcategories within the category bubbles
       * event up to the category. That fires this event, but is not actually a state change
       * so ensure it is treated as a "non-change" and doesn't re-render or set incorrect data
       * such as resetting the focusted subcategory
       */

      if (categoryIdx !== state.focusedCategoryIdx) {
        const currentNavItem = navigation[categoryIdx];
        let testsInChildren: string[] = [];
        if (currentNavItem?.testsInChildren) {
          testsInChildren = [...currentNavItem.testsInChildren];
        }
        // Text based navigation desktop will show both second and third level nav nodes
        if (!currentNavItem.isVisual && !mobile) {
          (currentNavItem as NavItemWithChildrenType).children.forEach(
            (child) => {
              if (child.testsInChildren) {
                testsInChildren = child.testsInChildren?.reduce(
                  (accum, test) => {
                    if (!accum.includes(test)) {
                      accum.push(test);
                    }
                    return accum;
                  },
                  testsInChildren
                );
              }
            }
          );
        }

        triggerImpression(testsInChildren, userTests, impressionQueue);

        document.dispatchEvent(new Event('navHover'));
        if (!mobile || (mobile && !isEmptyNavItem(currentNavItem))) {
          // Avoid setting focus on empty nav items in mobile
          dispatch({
            categoryIdx,
            navigation,
            type: 'focus category',
          });
        }
      
      }
    },
    [userTests, state.focusedCategoryIdx, impressionQueue]
  );

  const hoverSubNavItem = React.useCallback(
    (subcategoryIdx: number) => {
      /* When using keyboard focus events, changing focus to links within the subcategory bubbles
       * event up to the subcategory. That fires this event, but is not actually a state change
       * so ensure it is treated as a "non-change" and doesn't re-render
       */

      if (subcategoryIdx !== state.focusedSubcategoryIdx) {
        const currentNavItem = state.focusedCategoryIdx?.toString()
          ? (navigation[state.focusedCategoryIdx] as NavItemWithChildrenType)
              .children[subcategoryIdx]
          : undefined;
        if (currentNavItem?.testsInChildren) {
          triggerImpression(
            currentNavItem.testsInChildren,
            userTests,
            impressionQueue
          );
        }
        dispatch({
          subcategoryIdx,
          type: 'focus subcategory',
        });
      }
    },
    [
      userTests,
      state.focusedCategoryIdx,
      state.focusedSubcategoryIdx,
      impressionQueue,
      navigation,
    ]
  );

  const unfocusNav = React.useCallback(
    (unfocusedCategory: any, triggeredOnBlur = false) => {
      if (
        unfocusedCategory &&
        state.focusedCategoryIdx !== unfocusedCategory &&
        !triggeredOnBlur
      ) {
        // If we have already focused another category, do not unfocus. We should have already re-rendered.
        return;
      }
      dispatch({
        type: 'unfocus category',
      });
    },
    [state.focusedCategoryIdx]
  );

  const keypress = (
    categoryIdx: number,
    event: React.KeyboardEvent<HTMLLIElement>,
    navigation: Array<NavItem>
  ) => {
    dispatch({
      categoryIdx,
      event,
      navigation,
      type: 'keypress',
    });
  };

  const addItemRef = (id: number, ref: React.Ref<any>) => {
    topLevelItemReferences[id] = ref;
  };

  React.useEffect(() => {
    const mouseMoveHandler = function () {
      dispatch({
        type: 'unfocus category',
      });
    };
    document.addEventListener('headerMouseMove', mouseMoveHandler);
    document.addEventListener('veilMouseMove', mouseMoveHandler);

    return () => {
      document.removeEventListener('headerMouseMove', mouseMoveHandler);
      document.removeEventListener('veilMouseMove', mouseMoveHandler);
    };
  }, []);
  
  const isMxNav = navigation?.some((navigationNode) =>
    (navigationNode as NavItemWithChildrenType)?.children?.some(
      (childNode) => childNode?.startOfMx
    )
  );

  return (
    <NavContext
      value={{
        state,
        navigation,
        isMobileNavOpen,
        userTests,
        navigationLabel,
        navigationMenuTitle,
        locale,
        hoverNavItem,
        hoverSubNavItem,
        unfocusNav,
        keypress,
        addItemRef,
        focusedSubcategoryIdx: state.focusedSubcategoryIdx,
        focusedThirdLevelIdx: state.focusedThirdLevelIdx,
        focusAfterRender: state.focusAfterRender,
        backButtonRef,
        isMxNav,
      }}
    >
      {children}
    </NavContext>
  );
};
