import * as React from 'react';
import classNames from 'classnames';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleSummary,
  CollapsibleSummaryButton,
} from '@vp/swan';
import { isEmptyNavItem } from '../isEmptyNavItem';
import { ThirdLevelNavItem } from './ThirdLevelNavItem';
import { ThirdLevelTileNavItem } from './ThirdLevelTileNavItem';
import { NavItemLeafType, SubCategoryEntryProps } from '../../../../types';
import { MAX_THIRD_LEVEL_ITEMS } from '../constants';
import { BookendsBadge } from 'components/BookendsBadge';
import { useNavContext } from '../NavContext';

export const SubCategoryWithChildren = ({
  subCategoryLink,
  isVisualNav,
  parentNavigationDetailPath,
  index,
  navigationSection
}: SubCategoryEntryProps) => {
  const { isMxNav } = useNavContext()
 
  const navigationDetailPath = parentNavigationDetailPath.concat(
    subCategoryLink.analyticsId ? subCategoryLink.analyticsId : []
  );
  return (
    <Collapsible
      component="li"
      collapsibleId={`${index}`} //This is used by the accordion event to know the index of the nav item being expanded
      className={classNames(
        'site-header-nav-flyout-category',
        'site-header-nav-secondlevel',
        subCategoryLink.theme
      )}
    >
      <CollapsibleSummary component="h3">
        <CollapsibleSummaryButton>
          {' '}
          {subCategoryLink.text}{' '}
          {subCategoryLink.badge && (
            <BookendsBadge {...subCategoryLink.badge} layout="visual" />
          )}
        </CollapsibleSummaryButton>
      </CollapsibleSummary>
      <CollapsibleContent>
      <ul
        className={'site-header-nav-subcategory-item-list'}
      >
        {
          isMxNav && subCategoryLink.url && subCategoryLink.seeAllMinimal &&
          <ThirdLevelNavItem
            subflyoutLink={subCategoryLink as NavItemLeafType}
            parentNavigationDetailPath={navigationDetailPath}
            key={subCategoryLink.id}
            dataPosition={0}
            seeAllText={subCategoryLink.seeAllMinimal}
            navigationSection={navigationSection}
        />
        }
        {subCategoryLink.children
          ?.slice(0, MAX_THIRD_LEVEL_ITEMS)
          .map((subflyoutLink, index) => {
            return isEmptyNavItem(subflyoutLink) ? (
              isVisualNav ? (
                <ThirdLevelTileNavItem
                  subflyoutLink={subflyoutLink as NavItemLeafType}
                  parentNavigationDetailPath={navigationDetailPath}
                  key={subflyoutLink.id}
                  dataPosition={index + 1}
                  layoutType="Visual Tile"
                  navigationSection={navigationSection}
                />
              ) : (
                <ThirdLevelNavItem
                  subflyoutLink={subflyoutLink as NavItemLeafType}
                  parentNavigationDetailPath={navigationDetailPath}
                  key={subflyoutLink.id}
                  dataPosition={index + 1}
                  navigationSection={navigationSection}
                />
              )
            ) : null;
          })}
      </ul>
      </CollapsibleContent>
    </Collapsible>
  );
};
