import * as React from 'react';
import classNames from 'classnames';
import TrackableLink from '../../../TrackableLink';
import { MobileNavSeeAllLinkProps } from '../../../../types';
import { useNavContext } from '../NavContext';
import { BookendsBadge } from '../../../BookendsBadge';

export function SeeAllLink({
  categoryLink,
  className,
  parentNavigationDetailPath,
  analyticsId,
  dataPosition,
  index,
  trackTests,
  navigationSection,
}: MobileNavSeeAllLinkProps & React.ComponentProps<'li'>) {
  const { hoverSubNavItem } = useNavContext();

  return (
    <li
      className={classNames(
        'site-header-nav-item',
        'site-header-nav-secondlevel',
        className
      )}
      data-item-title={categoryLink.text}
      onClick={() => {
        if (trackTests && index !== undefined) {
          hoverSubNavItem(index);
        }
      }}
      onKeyDown={(e) => {
        if ((e.key === 'Enter' || e.key === ' ') && index !== undefined) {
          hoverSubNavItem(index);
        }
      }}
    >
      <TrackableLink
        subSection="TopNav"
        navigationDetailPath={
          analyticsId ? parentNavigationDetailPath.concat([analyticsId]) : []
        }
        data-fidoprefetchhint="IO"
        className={`site-header-nav-link ${categoryLink.theme}`}
        dataPosition={dataPosition}
        href={categoryLink.url}
        navigationSection={navigationSection}
      >
        <span>
          {categoryLink.text}
          {categoryLink.badge && (
            <BookendsBadge {...categoryLink.badge} layout="visual" />
          )}
        </span>
      </TrackableLink>
    </li>
  );
}
