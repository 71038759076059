import { SkipLink } from '@vp/swan';
import React from 'react';
import { VatContextProvider } from '../../contexts/VatContext';
import { Image } from '../Image';
import TrackableLink from '../TrackableLink';

export const MasksLayout = (props: any) => {
  const data: any = props.data;
  return (
    <VatContextProvider
      vatLocale={data.vatLocale}
      vatToggleExclusionText={data.vatToggleExclusionText}
      vatToggleInclusionText={data.vatToggleInclusionText}
      vatToggleLabel={data.vatToggleLabel}
    >
      <SkipLink>
        {data.skipToMainContent}
      </SkipLink>
      <header className="site-header site-header-full masks">
        <div className="full-width-container">
          <div className="site-header-main full-width-container full-width-container-capped">
            <div className="site-header-logo-and-tagline">
              <div className="site-header-logo">
                <TrackableLink
                  navigationDetailPath={['homePage']}
                  href={data.host}
                  aria-label={data.logoDescription}
                >
                  <span>
                    <Image
                      className="logo-fullsize"
                      image={data.inlineSecondaryLogo.image}
                    />
                    <Image
                      className="logo-narrow"
                      image={data.inlineSecondaryLogo.image}
                    />
                  </span>
                </TrackableLink>
              </div>
            </div>
          </div>
        </div>
      </header>
    </VatContextProvider>
  );
};
