import React, { FC, useContext } from 'react';
import { Typography } from '@vp/swan';
import { TrackingContext } from '../../hooks/useTracking';
import { FooterDivider } from './FooterDivider';
import { AbsoluteGuarantee } from '../../types';
import { ErrorBoundary } from '../ErrorBoundary';

//Todo: wrap html embedded a with onclick at this level
export const AbsoluteGuaranteeSection: FC<AbsoluteGuarantee> = ({
  html,
  analyticsId,
}) => {
  const { trackNavigation } = useContext(TrackingContext);
  return (
    <ErrorBoundary component="absolute-guarantee-section">
      <div className="site-footer-guarantee-container">
        <Typography
          marginBottom={'7'}
          className="site-footer-guarantee-message"
          onClick={(evt: React.MouseEvent) => {
            //only if root click is the a tag
            if ((evt.target as HTMLElement).nodeName === 'A') {
              const href = (evt.target as HTMLAnchorElement).href;
              trackNavigation(analyticsId, href ? new URL(href).pathname : '');
            }
          }}
          onKeyDown={(evt: React.KeyboardEvent) => {
            //only if root click is the a tag
            if (
              (evt.target as HTMLElement).nodeName === 'A' &&
              evt.key === 'Enter'
            ) {
              const href = (evt.target as HTMLAnchorElement).href;
              trackNavigation(analyticsId, href ? new URL(href).pathname : '');
            }
          }}
          dangerouslySetInnerHTML={{ __html: html }}
        />
        <FooterDivider />
      </div>
    </ErrorBoundary>
  );
};
